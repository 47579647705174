import React from 'react';
import { StyledFulfillmentStatusPill } from './FulFillmentPill.styles';
import { StatusPill, StatusPillProps } from 'lib/components/StatusPill/StatusPill';

export interface FulfillmentStatusPillProps {
  fulfillmentPercent: number;
  size?: StatusPillProps['size'];
}

export const FulfillmentStatusPill = ({
  fulfillmentPercent,
  size = 'large',
}: FulfillmentStatusPillProps): React.JSX.Element => {
  const FulfillmentString = [fulfillmentPercent, '%'].join('');

  const getColor = (): string => {
    if (fulfillmentPercent < 90 && fulfillmentPercent >= 25) return 'yellow';
    if (fulfillmentPercent < 25) return 'red';

    return 'green';
  };

  return (
    <StyledFulfillmentStatusPill className="Fulfillment-status-pill">
      <StatusPill color={getColor()} size={size}>
        {FulfillmentString}
      </StatusPill>
    </StyledFulfillmentStatusPill>
  );
};
