import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  CleaningReportListAvailableFilters,
  CleaningReportsExportRequest,
  CleaningReportsExportGetFile,
} from '../../interfaces/CleaningReport.types';
import { CleaningReportListActions } from './cleaningReportListActions';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import { MachineWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import {
  CleaningReportSubscription,
  CleaningTaskReport,
  FilterLastActivityAt,
  MachineClassification,
  SingleRoutesAndRoutineData,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getNextPaginationToken } from 'app/utils/infinite-scroll/infiniteScroll';

export interface CleaningReportListState {
  totals: {
    data: Optional<MachineWithCleaningReport[]>;
    totalCount: Optional<number>;
    isLoading: Optional<boolean>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    sortOptions: {
      field: Optional<string>;
      order: Optional<SortOrders>;
    };
  };
  exact: {
    data: Optional<CleaningTaskReport[]>;
    totalCount: Optional<number>;
    isLoading: Optional<boolean>;
    isLoadingMoreData: Optional<boolean>;
    nextPaginationToken: string | null;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    sortOptions: {
      field: Optional<string>;
      order: Optional<SortOrders>;
    };
  };
  robot: {
    data: Optional<SingleRoutesAndRoutineData[]>;
    totalCount: Optional<number>;
    isLoading: Optional<boolean>;
    isLoadingMoreData: Optional<boolean>;
    nextPaginationToken: string | null;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    sortOptions: {
      field: Optional<string>;
      order: Optional<SortOrders>;
    };
  };
  filters: {
    available: CleaningReportListAvailableFilters;
    active: {
      machines: Optional<string[]>;
      siteIds: Optional<string[]>;
      period: {
        startDate: Optional<string>;
        endDate: Optional<string>;
      };
      lastActivityAt: Optional<FilterLastActivityAt>;
      taskCompletion: Optional<string[]>;
      searchText: Optional<string>;
    };
    isLoading: boolean;
  };
  export: {
    isLoading: Optional<boolean>;
    requestId: Optional<string>;
  };
  exportRobotDetails: {
    isLoading: Optional<boolean>;
    downloadUrl: Optional<string>;
    id: Optional<string>;
  };
  selectTab: {
    deviceType: MachineClassification;
  };
  reportSubscriptionDrawer: {
    visible: boolean;
    isLoading: boolean;
    reportSubscription: {
      data: CleaningReportSubscription[];
      isLoading: boolean;
    };
    saveReportSubscription: {
      data: CleaningReportSubscription[];
      isLoading: boolean;
    };
  };
}

export const initialState: CleaningReportListState = {
  totals: {
    data: null,
    totalCount: null,
    isLoading: true,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
    sortOptions: {
      field: 'serialNumber',
      order: SortOrders.Asc,
    },
  },
  exact: {
    data: null,
    totalCount: null,
    isLoading: true,
    isLoadingMoreData: null,
    nextPaginationToken: '',
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
    sortOptions: {
      field: 'startedAt',
      order: SortOrders.Desc,
    },
  },
  robot: {
    data: null,
    totalCount: null,
    isLoading: true,
    isLoadingMoreData: null,
    nextPaginationToken: '',
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
    sortOptions: {
      field: 'startedAt',
      order: SortOrders.Desc,
    },
  },
  filters: {
    available: {
      machines: [],
      standAloneMachines: [],
      sites: [],
      machineStatus: [],
      taskCompletion: [],
    },
    active: {
      machines: undefined,
      siteIds: undefined,
      period: {
        startDate: undefined,
        endDate: undefined,
      },
      lastActivityAt: undefined,
      taskCompletion: undefined,
      searchText: undefined,
    },
    isLoading: false,
  },
  export: {
    isLoading: null,
    requestId: null,
  },
  exportRobotDetails: {
    isLoading: null,
    downloadUrl: null,
    id: null,
  },
  selectTab: {
    deviceType: MachineClassification.Gcd,
  },
  reportSubscriptionDrawer: {
    visible: false,
    isLoading: false,
    reportSubscription: {
      data: [],
      isLoading: false,
    },
    saveReportSubscription: {
      data: [],
      isLoading: false,
    },
  },
};

export const cleaningReportListReducer = (state = initialState, action: AnyAction): CleaningReportListState =>
  produce(state, draft => {
    switch (action.type) {
      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_REQUEST: {
        draft.totals.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_SUCCESS: {
        const {
          machines: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.totals.isLoading = false;
        draft.totals.data = data;
        draft.totals.totalCount = totalCount;

        if (paginationToken) {
          draft.totals.paginationTokens[draft.totals.page + 1] = paginationToken;
        }

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_ERROR: {
        draft.totals.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.totals.page = page;
        return draft;
      }

      case CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.totals.paginationTokens = {};
        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.pageSize = pageSize;
        return draft;
      }

      case CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.totals.sortOptions.field = field;
        return draft;
      }

      case CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.totals.sortOptions.order = order;
        draft.totals.paginationTokens = {};
        draft.totals.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case CleaningReportListActions.TOTALS_RESET_STATE: {
        draft.totals = initialState.totals;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_REQUEST: {
        draft.exact.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_SUCCESS: {
        const {
          cleaningTaskReports: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.exact.isLoading = false;
        draft.exact.data = data;
        draft.exact.totalCount = totalCount;
        draft.exact.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.exact.paginationTokens[draft.exact.page + 1] = paginationToken;
        }

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_ERROR: {
        draft.exact.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST: {
        draft.exact.isLoadingMoreData = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_SUCCESS: {
        const {
          cleaningTaskReports: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;

        draft.exact.isLoadingMoreData = false;
        draft.exact.data = (draft.exact.data || []).concat(data);
        draft.exact.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_ERROR: {
        draft.exact.isLoadingMoreData = false;
        return draft;
      }

      case CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.exact.page = page;
        return draft;
      }

      case CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.exact.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.pageSize = pageSize;
        return draft;
      }

      case CleaningReportListActions.EXACT_SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.exact.sortOptions.field = field;
        return draft;
      }

      case CleaningReportListActions.EXACT_SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.exact.sortOptions.order = order;
        draft.exact.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case CleaningReportListActions.EXACT_RESET_STATE: {
        draft.exact = initialState.exact;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_REQUEST: {
        draft.robot.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_SUCCESS: {
        const {
          singleRoutesAndRoutinesList: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.robot.isLoading = false;
        draft.robot.data = data;
        draft.robot.totalCount = totalCount;
        draft.robot.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.robot.paginationTokens[draft.robot.page + 1] = paginationToken;
        }

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_ERROR: {
        draft.robot.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST: {
        draft.robot.isLoadingMoreData = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_SUCCESS: {
        const {
          singleRoutesAndRoutinesList: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;

        draft.robot.isLoadingMoreData = false;
        draft.robot.data = (draft.robot.data || []).concat(data);
        draft.robot.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_ERROR: {
        draft.robot.isLoadingMoreData = false;
        return draft;
      }

      case CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.robot.page = page;
        return draft;
      }

      case CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.robot.paginationTokens = {};
        draft.robot.page = DEFAULT_PAGE_VALUE;
        draft.robot.pageSize = pageSize;
        return draft;
      }

      case CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.robot.sortOptions.field = field;
        return draft;
      }

      case CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.robot.sortOptions.order = order;
        draft.robot.paginationTokens = {};
        draft.robot.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case CleaningReportListActions.ROBOT_RESET_STATE: {
        draft.robot = initialState.robot;
        return draft;
      }

      case CleaningReportListActions.SET_ACTIVE_PERIOD_FILTER: {
        const { period } = action.payload;

        draft.filters.active.period = period;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available = action.payload;

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.SET_ACTIVE_MACHINES_FILTER: {
        const { machines } = action.payload;

        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.paginationTokens = {};
        draft.filters.active.machines = machines;
        return draft;
      }

      case CleaningReportListActions.SET_ACTIVE_SITE_FILTER: {
        const { siteIds } = action.payload;

        draft.filters.active.siteIds = siteIds;
        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.paginationTokens = {};
        return draft;
      }

      case CleaningReportListActions.SET_ACTIVE_MACHINE_STATUS_FILTER: {
        const { lastActivityAt } = action.payload;

        draft.filters.active.lastActivityAt = lastActivityAt;
        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.paginationTokens = {};
        return draft;
      }

      case CleaningReportListActions.SET_ACTIVE_TASK_COMPLETION_FILTER: {
        const { taskCompletion } = action.payload;

        draft.filters.active.taskCompletion = taskCompletion;
        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.paginationTokens = {};
        return draft;
      }

      case CleaningReportListActions.RESET_ACTIVE_MACHINE_STATUS_FILTER: {
        draft.filters.active.lastActivityAt = initialState.filters.active.lastActivityAt;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST: {
        // Reset machines selection of the current list before get the new one
        draft.filters.active.machines = initialState.filters.active.machines;
        draft.filters.isLoading = true;
        draft.totals.isLoading = true;

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_SUCCESS: {
        draft.filters.isLoading = false;
        draft.totals.isLoading = false;
        draft.filters.available.machines = action.payload.machines.data;

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_ERROR: {
        draft.filters.isLoading = false;
        draft.totals.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_REQUEST: {
        draft.export.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_SUCCESS: {
        const {
          cleaningReportsExportRequest: { data },
        } = action.payload as CleaningReportsExportRequest;

        draft.export.requestId = data.requestId;
        draft.export.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_SUCCESS: {
        const {
          cleaningReportsExportGetFile: { data },
        } = action.payload as CleaningReportsExportGetFile;

        draft.exportRobotDetails.id = null;
        draft.exportRobotDetails.isLoading = false;
        draft.exportRobotDetails.downloadUrl = data.presignedUrl;
        return draft;
      }

      case CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_ERROR: {
        draft.exportRobotDetails.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_RESET_STATE: {
        draft.exportRobotDetails = initialState.exportRobotDetails;
        return draft;
      }

      case CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST: {
        draft.exportRobotDetails.isLoading = true;
        draft.exportRobotDetails.id = action.payload.id;
        return draft;
      }

      case CleaningReportListActions.SET_SELECT_DEVICE_TYPE: {
        draft.selectTab.deviceType = action.payload;
        return draft;
      }

      case CleaningReportListActions.RESET_CLEANING_REPORT_LIST_ACTIVE_FILTERS: {
        draft.filters = initialState.filters;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST: {
        // Reset machines selection of the current list before get the new one
        draft.filters.active.machines = initialState.filters.active.machines;
        draft.filters.isLoading = true;
        draft.totals.isLoading = true;

        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_SUCCESS: {
        draft.filters.isLoading = false;
        draft.totals.isLoading = false;
        draft.filters.available.standAloneMachines = action.payload.machines.data;
        return draft;
      }

      case CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_ERROR: {
        draft.filters.isLoading = false;
        draft.totals.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.RESET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER: {
        draft.filters.available.standAloneMachines = initialState.filters.available.standAloneMachines;
        return draft;
      }

      case CleaningReportListActions.RESET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER: {
        draft.filters.available.machines = initialState.filters.available.machines;
        return draft;
      }

      case CleaningReportListActions.SET_SEARCH_TEXT_FILTER: {
        const { searchText } = action.payload;

        draft.filters.active.searchText = searchText;
        draft.totals.page = DEFAULT_PAGE_VALUE;
        draft.totals.paginationTokens = {};
        draft.exact.page = DEFAULT_PAGE_VALUE;
        draft.exact.paginationTokens = {};
        return draft;
      }

      case CleaningReportListActions.RESET_CLEANING_REPORT_LIST_FILTER_SELECTED: {
        draft.filters.active.machines = initialState.filters.active.machines;
        draft.filters.active.siteIds = initialState.filters.active.siteIds;
        draft.filters.active.lastActivityAt = initialState.filters.active.lastActivityAt;
        draft.filters.active.taskCompletion = initialState.filters.active.taskCompletion;
        draft.filters.active.searchText = initialState.filters.active.searchText;

        return draft;
      }

      case CleaningReportListActions.SHOW_REPORT_SUBSCRIPTION_DRAWER: {
        draft.reportSubscriptionDrawer.visible = true;
        return draft;
      }

      case CleaningReportListActions.HIDE_REPORT_SUBSCRIPTION_DRAWER: {
        draft.reportSubscriptionDrawer.visible = false;
        return draft;
      }

      case CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_LOADING: {
        draft.reportSubscriptionDrawer.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_NOT_LOADING: {
        draft.reportSubscriptionDrawer.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.GET_REPORT_SUBSCRIPTION_REQUEST: {
        draft.reportSubscriptionDrawer.reportSubscription.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.GET_REPORT_SUBSCRIPTION_SUCCESS: {
        const {
          cleaningReportSubscriptions: { data },
        } = action.payload;
        draft.reportSubscriptionDrawer.reportSubscription.data = data;
        draft.reportSubscriptionDrawer.reportSubscription.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.GET_REPORT_SUBSCRIPTION_ERROR: {
        draft.reportSubscriptionDrawer.reportSubscription.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_REQUEST: {
        draft.reportSubscriptionDrawer.saveReportSubscription.isLoading = true;
        return draft;
      }

      case CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_SUCCESS: {
        const {
          cleaningReportSubscriptionUpdateBatch: { data },
        } = action.payload;
        draft.reportSubscriptionDrawer.saveReportSubscription.data = data;
        draft.reportSubscriptionDrawer.saveReportSubscription.isLoading = false;
        return draft;
      }

      case CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_ERROR: {
        draft.reportSubscriptionDrawer.saveReportSubscription.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
