import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { StyledRoutineKpiBar } from './RoutineKpiBar.styles';
import { ShortInfoRoutineDetails } from './ShortInfoRoutineDetails/ShortInfoRoutineDetails';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import * as cleaningReportListSelectors from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListSelectors';

export const RoutineKpiBar = (): JSX.Element => {
  const { t } = useTranslation();
  const isUSusers = false;

  const routineConsumption = useSelector(cleaningReportListSelectors.selectRoutineConsumptionData);
  const routineKpi = useSelector(cleaningReportListSelectors.selectRoutineKPIsData);
  const routineKpiLoading = useSelector(cleaningReportListSelectors.selectRoutineCleaningTaskReportLoading);

  const totalCleaningHours = Numbers.formatNumberDecimalRounded(routineKpi?.totalCleaningHrs || 0, 1);
  const totalCleanedArea = Numbers.formatNumberDecimalRounded(routineKpi?.totalCleanedArea || 0, 1);
  const totalCleanedAreaUSUnit = ConvertUnitUtils.converMetersToMiles(routineKpi?.totalCleanedArea || 0);
  const distanceDriven = ConvertUnitUtils.convertMetersToKilometers(routineKpi?.distanceDriven || 0);
  const distanceDrivenUSUnit = ConvertUnitUtils.converMetersToMiles(routineKpi?.distanceDriven || 0);
  const taskCoverage = Math.round(routineKpi?.taskCoverage || 0 * 100);
  const tasksCompleted = Math.round(routineKpi?.tasksCompleted || 0);

  const batteryValue = Numbers.formatNumberDecimalRounded(routineConsumption?.battery || 0, 1);
  const waterValue = Numbers.formatNumberDecimalRounded(routineConsumption?.water || 0, 1);
  const detergentsValue = Numbers.formatNumberDecimalRounded(routineConsumption?.detergents || 0, 1);

  return (
    <StyledRoutineKpiBar className="routine-kpi-bar">
      <Spin spinning={routineKpiLoading}>
        <Row gutter={[16, 24]}>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="wallClock" />}
              value={totalCleaningHours}
              unit={t('common.hUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="clean" />}
              value={isUSusers ? totalCleanedAreaUSUnit : totalCleanedArea}
              unit={isUSusers ? t('common.areaUnitFeetSquare') : t('common.areaUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="distance" />}
              value={isUSusers ? distanceDrivenUSUnit : distanceDriven}
              unit={isUSusers ? t('common.mileUnit') : t('common.kmUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="coverage" />}
              value={taskCoverage}
              unit={t('common.percentUnit')}
            />
          </Col>

          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="successRace" />}
              value={tasksCompleted}
              unit={t('common.percentUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="battery" />}
              value={batteryValue}
              unit={t('common.kWhUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="waterAmountDouble" />}
              value={waterValue}
              unit={t('common.litreUnit')}
            />
          </Col>
          <Col span={6}>
            <ShortInfoRoutineDetails
              className="routine-kpi-bar__short-info"
              icon={<SvgIcon name="containerDetergent" />}
              value={detergentsValue}
              unit={t('common.litreUnit')}
            />
          </Col>
        </Row>
      </Spin>
    </StyledRoutineKpiBar>
  );
};
