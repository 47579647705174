import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { debounce, every, flatten, isArray, isEqual, without, xor } from 'lodash-es';
import find from 'lodash-es/find';
import isNil from 'lodash-es/isNil';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'use-deep-compare';
import { OpenSearch } from '../../../../../../config/constants';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { Checkbox } from '../../../../../../lib/components/Checkbox/Checkbox';
import { Drawer } from '../../../../../../lib/components/Drawer/Drawer';
import { Input } from '../../../../../../lib/components/Input/Input';
import { RangePicker } from '../../../../../../lib/components/RangePicker/RangePicker';
import { SelectUtils } from '../../../../../../lib/components/Select/SelectUtils';
import {
  ColumnCustomizationConfig,
  ColumnCustomizerPanel,
} from '../../../../../components/ColumnCustomizerPanel/ColumnCustomizerPanel';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetMachineAttributes } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetMachineAttributes';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsSetSearch } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetSearch';
import {
  AnalyticsLink,
  AnalyticsMachineListFilter,
  IAnalyticsFilter,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useDependencies } from '../../../../../cross-cutting-concerns/dependency-injection/hooks/useDependencies';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineAsReport, MachineStatus, MachineStatusItemData } from '../../../interfaces/Machine.types';
import {
  MachineListPreset,
  MachineListPresetId,
  MachineListPresets,
  PRESET_EDITED,
} from '../../../interfaces/MachineListPreset.types';
import { selectUpdateMachineInfo } from '../../../machine-details-panel/state/machineDetailsPanelSelectors';
import { MachineListActions } from '../../state/machineListActions';
import * as machineListSelectors from '../../state/machineListSelectors';
import { MachineListTabGraphs } from '../MachineListTabGraphs/MachineListTabGraphs';
import { MachineListTabMap } from '../MachineListTabMap/MachineListTabMap';
import { MachineListTabTable } from '../MachineListTabTable/MachineListTabTable';
import { MachineListReportSubscriptionDrawer } from '../MachineListReportSubscriptionDrawer/MachineListReportSubscriptionDrawer';
import { getMachineListColumns } from './columns/MachineList.columns';
import { StyledMachineList } from './MachineList.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { Optional } from 'lib/types/Optional';
import { Tabs } from 'lib/components/Tabs/Tabs';
import { ColumnsTypeForCustomizableTable } from 'lib/components/Table/Table';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Select } from 'lib/components/Select/Select';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { OperatingHoursChartUtils } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { CleaningReportRequestDateRangeRestrictor } from 'app/modules/cleaning/CleaningReportRequestDateRangeRestrictor';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import {
  FinanceType,
  InputFilterMachinesReport,
  InputPeriod,
  InputSortOptions,
  MachineClassification,
  ServicePackage,
  SortOrders,
  FilterLastActivityAt,
  Column as MachineListColumn,
  MachineSubclassification,
  DataStatus,
  InputMachineFilterFilter,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  selectHasAccessToGCD,
  selectHasAccessToRobots,
} from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { useAnalyticsSetFilter } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import { RoutePaths } from 'config/route-paths';

const MACHINE_LIST_COLUMN_CUSTOMIZATION_CONFIG_KEY = 'machine-list-column-customization-config';

export enum MachineListTab {
  TABLE = 'TABLE',
  GRAPHS = 'GRAPHS',
  MAP = 'MAP',
}

export const MachineList = (): React.JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { browserStorage } = useDependencies();
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [periodForm] = Form.useForm();
  const [categoriesForm] = Form.useForm();
  const totalCount = useSelector(machineListSelectors.selectTotalCount) || 0;
  const page = useSelector(machineListSelectors.selectPage);
  const pageSize = useSelector(machineListSelectors.selectPageSize);
  const sortField = useSelector(machineListSelectors.selectSortField) || 'machineTypeName';
  const sortOrder = useSelector(machineListSelectors.selectSortOrder) || SortOrders.Asc;
  const isMapEnabled = features.MACHINE_LIST_MAP;
  const exportIsLoading = useSelector(machineListSelectors.selectExportIsLoading) || false;
  const machineInfo = useSelector(selectUpdateMachineInfo);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [isColumnCustomizationPanelOpen, setIsColumnCustomizationPanelOpen] = useState(false);

  const activePeriodFilter = useSelector(machineListSelectors.selectActivePeriodFilter);
  const activeSearchTextFilter = useSelector(machineListSelectors.selectActiveSearchTextFilter);
  const activeMachineTypeFilter = useSelector(machineListSelectors.selectActiveMachineTypeFilter);
  const activeSiteFilter = useSelector(machineListSelectors.selectActiveSiteFilter);
  const activeMaterialNumberFilter = useSelector(machineListSelectors.selectActiveMaterialNumberFilter);
  const activeMachineStatusFilter = useSelector(machineListSelectors.selectActiveMachineStatusFilter);
  const availableMachineFilterData = useSelector(machineListSelectors.selectMachineFilterData);
  const isAvailableMachineFilterLoading = !!useSelector(machineListSelectors.selectIsMachineFilterLoading);

  const hasAccessToGCD = useSelector(selectHasAccessToGCD);
  const hasAccessToRobot = useSelector(selectHasAccessToRobots);
  const subscriptionVisible = useSelector(machineListSelectors.selectSubscriptionIsVisible) || false;

  const isBatteryDeepDischargeEnabled = features.BATTERY_DEEP_DISCHARGE;

  const getActiveCategoriesFilterInit = useMemo(() => {
    if (hasAccessToGCD && !hasAccessToRobot) {
      return [MachineClassification.Gcd];
    }

    if (!hasAccessToGCD && hasAccessToRobot) {
      return [MachineClassification.Robot];
    }

    return [MachineClassification.Gcd, MachineClassification.Robot];
  }, [hasAccessToGCD, hasAccessToRobot]);

  const [activeCategoriesFilter, setActiveCategoriesFilter] = useState<MachineClassification[] | undefined>(
    getActiveCategoriesFilterInit
  );
  const [activeIotClassificationFilter, setActiveIotClassificationFilter] = useState<string | undefined>('');
  const [activeFinanceTypeFilter, setActiveFinanceTypeFilter] = useState<string[] | undefined>(undefined);
  const [activeServiceAgreementTypeFilter, setActiveServiceAgreementTypeFilter] = useState<string[] | undefined>(
    undefined
  );
  const [isMachineTypeFilterOpen, setIsMachineTypeFilterOpen] = useState(false);
  const [isSitesFilterOpen, setIsSitesFilterOpen] = useState(false);
  const [isMachineStatusFilterOpen, setIsMachineStatusFilterOpen] = useState(false);
  const [isMaterialNumberFilterOpen, setIsMaterialNumberFilterOpen] = useState(false);
  const [isIotClassificationFilterOpen, setIsIotClassificationFilterOpen] = useState(false);
  const [isFinanceTypeFilterOpen, setIsFinanceTypeFilterOpen] = useState(false);
  const [isServiceAgreementFilterOpen, setIsServiceAgreementFilterOpen] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    type: boolean;
    planned: boolean;
    operating: boolean;
    average: boolean;
    total: boolean;
    activation: boolean;
    contract: boolean;
  }>({
    type: false,
    planned: false,
    operating: false,
    average: false,
    total: false,
    activation: false,
    contract: false,
  });

  const [activePreset, setActivePreset] = useState<MachineListPreset | typeof PRESET_EDITED>(
    MachineListPresets[MachineListPresetId.ALL_DATA]
  );

  const getTotalFilterCount = (): number => {
    const sanitizedActiveMachineTypeFilter = activeMachineTypeFilter ?? [];
    const sanitizedActiveSiteFilter = activeSiteFilter ?? [];
    const sanitizedActiveMaterialNumberFilter = activeMaterialNumberFilter ?? [];
    const sanitizedMachineStatusFilter =
      ((activeMachineStatusFilter !== '' ? [activeMachineStatusFilter] : []) as unknown as string[]) ?? [];
    const sanitizedIotClassificationFilter =
      ((activeIotClassificationFilter !== '' ? [activeIotClassificationFilter] : []) as unknown as string[]) ?? [];
    const sanitizedActiveFinanceTypeFilter = activeFinanceTypeFilter ?? [];
    const sanitizedActiveServiceAgreementTypeFilter = activeServiceAgreementTypeFilter ?? [];

    const count = [
      sanitizedActiveMachineTypeFilter,
      sanitizedActiveSiteFilter,
      sanitizedActiveMaterialNumberFilter,
      sanitizedMachineStatusFilter,
      sanitizedIotClassificationFilter,
      sanitizedActiveFinanceTypeFilter,
      sanitizedActiveServiceAgreementTypeFilter,
    ]
      .map(value => {
        if (!isArray(value)) {
          return [value];
        }

        return value;
      })
      .reduce((acc, filterValues) => acc + (filterValues ?? []).length, 0);

    return count;
  };

  const { id } = useParams();

  const handleClickSiteName = (siteId: string): void => {
    if (location.pathname.startsWith(RoutePaths.MACHINES)) {
      navigate(RoutePaths.MACHINES);
    }
    dispatch(DrawersActions.showSiteDetailsDrawer({ siteId }));
  };

  const tableColumns = getMachineListColumns({
    t,
    i18n,
    handleClickSiteName,
    sortedInfo: {
      columnKey: sortField,
      order: sortOrder === SortOrders.Asc ? 'ascend' : 'descend',
    },
    isTooltipVisible,
    setIsTooltipVisible,
    isBatteryDeepDischargeEnabled,
  });
  const customizableColumnIds: MachineListColumn[] = tableColumns
    .filter(columnDef => columnDef.customizable === true)
    .map(columnDefinition => columnDefinition.columnId);

  let initialColumnCustomizationConfig: MachineListColumn[];

  try {
    const initialColumnCustomizationConfigString = browserStorage.get(MACHINE_LIST_COLUMN_CUSTOMIZATION_CONFIG_KEY);

    if (initialColumnCustomizationConfigString) {
      initialColumnCustomizationConfig = JSON.parse(initialColumnCustomizationConfigString);
    } else {
      initialColumnCustomizationConfig = customizableColumnIds;
    }
  } catch (error) {
    initialColumnCustomizationConfig = customizableColumnIds;
  }

  const [columnCustomizationConfig, setColumnCustomizationConfig] = useState<
    ColumnCustomizationConfig<MachineListColumn>
  >(initialColumnCustomizationConfig);
  const columnIdsOfAllColumns = tableColumns.map(columnDefinition => columnDefinition.columnId);

  // Filter table column definitions that are passed to <Table> down to the columns specified
  // in column customization config
  const customizedTableColumns = useMemo(() => {
    const nonCustomizableColumns = tableColumns.filter(columnDefinition => columnDefinition.customizable === false);

    const customizableColumns = columnCustomizationConfig.reduce(
      (
        columnDefinitions: ColumnsTypeForCustomizableTable<MachineListColumn, MachineAsReport>,
        columnId: MachineListColumn
      ) => {
        const columnDefinition = tableColumns
          .filter(columnDef => columnDef.customizable === true)
          .find(columnDef => columnDef.columnId === columnId);

        if (columnDefinition) {
          columnDefinitions.push(columnDefinition);
        }

        return columnDefinitions;
      },
      []
    );

    return [...nonCustomizableColumns, ...customizableColumns];
  }, [columnCustomizationConfig, tableColumns]);

  const getActiveFiltersCallback = useCallback((): IAnalyticsFilter[] => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(activeMachineTypeFilter) && activeMachineTypeFilter?.length > 0) {
      activeFilters.push(AnalyticsMachineListFilter.TYPE);
    }

    if (!isNil(activeMaterialNumberFilter) && activeMaterialNumberFilter?.length > 0) {
      activeFilters.push(AnalyticsMachineListFilter.MATERIAL_NUMBER);
    }

    if (!isNil(activeSiteFilter) && activeSiteFilter?.length > 0) {
      activeFilters.push(AnalyticsMachineListFilter.ASSIGNED_SITES);
    }

    if (!isNil(activeMachineStatusFilter)) {
      activeFilters.push(AnalyticsMachineListFilter.STATUS);
    }

    if (!isNil(activeSearchTextFilter)) {
      activeFilters.push(AnalyticsMachineListFilter.SEARCH);
    }

    return activeFilters;
  }, [
    activeMachineStatusFilter,
    activeMachineTypeFilter,
    activeMaterialNumberFilter,
    activeSearchTextFilter,
    activeSiteFilter,
  ]);

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });
  useAnalyticsSetSearch(activeSearchTextFilter);
  useAnalyticsSetMachineAttributes({
    count: totalCount,
  });
  useAnalyticsSetPageInfo({});

  const availableNewMaterialNumbers = useMemo<string[]>((): string[] => {
    const allMachineTypesSelected: boolean =
      !activeMachineTypeFilter || (isArray(activeMachineTypeFilter) && activeMachineTypeFilter.length <= 0);

    // Show material numbers as selection based on the rules below
    // 1. If a user doesn't choose any machine type, then display all material numbers from machine types
    // 2. If a user chooses one machine type, then display machine type's material numbers
    if (
      !availableMachineFilterData?.machineTypes ||
      (isArray(availableMachineFilterData?.machineTypes) && availableMachineFilterData?.machineTypes.length <= 0)
    ) {
      return [];
    }

    if (allMachineTypesSelected) {
      return flatten<string>(
        availableMachineFilterData?.machineTypes.map(machineType => machineType.materialNumbers || [])
      ).sort((a, b) => a.localeCompare(b, undefined, { ignorePunctuation: true })) as string[];
    }

    const selectedMachineTypeMaterialNumbers: string[] = availableMachineFilterData?.machineTypes
      .filter(machineType => {
        if (isNil(machineType.name)) {
          return false;
        }

        return (activeMachineTypeFilter || []).includes(machineType.name);
      })
      .flatMap(machineType => {
        if (isNil(machineType.materialNumbers)) {
          return [];
        }

        return machineType.materialNumbers;
      });

    return selectedMachineTypeMaterialNumbers || [];
  }, [activeMachineTypeFilter, availableMachineFilterData]);

  const materialNumbersFilter = useMemo<string[] | undefined>((): string[] | undefined => {
    // Send filter value of the field material number selection as the rule below
    // 1. If a user doesn't choose any machine type => materialNumbers filter value is undefined
    // 2. If a user chooses one machine type with default material number selection value,
    //    => materialNumbers filter value is selected machine type's material numbers
    // 3. If a user chooses one machine type and choose one material number,
    //    => materialNumbers filter value is [selected material numbers]
    if (!activeMachineTypeFilter || activeMachineTypeFilter.length <= 0) {
      if (activeMaterialNumberFilter && activeMaterialNumberFilter.length > 0) {
        return activeMaterialNumberFilter;
      }

      return undefined;
    }

    if (activeMaterialNumberFilter && activeMaterialNumberFilter.length > 0) {
      return activeMaterialNumberFilter;
    }

    return availableNewMaterialNumbers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMachineTypeFilter, activeMaterialNumberFilter]);

  const requestParams = (): {
    period: InputPeriod;
    search: string;
    filter: InputFilterMachinesReport;
    sortOptions?: InputSortOptions;
    timezone: string;
  } => {
    const rangeMap = {
      [DataStatus.LessThanOneDayOld]: {
        from: DateTime.oneDayAgo().toISOString(),
        to: DateTime.today().toISOString(),
      },
      [DataStatus.OneToThreeDaysOld]: {
        from: DateTime.threeDaysAgo().toISOString(),
        to: DateTime.oneDayAgo().toISOString(),
      },
      [DataStatus.GreaterThanThreeDaysOld]: {
        from: DateTime.beginningOfEpochTime().toISOString(),
        to: DateTime.threeDaysAgo().toISOString(),
      },
      [DataStatus.NoData]: null,
    };

    const lastActivityAt = rangeMap[
      activeMachineStatusFilter as keyof typeof rangeMap
    ] as Optional<FilterLastActivityAt>;

    return {
      period: {
        startAt: activePeriodFilter.startAt,
        endAt: activePeriodFilter.endAt,
      },
      search: activeSearchTextFilter ?? '',
      filter: {
        materialNumbers: materialNumbersFilter,
        siteIds: activeSiteFilter,
        lastActivityAt,
        classifications: activeCategoriesFilter,
        isIoTDevice: activeIotClassificationFilter ? activeIotClassificationFilter === 'true' : undefined,
        financeTypes: activeFinanceTypeFilter as FinanceType[] | undefined,
        servicePackages: activeServiceAgreementTypeFilter as ServicePackage[] | undefined,
      },
      sortOptions: {
        field: sortField,
        order: sortOrder,
      },
      timezone: DateTime.getBrowserTimeZone(),
    };
  };

  const resetFilterByColumnId = useCallback(
    (columnId: MachineListColumn): void => {
      switch (columnId) {
        case MachineListColumn.MachineType: {
          dispatch(
            MachineListActions.setActiveMachineTypeFilter({
              machineTypes: [],
            })
          );
          break;
        }

        case MachineListColumn.SiteName: {
          dispatch(MachineListActions.setActiveSiteFilter([]));
          break;
        }

        case MachineListColumn.DataStatus: {
          dispatch(MachineListActions.setActiveMachineStatusFilter(''));
          break;
        }

        case MachineListColumn.MaterialNumber: {
          dispatch(MachineListActions.setActiveMaterialNumberFilter([]));
          break;
        }

        case MachineListColumn.Classification: {
          setActiveIotClassificationFilter('');
          break;
        }

        case MachineListColumn.FinanceContractType: {
          setActiveFinanceTypeFilter([]);
          break;
        }

        case MachineListColumn.ServiceAgreementType: {
          setActiveServiceAgreementTypeFilter([]);
          break;
        }

        default:
          break;
      }
    },
    [dispatch]
  );

  const fetchData = (): void => {
    dispatch(
      MachineListActions.getMachineListAsReportRequest({
        ...requestParams(),
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
          paginationToken: '',
        },
      })
    );
  };

  const tabs = [
    {
      label: t('machineList.tab.table'),
      key: MachineListTab.TABLE,
      children: <MachineListTabTable customizedTableColumns={customizedTableColumns} />,
    },
    ...(hasAccessToGCD
      ? [
          {
            label: t('machineList.tab.graphs'),
            key: MachineListTab.GRAPHS,
            children: <MachineListTabGraphs period={activePeriodFilter} />,
          },
        ]
      : []),
    ...(isMapEnabled
      ? [
          {
            label: t('machineList.tab.map'),
            key: MachineListTab.MAP,
            children: <MachineListTabMap />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeMachineStatusFilter,
    activeSiteFilter,
    activeMaterialNumberFilter,
    dispatch,
    page,
    pageSize,
    sortField,
    sortOrder,
    materialNumbersFilter,
    activeSearchTextFilter,
    activeCategoriesFilter,
    activeIotClassificationFilter,
    activeFinanceTypeFilter,
    activeServiceAgreementTypeFilter,
    activePeriodFilter.startAt,
    activePeriodFilter.endAt,
    machineInfo,
  ]);

  useEffect(
    () => (): void => {
      dispatch(MachineListActions.resetState());
      dispatch(DrawersActions.hideMachineDetailsDrawer());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: id }));
    }
  }, [dispatch, id]);

  const requestFilterParams = (): {
    filter: InputMachineFilterFilter;
    timezone: string;
  } => {
    const dataStatusesFilter = !activeMachineStatusFilter?.includes('') && [activeMachineStatusFilter as DataStatus];

    const subClassificationsFilter = !activeIotClassificationFilter?.includes('') && [
      activeIotClassificationFilter as MachineSubclassification,
    ];

    const servicePackagesFilter =
      !activeServiceAgreementTypeFilter?.includes('') && (activeServiceAgreementTypeFilter as ServicePackage[]);

    const filter = {
      classifications: activeCategoriesFilter || undefined,
      siteIds: activeSiteFilter || undefined,
      dataStatuses: dataStatusesFilter || undefined,
      materialNumbers: materialNumbersFilter || undefined,
      subclassifications: subClassificationsFilter || undefined,
      financeTypes: activeFinanceTypeFilter as FinanceType[] | undefined,
      servicePackages: servicePackagesFilter || undefined,
    };

    const timezone = DateTime.getBrowserTimeZone();

    return {
      filter,
      timezone,
    };
  };

  const fetchMachineFilterData = (): void => {
    dispatch(MachineListActions.getMachineFilterRequest(requestFilterParams()));
  };

  useEffect(() => {
    fetchMachineFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyPreset = useCallback(
    (presetId: MachineListPresetId) => {
      const preset = MachineListPresets[presetId];

      setColumnCustomizationConfig(preset.columnCustomizationConfig);

      Object.entries(preset.filter).forEach(([filterKey, filterValue]): void => {
        switch (filterKey) {
          case 'classifications': {
            setActiveCategoriesFilter(filterValue as MachineClassification[] | undefined);
            break;
          }

          case 'machineTypes': {
            dispatch(
              MachineListActions.setActiveMachineTypeFilter({
                machineTypes: filterValue as Optional<string[]>,
              })
            );
            break;
          }

          case 'sites': {
            dispatch(MachineListActions.setActiveSiteFilter(filterValue as string[]));
            break;
          }

          case 'machineStatus': {
            dispatch(MachineListActions.setActiveMachineStatusFilter(filterValue as Optional<string>));
            break;
          }

          case 'materialNumbers': {
            dispatch(MachineListActions.setActiveMaterialNumberFilter(filterValue as Optional<string[]>));
            break;
          }

          case 'iotClassification': {
            setActiveIotClassificationFilter(filterValue as string | undefined);
            break;
          }

          case 'financeType': {
            setActiveFinanceTypeFilter(filterValue as string[] | undefined);
            break;
          }

          case 'serviceAgreementType': {
            setActiveServiceAgreementTypeFilter(filterValue as string[] | undefined);
            break;
          }

          default: {
            break;
          }
        }
      });

      const hiddenColumns = without(columnIdsOfAllColumns, ...preset.columnCustomizationConfig);
      hiddenColumns.forEach(columnId => resetFilterByColumnId(columnId));

      // TODO: Fix display state of sorting indicators after apply
      dispatch(MachineListActions.setActiveSortField(preset.sorting.field));
      dispatch(MachineListActions.setActiveSortOrder(preset.sorting.order));
    },
    [columnIdsOfAllColumns, dispatch, resetFilterByColumnId]
  );

  const isPresetActive = useCallback(
    (presetId: MachineListPresetId): boolean => {
      // xor() compares two arrays A and B and returns all elements that are in A but not in B as well as all elements
      // that are in B and not in A. If the returning array is empty it means both have the same elements.
      // In comparison to isEqual() the xor() function allows to ignore the order of elements for the purpose of
      // the comparison.
      const columnCustomizationConfigMatches =
        xor(columnCustomizationConfig, MachineListPresets[presetId].columnCustomizationConfig).length === 0;

      const filtersMatch = every(
        Object.entries(MachineListPresets[presetId].filter),
        ([filterKey, filterValue]): boolean => {
          switch (filterKey) {
            case 'classifications': {
              if ((!hasAccessToGCD && hasAccessToRobot) || (hasAccessToGCD && !hasAccessToRobot)) {
                return true;
              }

              return xor(activeCategoriesFilter, filterValue).length === 0;
            }

            case 'machineTypes': {
              return xor(activeMachineTypeFilter, filterValue).length === 0;
            }

            case 'sites': {
              return xor(activeSiteFilter, filterValue).length === 0;
            }

            case 'machineStatus': {
              return !!isEqual(activeMachineStatusFilter, filterValue);
            }

            case 'materialNumbers': {
              return xor(activeMaterialNumberFilter, filterValue).length === 0;
            }

            case 'iotClassification': {
              return !!isEqual(activeIotClassificationFilter, filterValue);
            }

            case 'financeType': {
              return xor(activeFinanceTypeFilter, filterValue).length === 0;
            }

            case 'serviceAgreementType': {
              return xor(activeServiceAgreementTypeFilter, filterValue).length === 0;
            }

            default: {
              return false;
            }
          }
        }
      );

      const sortingMatches =
        MachineListPresets[presetId].sorting.field === sortField &&
        MachineListPresets[presetId].sorting.order === sortOrder;

      const allIsMatching = every([columnCustomizationConfigMatches, filtersMatch, sortingMatches]);

      return allIsMatching;
    },
    [
      activeCategoriesFilter,
      activeFinanceTypeFilter,
      activeIotClassificationFilter,
      activeMachineStatusFilter,
      activeMachineTypeFilter,
      activeMaterialNumberFilter,
      activeServiceAgreementTypeFilter,
      activeSiteFilter,
      columnCustomizationConfig,
      sortField,
      sortOrder,
      hasAccessToGCD,
      hasAccessToRobot,
    ]
  );

  useDeepCompareEffect(() => {
    const activePresetMatch = find(Object.values(MachineListPresets), availablePreset =>
      isPresetActive(availablePreset.id)
    );

    if (activePresetMatch !== undefined) {
      setActivePreset(activePresetMatch);
    } else {
      setActivePreset(PRESET_EDITED);
    }
  }, [activePreset, isPresetActive]);

  const getPresetDropdownOptions = useCallback(() => {
    const options: {
      label: string;
      value: MachineListPresetId | typeof PRESET_EDITED;
    }[] = [
      ...Object.values([MachineListPresetId.ALL_DATA]).map(presetId => ({
        label: t(`machineList.presets.options.${presetId}`),
        value: presetId,
      })),
    ];

    if (hasAccessToGCD) {
      options.push({
        label: t(`machineList.presets.options.${MachineListPresetId.INVENTORY_MACHINES}`),
        value: MachineListPresetId.INVENTORY_MACHINES,
      });
    }

    if (activePreset === PRESET_EDITED) {
      options.push({
        label: t('machineList.presets.presetEdited'),
        value: PRESET_EDITED,
      });
    }

    options.push({
      label: t(`machineList.presets.options.${MachineListPresetId.CLEANING_ANALYSIS}`),
      value: MachineListPresetId.CLEANING_ANALYSIS,
    });

    return options;
  }, [activePreset, hasAccessToGCD, t]);

  const handleMaterialNumberFilterChange = (values: SelectValue): void => {
    const materialNumberValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_MATERIAL_NO_FILTER,
    });
    dispatch(MachineListActions.setActiveMaterialNumberFilter(materialNumberValues));
  };

  const handleSiteFilterChange = (values: SelectValue): void => {
    const siteValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_ASSIGNED_SITE_FILTER,
    });
    dispatch(MachineListActions.setActiveSiteFilter(siteValues));
  };

  const handleMachineTypeFilterChange = (values: SelectValue): void => {
    const machineTypeValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_MACHINE_TYPE_FILTER,
    });
    dispatch(
      MachineListActions.setActiveMachineTypeFilter({
        machineTypes: machineTypeValues,
      })
    );
  };

  const handleMachineStatusFilterChange = (value: SelectValue): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_MACHINE_STATUS_FILTER,
    });

    dispatch(MachineListActions.setActiveMachineStatusFilter(value as MachineStatus));
  };

  const handleIotClassificationFilterChange = (value: string | undefined): void => {
    setActiveIotClassificationFilter(value);
  };

  const handleFinanceTypeFilterChange = (values: string[] | undefined): void => {
    const financeTypeValues = values as string[];

    setActiveFinanceTypeFilter(financeTypeValues);
  };

  const handleServiceAgreementTypeFilterChange = (values: string[] | undefined): void => {
    const serviceAgreementValues = values as string[];

    setActiveServiceAgreementTypeFilter(serviceAgreementValues);
  };

  const handleMachinesReportExport = (): void => {
    dispatch(
      MachineListActions.requestExportMachinesReportRequest({
        ...requestParams(),
        columns:
          customizedTableColumns.length === tableColumns.length
            ? undefined
            : customizedTableColumns.map(column => column.columnId),
        lang: Translations.getSupportedLanguageCode(i18n.language),
      })
    );
  };

  const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim() || undefined;

    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_SEARCH_TERM,
    });

    dispatch(MachineListActions.setSearchText(value as Optional<string>));
  }, 500);

  const onPeriodChange = (values: { dateRangeLocal: [Dayjs | null, Dayjs | null] | null }): void => {
    const { dateRangeLocal: dates } = values;
    if (dates && dates[0] && dates[1]) {
      dispatch(
        MachineListActions.setActiveMachinePeriodFilter({
          startAt: OperatingHoursChartUtils.prepareStartDate(dates[0]?.utc().toDate()).toISOString(),
          endAt: OperatingHoursChartUtils.prepareEndDate(dates[1]?.utc().toDate()).toISOString(),
        })
      );
    }
  };

  const onCategoriesFilterChange = (): void => {
    const { showRobots, showMachines } = categoriesForm.getFieldsValue();

    let updatedCategoriesFilter: MachineClassification[] = [];

    if (showRobots) {
      updatedCategoriesFilter.push(MachineClassification.Robot);
    }

    if (showMachines) {
      updatedCategoriesFilter.push(MachineClassification.Gcd);
    }

    // Re-check both boxes, when last checkbox is unchecked
    if (!showRobots && !showMachines) {
      updatedCategoriesFilter = [MachineClassification.Robot, MachineClassification.Gcd];
      categoriesForm.setFieldsValue({ showRobots: true, showMachines: true });
    }

    setActiveCategoriesFilter(updatedCategoriesFilter);
  };

  const handleReportSubscription = (): void => {
    dispatch(MachineListActions.showMachineReportSubscriptionsDrawer());
  };

  return (
    <StyledMachineList className="machine-list">
      <div className="machine-list__header">
        <div className="machine-list__header-content">
          <div className="machine-list__container--fluid">
            <div className="machine-list__page-info">
              <h1 className="machine-list__title">{t('common.devices')}</h1>
              <div className="machine-list__header-buttons">
                <SecondaryButton
                  className="machine-list__report-subscription-link"
                  size="m"
                  onClick={handleReportSubscription}
                >
                  {t('machineList.reportSubscription.title')}
                </SecondaryButton>
                <PrimaryButton
                  className="machine-list__export-link"
                  size="m"
                  onClick={handleMachinesReportExport}
                  loading={exportIsLoading}
                  icon={<SvgIcon name="exportReport" className="machine-list__export-icon" />}
                >
                  {t('common.exportReport')}
                </PrimaryButton>
              </div>
            </div>

            <>
              <div className="machine-list__filter">
                <div style={{ width: 'auto' }}>
                  <Form
                    form={periodForm}
                    name="machine-list__filter-form"
                    layout="horizontal"
                    onFinish={onPeriodChange}
                    onValuesChange={(): void => periodForm.submit()}
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    initialValues={{
                      dateRangeLocal: [dayjs(activePeriodFilter.startAt), dayjs(activePeriodFilter.endAt)],
                    }}
                  >
                    <Form.Item
                      name="dateRangeLocal"
                      required
                      rules={[
                        {
                          required: true,
                          message: t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeRequired'),
                        },
                        {
                          validator: CleaningReportRequestDateRangeRestrictor.validate,
                        },
                      ]}
                    >
                      <RangePicker disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate} />
                    </Form.Item>
                  </Form>
                </div>
                <Select
                  className="machine-list__preset-select"
                  onChange={applyPreset}
                  showSearch={false}
                  value={activePreset === PRESET_EDITED ? PRESET_EDITED : activePreset?.id}
                  options={getPresetDropdownOptions()}
                  dropdownRender={(menu): React.ReactElement => <>{menu}</>}
                  labelRender={(): React.ReactElement => {
                    const presetLabel =
                      activePreset !== PRESET_EDITED
                        ? t(`machineList.presets.options.${activePreset.id}`)
                        : t('machineList.presets.presetEdited');

                    return (
                      <div className="select__dropdown-label">
                        <div className="select__dropdown-label-text">{presetLabel}</div>
                      </div>
                    );
                  }}
                />
                <SecondaryButton
                  loading={isAvailableMachineFilterLoading}
                  size="s"
                  onClick={(): void => setAreFiltersVisible(prevState => !prevState)}
                  className={classnames('machine-list__filter-button', {
                    'button-active': areFiltersVisible,
                  })}
                  key="machine-list__filter-button"
                >
                  <span>{t('machineList.filter.filter')}</span>
                  {getTotalFilterCount() > 0 && (
                    <span className="machine-list__filter-button-counter">{getTotalFilterCount() || ''}</span>
                  )}
                  <SvgIcon name="filter" className="machine-list__filter-button-icon" />
                </SecondaryButton>
                <SecondaryButton
                  size="s"
                  onClick={(): void => {
                    setIsColumnCustomizationPanelOpen(true);
                  }}
                  className={classnames('machine-list__columns-button', {
                    'button-active': isColumnCustomizationPanelOpen,
                  })}
                  key="machine-list__columns-button"
                >
                  <span>{t('machineList.filter.columns')}</span>
                  <SvgIcon name="column" className="machine-list__columns-button-icon" />
                </SecondaryButton>
                <Input
                  className={classnames('machine-list__search-bar', 'machine-list__input')}
                  placeholder={t('machineList.filter.searchForMachine')}
                  prefix={
                    <span className="machine-list__search-bar-icon">
                      <SearchOutlined />
                    </span>
                  }
                  onChange={handleSearchChange}
                />
              </div>
              <div
                className={classnames('machine-list__filter-wrapper-container', {
                  'machine-list__filter-wrapper-container--hidden': !areFiltersVisible,
                })}
              >
                {hasAccessToGCD && hasAccessToRobot && (
                  <Form
                    form={categoriesForm}
                    name="machine-list__categories-form"
                    className="machine-list__categories-form"
                    layout="horizontal"
                    onFinish={onCategoriesFilterChange}
                    onValuesChange={(): void => categoriesForm.submit()}
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    fields={[
                      {
                        name: ['showRobots'],
                        value: (activeCategoriesFilter || [])?.includes(MachineClassification.Robot),
                      },
                      {
                        name: ['showMachines'],
                        value: (activeCategoriesFilter || [])?.includes(MachineClassification.Gcd),
                      },
                    ]}
                  >
                    <div className="machine-list__select-wrapper">
                      <Form.Item name="showRobots" valuePropName="checked">
                        <Checkbox className="machine-list__categories-form-checkbox">
                          <div className="machine-list__categories-form-label">
                            {t('machineList.filter.showRobots')}
                          </div>
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="machine-list__select-wrapper">
                      <Form.Item name="showMachines" valuePropName="checked">
                        <Checkbox className="machine-list__categories-form-checkbox">
                          <div className="machine-list__categories-form-label">
                            {t('machineList.filter.showMachines')}
                          </div>
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Form>
                )}
                {columnCustomizationConfig.includes(MachineListColumn.MachineType) && (
                  <div className="machine-list__select-wrapper machine-list__machine-type-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__machine-type-filter machine-list__filter-select"
                      onChange={handleMachineTypeFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableMachineFilterData?.machineTypes || [])
                          .map(machineType => ({
                            label: machineType.name,
                            value: machineType.name,
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label, undefined, { ignorePunctuation: true })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsMachineTypeFilterOpen(isOpen),
                        dropdownVisibleState: isMachineTypeFilterOpen,
                        valueArray: activeMachineTypeFilter,
                        dropdownLabel: t('machineList.table.type'),
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.SiteName) && (
                  <div className="machine-list__select-wrapper machine-list__site-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__site-filter machine-list__filter-select"
                      onChange={handleSiteFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableMachineFilterData?.sites || []).map(site => ({
                          label: site.name,
                          value: site.siteId,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsSitesFilterOpen(isOpen),
                        dropdownVisibleState: isSitesFilterOpen,
                        valueArray: activeSiteFilter,
                        dropdownLabel: t('machineList.filter.sites'),
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.DataStatus) && (
                  <div className="machine-list__select-wrapper machine-list__machine-status-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__machine-status-filter machine-list__filter-select"
                      onChange={handleMachineStatusFilterChange}
                      options={(availableMachineFilterData?.dataStatuses || []).map(value => ({
                        label: t(MachineStatusItemData[value].title),
                        value,
                      }))}
                      {...SelectUtils.getSingleSelectionProps({
                        dropdownLabel: t('machineList.table.dataStatus'),
                        dropdownVisibleState: isMachineStatusFilterOpen,
                        value: activeMachineStatusFilter ?? '',
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsMachineStatusFilterOpen(isOpen),
                        showSearch: false,
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.MaterialNumber) && (
                  <div className="machine-list__select-wrapper machine-list__material-number-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__material-number-filter machine-list__filter-select"
                      onChange={handleMaterialNumberFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableNewMaterialNumbers || []).map(materialNumber => ({
                          label: materialNumber,
                          value: materialNumber,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsMaterialNumberFilterOpen(isOpen),
                        dropdownVisibleState: isMaterialNumberFilterOpen,
                        valueArray: activeMaterialNumberFilter,
                        dropdownLabel: t('machineList.filter.materialNo'),
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.Classification) && (
                  <div className="machine-list__select-wrapper machine-list__classification-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__classification-filter machine-list__filter-select"
                      onChange={handleIotClassificationFilterChange}
                      options={(availableMachineFilterData?.subclassifications || []).map(value => ({
                        label: value === 'IOT' ? t('common.iot') : t('common.nonIot'),
                        value: value === 'IOT' ? 'true' : 'false',
                      }))}
                      {...SelectUtils.getSingleSelectionProps({
                        dropdownLabel: t('machineList.table.classification'),
                        dropdownVisibleState: isIotClassificationFilterOpen,
                        value: activeIotClassificationFilter ?? '',
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsIotClassificationFilterOpen(isOpen),
                        showSearch: false,
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.FinanceContractType) && (
                  <div className="machine-list__select-wrapper machine-list__finance-type-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__finance-type-filter machine-list__filter-select"
                      onChange={handleFinanceTypeFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableMachineFilterData?.financeTypes || []).map(value => ({
                          label: t(`machineModals.editMachineInfo.financeType.${value}`),
                          value,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsFinanceTypeFilterOpen(isOpen),
                        dropdownVisibleState: isFinanceTypeFilterOpen,
                        valueArray: activeFinanceTypeFilter,
                        dropdownLabel: t('machineList.table.contract'),
                      })}
                    />
                  </div>
                )}

                {columnCustomizationConfig.includes(MachineListColumn.ServiceAgreementType) && (
                  <div className="machine-list__select-wrapper machine-list__service-agreement-type-select-wrapper">
                    <Select
                      loading={isAvailableMachineFilterLoading}
                      className="machine-list__service-agreement-type-filter machine-list__filter-select"
                      onChange={handleServiceAgreementTypeFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableMachineFilterData?.servicePackages || []).map(value => ({
                          label: t(`machineModals.editMachineInfo.servicePackage.${value}`),
                          value,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsServiceAgreementFilterOpen(isOpen),
                        dropdownVisibleState: isServiceAgreementFilterOpen,
                        valueArray: activeServiceAgreementTypeFilter,
                        dropdownLabel: t('machineList.table.serviceAgreement'),
                      })}
                    />
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="machine-list__body">
        <div className="machine-list__body-content">
          <div className="machine-list__container--fluid machine-list__container--bottom-margin">
            <Tabs destroyInactiveTabPane items={tabs} defaultActiveKey={MachineListTab.TABLE} />
          </div>
        </div>
      </div>
      <Drawer
        className="machine-list__column-customizer-panel-drawer"
        size="small"
        open={isColumnCustomizationPanelOpen}
        onClose={(): void => {
          setIsColumnCustomizationPanelOpen(false);
        }}
      >
        <ColumnCustomizerPanel<MachineListColumn, MachineAsReport>
          columnDefinitions={tableColumns}
          columnCustomizationConfig={columnCustomizationConfig}
          onSubmit={(config, hiddenColumns): void => {
            try {
              const configString = JSON.stringify(config);
              browserStorage.set(MACHINE_LIST_COLUMN_CUSTOMIZATION_CONFIG_KEY, configString);
            } catch (error) {
              console.error(error);
            }

            hiddenColumns.forEach(columnId => resetFilterByColumnId(columnId));

            setColumnCustomizationConfig(config);
            setIsColumnCustomizationPanelOpen(false);
          }}
          onCancel={(): void => {
            setIsColumnCustomizationPanelOpen(false);
          }}
        />
      </Drawer>

      {subscriptionVisible && <MachineListReportSubscriptionDrawer />}
    </StyledMachineList>
  );
};
