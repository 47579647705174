import { Action } from 'redux';
import { UploadFile } from 'antd';
import { Optional } from '../../../../../lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { RemindersListResponse } from '../../../reminder/interfaces/Reminder.types';
import { ReminderServiceListOptions } from '../../../reminder/ReminderService';
import {
  GetMachineOperatingTimeForPeriod,
  GetMachinePlannedAndActualTimeForPeriod,
  MachineDetails,
  MachineDetailsLatestCtrDatum,
  MachineDetailsTelemetriesDatum,
  MachineDetailsVariantDatum,
  MachineRobotCleaningConsumptionSummaryResponse,
  MachineUpdateResponse,
} from '../../interfaces/Machine.types';
import {
  MachineClientDetailsOptions,
  MachineClientGetConsumptionsSummaryOptions,
  MachineClientOperatingTimeOptions,
  MachineClientAttachmentsListOptions,
  MachineClientUpdateOptions,
  MachineClientListPlannedAndActualForPeriodOptions,
} from '../../MachineClient';
import { MachineAttachmentClientDeleteOptions } from '../../MachineAttachmentClient';
import { RobotServiceGetRobotDashboardTasksCompletionOptions } from '../../RobotService';
import { RobotDashboardTasksCompletionStatisticResponse } from '../../interfaces/Robot.types';
import {
  MachineAttachmentDeleteResponse,
  MachineAttachmentDownloadResponse,
  MachineAttachmentsListResponse,
} from '../../interfaces/MachineAttachment.types';
import {
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
} from '../../interfaces/MachineSubscription.types';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { NotificationList } from 'app/modules/notification/interfaces/Notification.types';
import { NotificationClientListOptions } from 'app/modules/notification/NotificationClient';
import { NotesListResponse } from 'app/modules/note/interfaces/Note.types';
import { NoteServiceListOptions } from 'app/modules/note/NoteService';
import { CleaningTaskReportClientListOptions } from 'app/modules/cleaning/CleaningTaskReportClient';
import { CleaningTaskReportListResponse } from 'app/modules/cleaning/interfaces/CleaningTaskReport.types';
import { CleaningTaskReportServiceRouteImageOptions } from 'app/modules/cleaning/CleaningTaskReportService';

export type IGetMachineDetailsRequestOptions = MachineClientDetailsOptions;
export type IGetMachineDetailsRequestAction = Action<typeof MachineDetailsActions.GET_MACHINE_DETAILS_REQUEST> & {
  payload: MachineClientDetailsOptions;
};

export type IGetMachineDetailsSuccessOptions = MachineDetails;
export type IGetMachineDetailsSuccessAction = Action<typeof MachineDetailsActions.GET_MACHINE_DETAILS_SUCCESS> & {
  payload: MachineDetails;
};

export type IGetMachineDetailsPictureSuccessOptions = MachineDetailsVariantDatum;
export type IGetMachineDetailsPictureSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_DETAILS_PICTURE_SUCCESS
> & {
  payload: MachineDetailsVariantDatum;
};

export type IGetMachineDetailsTelemetriesSuccessOptions = MachineDetailsTelemetriesDatum;
export type IGetMachineDetailsTelemetriesSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS
> & {
  payload: MachineDetailsTelemetriesDatum;
};

export type IGetMachineDetailsLatestCtrSuccessOptions = MachineDetailsLatestCtrDatum;
export type IGetMachineDetailsLatestCtrSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS
> & {
  payload: MachineDetailsLatestCtrDatum;
};

export type IGetMachineDetailsErrorAction = IErrorAction<typeof MachineDetailsActions.GET_MACHINE_DETAILS_ERROR>;

export type IGetMachineOperatingTimeRequestOptions = MachineClientOperatingTimeOptions;
export type IGetMachineOperatingTimeRequestAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_OPERATING_TIME_REQUEST
> & {
  payload: MachineClientOperatingTimeOptions;
};

export type IGetMachineOperatingTimeSuccessOptions = GetMachineOperatingTimeForPeriod;
export type IGetMachineOperatingTimeSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_OPERATING_TIME_SUCCESS
> & {
  payload: GetMachineOperatingTimeForPeriod;
};

export type IGetMachineOperatingTimeErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_MACHINE_OPERATING_TIME_ERROR
>;

export interface IGetMachineRemindersRequestOptions extends ReminderServiceListOptions {
  append: boolean;
}
export type IGetMachineRemindersRequestAction = Action<typeof MachineDetailsActions.GET_MACHINE_REMINDERS_REQUEST> & {
  payload: IGetMachineRemindersRequestOptions;
};

export interface IGetMachineRemindersSuccessOptions extends RemindersListResponse {
  append: boolean;
}
export type IGetMachineRemindersSuccessAction = Action<typeof MachineDetailsActions.GET_MACHINE_REMINDERS_SUCCESS> & {
  payload: IGetMachineRemindersSuccessOptions;
};

export type IGetMachineRemindersErrorAction = IErrorAction<typeof MachineDetailsActions.GET_MACHINE_REMINDERS_ERROR>;

export interface IGetMachineNotesRequestOptions extends NoteServiceListOptions {
  append: boolean;
}
export type IGetMachineNotesRequestAction = Action<typeof MachineDetailsActions.GET_MACHINE_NOTES_REQUEST> & {
  payload: IGetMachineNotesRequestOptions;
};

export interface IGetMachineNotesSuccessOptions extends NotesListResponse {
  append: boolean;
}
export type IGetMachineNotesSuccessAction = Action<typeof MachineDetailsActions.GET_MACHINE_NOTES_SUCCESS> & {
  payload: IGetMachineNotesSuccessOptions;
};

export type IGetMachineNotesErrorAction = IErrorAction<typeof MachineDetailsActions.GET_MACHINE_NOTES_ERROR>;

export type IUpdateMachineRequestOptions = MachineClientUpdateOptions;
export type IUpdateMachineRequestAction = Action<typeof MachineDetailsActions.UPDATE_MACHINE_REQUEST> & {
  payload: MachineClientUpdateOptions;
};

export type IUpdateMachineSuccessOptions = Optional<MachineUpdateResponse>;
export type IUpdateMachineSuccessAction = Action<typeof MachineDetailsActions.UPDATE_MACHINE_SUCCESS> & {
  payload: Optional<MachineUpdateResponse>;
};

export type IUpdateMachineErrorAction = IErrorAction<typeof MachineDetailsActions.UPDATE_MACHINE_ERROR>;

export type IGetMachineNotificationListRequestOptions = NotificationClientListOptions;

export type IGetMachineNotificationListRequestAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST
> & {
  payload: NotificationClientListOptions;
};

export type IGetMachineNotificationListSuccessOptions = NotificationList;
export type IGetMachineNotificationListSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_SUCCESS
> & {
  payload: NotificationList;
};

export type IGetMachineNotificationListErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_ERROR
>;

export type IChangeMachineNotificationListTablePage = Action<
  typeof MachineDetailsActions.CHANGE_NOTIFICATIONS_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type IGetTaskCompletionHistoryRequestAction = Action<
  typeof MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_REQUEST
> & {
  payload: RobotServiceGetRobotDashboardTasksCompletionOptions;
};

export type IGetTaskCompletionHistorySuccessAction = Action<
  typeof MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_SUCCESS
> & {
  payload: {
    data: RobotDashboardTasksCompletionStatisticResponse;
  };
};

export type IGetTaskCompletionHistoryErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_ERROR
>;

export type ISetActiveNotificationsSortField = Action<
  typeof MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_FIELD
> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveNotificationsSortOrder = Action<
  typeof MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_ORDER
> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IGetMachineAttachmentsRequestOptions = MachineClientAttachmentsListOptions;
export type IGetMachineAttachmentsRequestAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST
> & {
  payload: MachineClientAttachmentsListOptions;
};

export type IGetMachineAttachmentsSuccessOptions = MachineAttachmentsListResponse;
export type IGetMachineAttachmentsSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_SUCCESS
> & {
  payload: MachineAttachmentsListResponse;
};
export type IGetMachineAttachmentsErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_ERROR
>;

export type IMachineAttachmentsIsLoadingAction = Action<typeof MachineDetailsActions.MACHINE_ATTACHMENTS_IS_LOADING>;

export type IChangeMachineAttachmentsListTablePage = Action<
  typeof MachineDetailsActions.CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type ISetMachineAttachmentsActiveSortOrder = Action<
  typeof MachineDetailsActions.SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER
> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IDeleteMachineAttachmentRequestOptions = MachineAttachmentClientDeleteOptions & { pageSize: number };
export type IDeleteMachineAttachmentRequestAction = Action<
  typeof MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_REQUEST
> & {
  payload: IDeleteMachineAttachmentRequestOptions;
};

export type IDeleteMachineAttachmentSuccessOptions = Optional<MachineAttachmentDeleteResponse>;
export type IDeleteMachineAttachmentSuccessAction = Action<
  typeof MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_SUCCESS
> & {
  payload: Optional<MachineAttachmentDeleteResponse>;
};

export type IDeleteMachineAttachmentErrorAction = IErrorAction<
  typeof MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_ERROR
>;

export interface IAddMachineAttachmentRequestOptions {
  machineId: string;
  file: UploadFile;
  pageSize: number;
}
export type IAddMachineAttachmentRequestAction = Action<typeof MachineDetailsActions.ADD_MACHINE_ATTACHMENT_REQUEST> & {
  payload: IAddMachineAttachmentRequestOptions;
};

export type IAddMachineAttachmentSuccessOptions = Optional<void>;
export type IAddMachineAttachmentSuccessAction = Action<typeof MachineDetailsActions.ADD_MACHINE_ATTACHMENT_SUCCESS> & {
  payload: Optional<void>;
};

export type IAddMachineAttachmentErrorAction = IErrorAction<typeof MachineDetailsActions.ADD_MACHINE_ATTACHMENT_ERROR>;

export interface IDownloadMachineAttachmentRequestOptions {
  machineId: string;
  attachmentId: string;
}
export type IDownloadMachineAttachmentRequestAction = Action<
  typeof MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST
> & {
  payload: IDownloadMachineAttachmentRequestOptions;
};

export type IDownloadMachineAttachmentSuccessOptions = Optional<MachineAttachmentDownloadResponse>;
export type IDownloadMachineAttachmentSuccessAction = Action<
  typeof MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS
> & {
  payload: Optional<MachineAttachmentDownloadResponse>;
};

export type IDownloadMachineAttachmentErrorAction = IErrorAction<
  typeof MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_ERROR
>;

export enum IPollMachineAttachmentsOperation {
  ADD = 'add',
  DELETE = 'delete',
}
export interface IPollMachineAttachmentsRequestOptions {
  machineId: string;
  operation: IPollMachineAttachmentsOperation;
  attachment?: {
    name: string;
    mimeType: string;
    size: number;
    time: number;
  };
  pageSize: number;
}

export type IPollMachineAttachmentsRequestAction = Action<
  typeof MachineDetailsActions.POLL_MACHINE_ATTACHMENTS_REQUEST
> & {
  payload: IPollMachineAttachmentsRequestOptions;
};

export interface IGetCTRListRobotRequestOptions extends CleaningTaskReportClientListOptions {
  append: boolean;
}
export type IGetCTRListRobotRequestAction = Action<typeof MachineDetailsActions.GET_CTR_LIST_ROBOT_REQUEST> & {
  payload: IGetCTRListRobotRequestOptions;
};

export interface IGetCTRListRobotSuccessOptions extends CleaningTaskReportListResponse {
  append: boolean;
}
export type IGetCTRListRobotSuccessAction = Action<typeof MachineDetailsActions.GET_CTR_LIST_ROBOT_SUCCESS> & {
  payload: IGetCTRListRobotSuccessOptions;
};

export type IGetCTRListRobotErrorAction = IErrorAction<typeof MachineDetailsActions.GET_CTR_LIST_ROBOT_ERROR>;

export type IResetRobotCTRListState = Action<typeof MachineDetailsActions.RESET_ROBOT_CTR_LIST_STATE>;

export type IGetCleaningReportRobotRouteImageRequestOptions = CleaningTaskReportServiceRouteImageOptions;
export type IGetCleaningReportRobotRouteImageRequestAction = Action<
  typeof MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST
> & {
  payload: IGetCleaningReportRobotRouteImageRequestOptions;
};

export interface IGetCleaningReportRobotRouteImageSuccessOptions {
  url: string;
  imageObjectUrl: string;
}
export type IGetCleaningReportRobotRouteImageSuccessAction = Action<
  typeof MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS
> & {
  payload: IGetCleaningReportRobotRouteImageSuccessOptions;
};

export type IGetCleaningReportRobotRouteImageErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR
>;

export type IResetCleaningReportRobotRouteImageAction = Action<
  typeof MachineDetailsActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE
>;

export type IGetConsumptionsSummaryRequestOptions = MachineClientGetConsumptionsSummaryOptions;
export type IGetConsumptionsSummaryRequestAction = Action<
  typeof MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_REQUEST
> & {
  payload: MachineClientGetConsumptionsSummaryOptions;
};

export type IGetConsumptionsSummarySuccessOptions = MachineRobotCleaningConsumptionSummaryResponse;
export type IGetConsumptionsSummarySuccessAction = Action<
  typeof MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_SUCCESS
> & {
  payload: MachineRobotCleaningConsumptionSummaryResponse;
};

export type IGetConsumptionsSummaryErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_ERROR
>;

export interface MachineDetailsRobotRealTimePropertyChangedOptions {
  updatedData: MachineUpdateConnectionStatus | MachineUpdateRobotStatus | MachineUpdateTelemetry;
}
export type MachineDetailsRobotRealTimePropertyChangedAction = Action<
  typeof MachineDetailsActions.MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED
> & {
  payload: {
    updatedData: MachineUpdateConnectionStatus | MachineUpdateRobotStatus | MachineUpdateTelemetry;
  };
};

export type IGetMachinePlannedAndActualTimeRequestOptions = MachineClientListPlannedAndActualForPeriodOptions;
export type IGetMachinePlannedAndActualTimeRequestAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_REQUEST
> & {
  payload: MachineClientListPlannedAndActualForPeriodOptions;
};

export type IGetMachinePlannedAndActualTimeSuccessOptions = GetMachinePlannedAndActualTimeForPeriod;
export type IGetMachinePlannedAndActualTimeSuccessAction = Action<
  typeof MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_SUCCESS
> & {
  payload: GetMachinePlannedAndActualTimeForPeriod;
};

export type IGetMachinePlannedAndActualTimeErrorAction = IErrorAction<
  typeof MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_ERROR
>;

export class MachineDetailsActions {
  public static GET_MACHINE_DETAILS_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_REQUEST';
  public static GET_MACHINE_DETAILS_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_SUCCESS';
  public static GET_MACHINE_DETAILS_PICTURE_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_PICTURE_SUCCESS';
  public static GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS';
  public static GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS';
  public static GET_MACHINE_DETAILS_ERROR = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_ERROR';

  public static GET_MACHINE_OPERATING_TIME_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_OPERATING_TIME_REQUEST';
  public static GET_MACHINE_OPERATING_TIME_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_OPERATING_TIME_SUCCESS';
  public static GET_MACHINE_OPERATING_TIME_ERROR = 'MACHINE_DETAILS::GET_MACHINE_OPERATING_TIME_ERROR';

  public static GET_MACHINE_REMINDERS_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_REMINDERS_REQUEST';
  public static GET_MACHINE_REMINDERS_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_REMINDERS_SUCCESS';
  public static GET_MACHINE_REMINDERS_ERROR = 'MACHINE_DETAILS::GET_MACHINE_REMINDERS_ERROR';

  public static GET_MACHINE_NOTES_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_NOTES_REQUEST';
  public static GET_MACHINE_NOTES_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_NOTES_SUCCESS';
  public static GET_MACHINE_NOTES_ERROR = 'MACHINE_DETAILS::GET_MACHINE_NOTES_ERROR';

  public static UPDATE_MACHINE_REQUEST = 'MACHINE_DETAILS::UPDATE_MACHINE_REQUEST';
  public static UPDATE_MACHINE_SUCCESS = 'MACHINE_DETAILS::UPDATE_MACHINE_SUCCESS';
  public static UPDATE_MACHINE_ERROR = 'MACHINE_DETAILS::UPDATE_MACHINE_ERROR';

  public static GET_MACHINE_NOTIFICATION_LIST_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_NOTIFICATION_LIST_REQUEST';
  public static GET_MACHINE_NOTIFICATION_LIST_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_NOTIFICATION_LIST_SUCCESS';
  public static GET_MACHINE_NOTIFICATION_LIST_ERROR = 'MACHINE_DETAILS::GET_MACHINE_NOTIFICATION_LIST_ERROR';
  public static CHANGE_NOTIFICATIONS_TABLE_PAGE = 'MACHINE_DETAILS::CHANGE_NOTIFICATIONS_TABLE_PAGE';

  public static GET_TASK_COMPLETION_HISTORY_REQUEST = 'MACHINE_DETAILS::GET_TASK_COMPLETION_HISTORY_REQUEST';
  public static GET_TASK_COMPLETION_HISTORY_SUCCESS = 'MACHINE_DETAILS::GET_TASK_COMPLETION_HISTORY_SUCCESS';
  public static GET_TASK_COMPLETION_HISTORY_ERROR = 'MACHINE_DETAILS::GET_TASK_COMPLETION_HISTORY_ERROR';

  public static SET_ACTIVE_NOTIFICATIONS_SORT_FIELD = 'MACHINE_DETAILS::SET_ACTIVE_NOTIFICATIONS_SORT_FIELD';
  public static SET_ACTIVE_NOTIFICATIONS_SORT_ORDER = 'MACHINE_DETAILS::SET_ACTIVE_NOTIFICATIONS_SORT_ORDER';

  public static GET_MACHINE_ATTACHMENTS_LIST_REQUEST = 'MACHINE_DETAILS::GET_MACHINE_ATTACHMENTS_LIST_REQUEST';
  public static GET_MACHINE_ATTACHMENTS_LIST_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_ATTACHMENTS_LIST_SUCCESS';
  public static GET_MACHINE_ATTACHMENTS_LIST_ERROR = 'MACHINE_DETAILS::GET_MACHINE_ATTACHMENTS_LIST_ERROR';
  public static MACHINE_ATTACHMENTS_IS_LOADING = 'MACHINE_DETAILS::MACHINE_ATTACHMENTS_IS_LOADING';
  public static CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE = 'MACHINE_DETAILS::CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE';
  public static SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER = 'MACHINE_DETAILS::SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER';

  public static DELETE_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS::DELETE_MACHINE_ATTACHMENT_REQUEST';
  public static DELETE_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS::DELETE_MACHINE_ATTACHMENT_SUCCESS';
  public static DELETE_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS::DELETE_MACHINE_ATTACHMENT_ERROR';

  public static ADD_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS::ADD_MACHINE_ATTACHMENT_REQUEST';
  public static ADD_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS::ADD_MACHINE_ATTACHMENT_SUCCESS';
  public static ADD_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS::ADD_MACHINE_ATTACHMENT_ERROR';

  public static DOWNLOAD_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS::DOWNLOAD_MACHINE_ATTACHMENT_REQUEST';
  public static DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS::DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS';
  public static DOWNLOAD_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS::DOWNLOAD_MACHINE_ATTACHMENT_ERROR';

  public static POLL_MACHINE_ATTACHMENTS_REQUEST = 'MACHINE_DETAILS::POLL_MACHINE_ATTACHMENTS_REQUEST';

  public static RESET_STATE = 'MACHINE_DETAILS::RESET_STATE';

  public static GET_CTR_LIST_ROBOT_REQUEST = 'MACHINE_DETAILS::GET_CTR_LIST_ROBOT_REQUEST';
  public static GET_CTR_LIST_ROBOT_SUCCESS = 'MACHINE_DETAILS::GET_CTR_LIST_ROBOT_SUCCESS';
  public static GET_CTR_LIST_ROBOT_ERROR = 'MACHINE_DETAILS::GET_CTR_LIST_ROBOT_ERROR';
  public static RESET_ROBOT_CTR_LIST_STATE = 'MACHINE_DETAILS::RESET_ROBOT_CTR_LIST_STATE';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST =
    'MACHINE_DETAILS::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS =
    'MACHINE_DETAILS::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR =
    'MACHINE_DETAILS::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR';

  public static RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE = 'MACHINE_DETAILS::RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE';

  public static GET_CONSUMPTIONS_SUMMARY_REQUEST = 'MACHINE_DETAILS::GET_CONSUMPTIONS_SUMMARY_REQUEST';
  public static GET_CONSUMPTIONS_SUMMARY_SUCCESS = 'MACHINE_DETAILS::GET_CONSUMPTIONS_SUMMARY_SUCCESS';
  public static GET_CONSUMPTIONS_SUMMARY_ERROR = 'MACHINE_DETAILS::GET_CONSUMPTIONS_SUMMARY_ERROR';

  public static MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED = 'MACHINE_DETAILS::ROBOT_REAL_TIME_PROPERTY_CHANGED';

  public static GET_MACHINE_PLANNED_AND_ACTUAL_TIME_REQUEST =
    'MACHINE_DETAILS::GET_MACHINE_PLANNED_AND_ACTUAL_TIME_REQUEST';

  public static GET_MACHINE_PLANNED_AND_ACTUAL_TIME_SUCCESS =
    'MACHINE_DETAILS::GET_MACHINE_PLANNED_AND_ACTUAL_TIME_SUCCESS';

  public static GET_MACHINE_PLANNED_AND_ACTUAL_TIME_ERROR =
    'MACHINE_DETAILS::GET_MACHINE_PLANNED_AND_ACTUAL_TIME_ERROR';

  public static getMachineDetailsRequest({ id }: IGetMachineDetailsRequestOptions): IGetMachineDetailsRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_REQUEST,
      payload: { id },
    };
  }

  public static getMachineDetailsSuccess(data: IGetMachineDetailsSuccessOptions): IGetMachineDetailsSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsPicturesSuccess(
    data: IGetMachineDetailsPictureSuccessOptions
  ): IGetMachineDetailsPictureSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_PICTURE_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsTelemetriesSuccess(
    data: IGetMachineDetailsTelemetriesSuccessOptions
  ): IGetMachineDetailsTelemetriesSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsLatestCtrSuccess(
    data: IGetMachineDetailsLatestCtrSuccessOptions
  ): IGetMachineDetailsLatestCtrSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsError({ error }: IActionCreatorErrorOptions): IGetMachineDetailsErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_DETAILS_ERROR,
      error,
    };
  }

  public static getMachineOperatingTimeRequest({
    id,
    timezone,
    period,
  }: IGetMachineOperatingTimeRequestOptions): IGetMachineOperatingTimeRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_OPERATING_TIME_REQUEST,
      payload: { id, timezone, period },
    };
  }

  public static getMachineOperatingTimeSuccess(
    data: IGetMachineOperatingTimeSuccessOptions
  ): IGetMachineOperatingTimeSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_OPERATING_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getMachineOperatingTimeError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineOperatingTimeErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_OPERATING_TIME_ERROR,
      error,
    };
  }

  public static getMachineRemindersRequest({
    machineId,
    paginationOptions,
    append,
  }: IGetMachineRemindersRequestOptions): IGetMachineRemindersRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_REMINDERS_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        append,
      },
    };
  }

  public static getMachineRemindersSuccess(
    data: IGetMachineRemindersSuccessOptions
  ): IGetMachineRemindersSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_REMINDERS_SUCCESS,
      payload: data,
    };
  }

  public static getMachineRemindersError({ error }: IActionCreatorErrorOptions): IGetMachineRemindersErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_REMINDERS_ERROR,
      error,
    };
  }

  public static getMachineNotesRequest({
    machineId,
    paginationOptions,
    append,
  }: IGetMachineNotesRequestOptions): IGetMachineNotesRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTES_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        append,
      },
    };
  }

  public static getMachineNotesSuccess(data: IGetMachineNotesSuccessOptions): IGetMachineNotesSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineNotesError({ error }: IActionCreatorErrorOptions): IGetMachineNotesErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTES_ERROR,
      error,
    };
  }

  public static updateMachineRequest({ id, name }: IUpdateMachineRequestOptions): IUpdateMachineRequestAction {
    return {
      type: MachineDetailsActions.UPDATE_MACHINE_REQUEST,
      payload: {
        id,
        name,
      },
    };
  }

  public static updateMachineSuccess(data: IUpdateMachineSuccessOptions): IUpdateMachineSuccessAction {
    return {
      type: MachineDetailsActions.UPDATE_MACHINE_SUCCESS,
      payload: data,
    };
  }

  public static updateMachineError({ error }: IActionCreatorErrorOptions): IUpdateMachineErrorAction {
    return {
      type: MachineDetailsActions.UPDATE_MACHINE_ERROR,
      error,
    };
  }

  public static getMachineNotificationListRequest({
    paginationOptions,
    sortOptions,
    filter,
    lang,
  }: IGetMachineNotificationListRequestOptions): IGetMachineNotificationListRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST,
      payload: {
        paginationOptions,
        sortOptions,
        filter,
        lang,
      },
    };
  }

  public static getMachineNotificationListSuccess(
    data: IGetMachineNotificationListSuccessOptions
  ): IGetMachineNotificationListSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineNotificationListError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineNotificationListErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_ERROR,
      error,
    };
  }

  public static changeMachineNotificationListTablePage(data: {
    page: number;
  }): IChangeMachineNotificationListTablePage {
    return {
      type: MachineDetailsActions.CHANGE_NOTIFICATIONS_TABLE_PAGE,
      payload: data,
    };
  }

  public static setMachineNotificationListActiveSortField(field: Optional<string>): ISetActiveNotificationsSortField {
    return {
      type: MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setMachineNotificationListActiveSortOrder(
    order: Optional<SortOrders>
  ): ISetActiveNotificationsSortOrder {
    return {
      type: MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getMachineAttachmentsRequest({
    machineId,
    paginationOptions,
    sortOptions,
  }: IGetMachineAttachmentsRequestOptions): IGetMachineAttachmentsRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        sortOptions,
      },
    };
  }

  public static getMachineAttachmentsSuccess(
    data: IGetMachineAttachmentsSuccessOptions
  ): IGetMachineAttachmentsSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineAttachmentsError({ error }: IActionCreatorErrorOptions): IGetMachineAttachmentsErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_ERROR,
      error,
    };
  }

  public static machineAttachmentsIsLoading(): IMachineAttachmentsIsLoadingAction {
    return {
      type: MachineDetailsActions.MACHINE_ATTACHMENTS_IS_LOADING,
    };
  }

  public static changeMachineAttachmentsListTablePage(data: { page: number }): IChangeMachineAttachmentsListTablePage {
    return {
      type: MachineDetailsActions.CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE,
      payload: data,
    };
  }

  public static setMachineAttachmentsActiveSortOrder(
    order: Optional<SortOrders>
  ): ISetMachineAttachmentsActiveSortOrder {
    return {
      type: MachineDetailsActions.SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static deleteMachineAttachmentRequest({
    machineId,
    attachmentId,
    pageSize,
  }: IDeleteMachineAttachmentRequestOptions): IDeleteMachineAttachmentRequestAction {
    return {
      type: MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        attachmentId,
        pageSize,
      },
    };
  }

  public static deleteMachineAttachmentSuccess(
    data: IDeleteMachineAttachmentSuccessOptions
  ): IDeleteMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static deleteMachineAttachmentError({
    error,
  }: IActionCreatorErrorOptions): IDeleteMachineAttachmentErrorAction {
    return {
      type: MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static addMachineAttachmentRequest({
    machineId,
    file,
    pageSize,
  }: IAddMachineAttachmentRequestOptions): IAddMachineAttachmentRequestAction {
    return {
      type: MachineDetailsActions.ADD_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        file,
        pageSize,
      },
    };
  }

  public static addMachineAttachmentSuccess(
    data: IAddMachineAttachmentSuccessOptions
  ): IAddMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsActions.ADD_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static addMachineAttachmentError({ error }: IActionCreatorErrorOptions): IAddMachineAttachmentErrorAction {
    return {
      type: MachineDetailsActions.ADD_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static downloadMachineAttachmentRequest({
    machineId,
    attachmentId,
  }: IDownloadMachineAttachmentRequestOptions): IDownloadMachineAttachmentRequestAction {
    return {
      type: MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        attachmentId,
      },
    };
  }

  public static downloadMachineAttachmentSuccess(
    data: IDownloadMachineAttachmentSuccessOptions
  ): IDownloadMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static downloadMachineAttachmentError({
    error,
  }: IActionCreatorErrorOptions): IDownloadMachineAttachmentErrorAction {
    return {
      type: MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static pollMachineAttachmentsRequest({
    machineId,
    operation,
    attachment,
    pageSize,
  }: IPollMachineAttachmentsRequestOptions): IPollMachineAttachmentsRequestAction {
    return {
      type: MachineDetailsActions.POLL_MACHINE_ATTACHMENTS_REQUEST,
      payload: {
        machineId,
        operation,
        attachment,
        pageSize,
      },
    };
  }

  public static resetState(): Action<typeof MachineDetailsActions.RESET_STATE> {
    return {
      type: MachineDetailsActions.RESET_STATE,
    };
  }

  public static getTaskCompletionHistoryRequest({
    filter,
  }: RobotServiceGetRobotDashboardTasksCompletionOptions): IGetTaskCompletionHistoryRequestAction {
    return {
      type: MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getTaskCompletionHistorySuccess(
    data: RobotDashboardTasksCompletionStatisticResponse
  ): IGetTaskCompletionHistorySuccessAction {
    return {
      type: MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_SUCCESS,
      payload: {
        data,
      },
    };
  }

  public static getTaskCompletionHistoryError({
    error,
  }: IActionCreatorErrorOptions): IGetTaskCompletionHistoryErrorAction {
    return {
      type: MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_ERROR,
      error,
    };
  }

  public static getCTRListRobotRequest({
    paginationOptions,
    filter,
    sortOptions,
    append,
  }: IGetCTRListRobotRequestOptions): IGetCTRListRobotRequestAction {
    return {
      type: MachineDetailsActions.GET_CTR_LIST_ROBOT_REQUEST,
      payload: { paginationOptions, filter, sortOptions, append },
    };
  }

  public static getCTRListRobotSuccess(data: IGetCTRListRobotSuccessOptions): IGetCTRListRobotSuccessAction {
    return {
      type: MachineDetailsActions.GET_CTR_LIST_ROBOT_SUCCESS,
      payload: data,
    };
  }

  public static getCTRListRobotError({ error }: IActionCreatorErrorOptions): IGetCTRListRobotErrorAction {
    return {
      type: MachineDetailsActions.GET_CTR_LIST_ROBOT_ERROR,
      error,
    };
  }

  public static resetRobotCTRListState(): IResetRobotCTRListState {
    return {
      type: MachineDetailsActions.RESET_ROBOT_CTR_LIST_STATE,
    };
  }

  public static getCleaningReportRobotRouteImageRequest({
    machineId,
    finishedAt,
  }: IGetCleaningReportRobotRouteImageRequestOptions): IGetCleaningReportRobotRouteImageRequestAction {
    return {
      type: MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST,
      payload: {
        machineId,
        finishedAt,
      },
    };
  }

  public static getCleaningReportRobotRouteImageSuccess({
    url,
    imageObjectUrl,
  }: IGetCleaningReportRobotRouteImageSuccessOptions): IGetCleaningReportRobotRouteImageSuccessAction {
    return {
      type: MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS,
      payload: {
        url,
        imageObjectUrl,
      },
    };
  }

  public static getCleaningReportRobotRouteImageError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportRobotRouteImageErrorAction {
    return {
      type: MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR,
      error,
    };
  }

  public static resetCleaningReportRobotRouteImage(): IResetCleaningReportRobotRouteImageAction {
    return {
      type: MachineDetailsActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE,
    };
  }

  public static getConsumptionsSummaryRequest({
    filter,
  }: IGetConsumptionsSummaryRequestOptions): IGetConsumptionsSummaryRequestAction {
    return {
      type: MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getConsumptionsSummarySuccess(
    data: IGetConsumptionsSummarySuccessOptions
  ): IGetConsumptionsSummarySuccessAction {
    return {
      type: MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_SUCCESS,
      payload: data,
    };
  }

  public static getConsumptionsSummaryError({ error }: IActionCreatorErrorOptions): IGetConsumptionsSummaryErrorAction {
    return {
      type: MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_ERROR,
      error,
    };
  }

  public static robotByStatusRealTimePropertyChanged({
    updatedData,
  }: MachineDetailsRobotRealTimePropertyChangedOptions): MachineDetailsRobotRealTimePropertyChangedAction {
    return {
      type: MachineDetailsActions.MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED,
      payload: {
        updatedData,
      },
    };
  }

  public static getMachinePlannedAndActualTimeRequest({
    filter,
    timezone,
  }: IGetMachinePlannedAndActualTimeRequestOptions): IGetMachinePlannedAndActualTimeRequestAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_REQUEST,
      payload: { filter, timezone },
    };
  }

  public static getMachinePlannedAndActualTimeSuccess(
    data: IGetMachinePlannedAndActualTimeSuccessOptions
  ): IGetMachinePlannedAndActualTimeSuccessAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getMachinePlannedAndActualTimeError({
    error,
  }: IActionCreatorErrorOptions): IGetMachinePlannedAndActualTimeErrorAction {
    return {
      type: MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_ERROR,
      error,
    };
  }
}
