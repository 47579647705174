import { CallEffect, ForkEffect, GetContextEffect, JoinEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, fork, join, SagaGenerator, retry, select } from 'typed-redux-saga';
import { Optional } from '../../../../../lib/types/Optional';
import { RemindersListResponse } from '../../../reminder/interfaces/Reminder.types';
import {
  MachineDetails,
  MachineDetailsVariantDatum,
  MachineUpdateResponse,
  GetMachineOperatingTimeForPeriod,
  MachineDetailsLatestCtrDatum,
  MachineDetailsTelemetriesDatum,
  GetMachinePlannedAndActualTimeForPeriod,
} from '../../interfaces/Machine.types';
import { MachineModalsActions } from '../../modals/state/machineModalsSlice';
import {
  IEditMachineNameModalIsLoadingAction,
  IEditMachineNameModalIsNotLoadingAction,
  IHideEditMachineNameModalAction,
} from '../../modals/state/machineModalsActions.types';
import { RobotDashboardTasksCompletionStatisticResponse } from '../../interfaces/Robot.types';
import {
  MachineAttachmentDeleteResponse,
  MachineAttachmentDownloadResponse,
  MachineAttachmentGetUploadLinkResponse,
  MachineAttachmentsListResponse,
} from '../../interfaces/MachineAttachment.types';
import { MACHINE_ATTACHMENT_LIST_DEFAULT_SORT_FIELD } from '../../utils/constants';
import { FormDataBuilder } from '../../utils/FormDataBuilder';
import {
  IGetMachineDetailsErrorAction,
  IGetMachineDetailsPictureSuccessAction,
  IGetMachineDetailsRequestAction,
  IGetMachineDetailsSuccessAction,
  IGetMachineOperatingTimeErrorAction,
  IGetMachineOperatingTimeRequestAction,
  IGetMachineOperatingTimeSuccessAction,
  IGetMachineNotificationListErrorAction,
  IGetMachineNotificationListRequestAction,
  IGetMachineNotificationListSuccessAction,
  IUpdateMachineErrorAction,
  IUpdateMachineRequestAction,
  IUpdateMachineSuccessAction,
  MachineDetailsActions,
  IGetMachineRemindersRequestAction,
  IGetMachineRemindersErrorAction,
  IGetMachineRemindersSuccessAction,
  IGetMachineNotesErrorAction,
  IGetMachineNotesRequestAction,
  IGetMachineNotesSuccessAction,
  IGetMachineAttachmentsSuccessAction,
  IGetMachineAttachmentsErrorAction,
  IGetMachineAttachmentsRequestAction,
  IDeleteMachineAttachmentRequestAction,
  IDeleteMachineAttachmentSuccessAction,
  IDeleteMachineAttachmentErrorAction,
  IAddMachineAttachmentErrorAction,
  IAddMachineAttachmentRequestAction,
  IAddMachineAttachmentSuccessAction,
  IDownloadMachineAttachmentErrorAction,
  IDownloadMachineAttachmentRequestAction,
  IDownloadMachineAttachmentSuccessAction,
  IPollMachineAttachmentsRequestAction,
  IMachineAttachmentsIsLoadingAction,
  IPollMachineAttachmentsOperation,
  IGetTaskCompletionHistoryRequestAction,
  IGetTaskCompletionHistorySuccessAction,
  IGetTaskCompletionHistoryErrorAction,
  IGetCTRListRobotRequestAction,
  IGetCTRListRobotErrorAction,
  IGetCTRListRobotSuccessAction,
  IGetConsumptionsSummaryErrorAction,
  IGetConsumptionsSummarySuccessAction,
  IGetConsumptionsSummaryRequestAction,
  IGetMachineDetailsLatestCtrSuccessAction,
  IGetMachineDetailsTelemetriesSuccessAction,
  IGetMachinePlannedAndActualTimeRequestAction,
  IGetMachinePlannedAndActualTimeSuccessAction,
  IGetMachinePlannedAndActualTimeErrorAction,
} from './machineDetailsActions';
import { selectAttachmentsTotalCount } from './machineDetailsSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { NotificationList } from 'app/modules/notification/interfaces/Notification.types';
import { NotesListResponse } from 'app/modules/note/interfaces/Note.types';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { downloadLink } from 'app/utils/download-link/downloadLink';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';
import { CleaningTaskReportListResponse } from 'app/modules/cleaning/interfaces/CleaningTaskReport.types';

export function* getMachineDetailsSaga(
  action: IGetMachineDetailsRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineDetails>
  | ForkEffect<MachineDetailsVariantDatum>
  | ForkEffect<MachineDetailsTelemetriesDatum>
  | ForkEffect<MachineDetailsLatestCtrDatum>
  | JoinEffect
  | PutEffect<IGetMachineDetailsSuccessAction>
  | PutEffect<IGetMachineDetailsPictureSuccessAction>
  | PutEffect<IGetMachineDetailsTelemetriesSuccessAction>
  | PutEffect<IGetMachineDetailsLatestCtrSuccessAction>
  | PutEffect<IGetMachineDetailsErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machineResponseTask = yield* fork(machineService.get, action.payload);
    const machinePictureResponseTask = yield* fork(machineService.getPicture, action.payload);
    const machineTelemetriesResponseTask = yield* fork(machineService.getTelemetries, action.payload);
    const machineLatestCtrResponseTask = yield* fork(machineService.getLatestCtr, action.payload);

    const machineResponse = yield* join(machineResponseTask);
    yield* put(MachineDetailsActions.getMachineDetailsSuccess(machineResponse));

    const machinePictureResponse = yield* join(machinePictureResponseTask);
    yield* put(MachineDetailsActions.getMachineDetailsPicturesSuccess(machinePictureResponse));

    const machineTelemetriesResponse = yield* join(machineTelemetriesResponseTask);
    yield* put(MachineDetailsActions.getMachineDetailsTelemetriesSuccess(machineTelemetriesResponse));

    const machineLatestCtrResponse = yield* join(machineLatestCtrResponseTask);
    yield* put(MachineDetailsActions.getMachineDetailsLatestCtrSuccess(machineLatestCtrResponse));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineDetailsError({
        error,
      })
    );
  }
}

export function* getMachineOperatingTimeSaga(
  action: IGetMachineOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<GetMachineOperatingTimeForPeriod>
  | PutEffect<IGetMachineOperatingTimeSuccessAction>
  | PutEffect<IGetMachineOperatingTimeErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.getMachineOperatingTime, action.payload);
    yield* put(MachineDetailsActions.getMachineOperatingTimeSuccess(response));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineOperatingTimeError({
        error,
      })
    );
  }
}

export function* getMachineRemindersSaga(
  action: IGetMachineRemindersRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RemindersListResponse>
  | PutEffect<IGetMachineRemindersSuccessAction>
  | PutEffect<IGetMachineRemindersErrorAction>,
  void,
  IDependencies
> {
  const { reminderService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { append, ...requestOptions } = action.payload;
    const response = yield* call(reminderService.list, requestOptions);

    const successActionPayload = { ...response, append };

    yield* put(MachineDetailsActions.getMachineRemindersSuccess(successActionPayload));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineRemindersError({
        error,
      })
    );
  }
}

export function* getMachineNotesSaga(
  action: IGetMachineNotesRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<NotesListResponse>
  | PutEffect<IGetMachineNotesSuccessAction>
  | PutEffect<IGetMachineNotesErrorAction>,
  void,
  IDependencies
> {
  const { noteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { append, ...requestOptions } = action.payload;
    const response = yield* call(noteService.list, requestOptions);

    const successActionPayload = { ...response, append };

    yield* put(MachineDetailsActions.getMachineNotesSuccess(successActionPayload));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineNotesError({
        error,
      })
    );
  }
}

export function* updateMachineSaga(
  action: IUpdateMachineRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineUpdateResponse>>
  | PutEffect<IUpdateMachineSuccessAction>
  | PutEffect<IUpdateMachineErrorAction>
  | PutEffect<IEditMachineNameModalIsLoadingAction>
  | PutEffect<IEditMachineNameModalIsNotLoadingAction>
  | PutEffect<IHideEditMachineNameModalAction>,
  void,
  IDependencies
> {
  const { id, name } = action.payload;
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(MachineModalsActions.editMachineNameModalIsLoading());
    const response = yield* call(machineService.update, { id, name });

    yield* put(MachineDetailsActions.updateMachineSuccess(response));

    yield* put(MachineModalsActions.editMachineNameModalIsNotLoading());
    yield* put(MachineModalsActions.hideEditMachineNameModal());
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.updateMachineError({
        error,
      })
    );

    yield* put(MachineModalsActions.editMachineNameModalIsNotLoading());
    yield* put(MachineModalsActions.hideEditMachineNameModal());
  }
}

export function* getMachineNotificationListSaga(
  action: IGetMachineNotificationListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<NotificationList>
  | PutEffect<IGetMachineNotificationListSuccessAction>
  | PutEffect<IGetMachineNotificationListErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.listShort, action.payload);
    yield* put(MachineDetailsActions.getMachineNotificationListSuccess(response));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineNotificationListError({
        error,
      })
    );
  }
}

export function* getMachineAttachmentsListSaga(
  action: IGetMachineAttachmentsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineAttachmentsListResponse>
  | PutEffect<IGetMachineAttachmentsSuccessAction>
  | PutEffect<IGetMachineAttachmentsErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listAttachments, action.payload);

    yield* put(MachineDetailsActions.getMachineAttachmentsSuccess(response));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachineAttachmentsError({
        error,
      })
    );
  }
}

export function* deleteMachineAttachmentSaga(
  action: IDeleteMachineAttachmentRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineAttachmentDeleteResponse>>
  | PutEffect<IPollMachineAttachmentsRequestAction>
  | PutEffect<IDeleteMachineAttachmentSuccessAction>
  | PutEffect<IDeleteMachineAttachmentErrorAction>,
  void,
  IDependencies
> {
  const { machineId, attachmentId, pageSize } = action.payload;
  const { machineAttachmentService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(MachineModalsActions.deleteMachineAttachmentModalIsLoading());
    const response: Optional<MachineAttachmentDeleteResponse> = yield* call(machineAttachmentService.delete, {
      machineId,
      attachmentId,
    });

    yield* put(MachineDetailsActions.deleteMachineAttachmentSuccess(response));
    yield* put(
      MachineDetailsActions.pollMachineAttachmentsRequest({
        machineId,
        operation: IPollMachineAttachmentsOperation.DELETE,
        pageSize,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.deleteMachineAttachmentError({
        error,
      })
    );
  } finally {
    yield* put(MachineModalsActions.deleteMachineAttachmentModalIsNotLoading());
    yield* put(MachineModalsActions.hideDeleteMachineAttachmentModal());
  }
}

export function* addMachineAttachmentSaga(
  action: IAddMachineAttachmentRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | CallEffect<Optional<MachineAttachmentGetUploadLinkResponse>>
  | PutEffect<IPollMachineAttachmentsRequestAction>
  | PutEffect<IAddMachineAttachmentSuccessAction>
  | PutEffect<IAddMachineAttachmentErrorAction>,
  void,
  IDependencies
> {
  const { machineId, file, pageSize } = action.payload;
  const { machineAttachmentService } = yield* getContext<IDependencies>('dependencies');
  const currentTime = Date.now();
  try {
    yield* put(MachineModalsActions.addMachineAttachmentModalIsLoading());
    const response = yield* call(machineAttachmentService.getUploadLink, {
      machineId,
      attachmentName: file.name,
      mimeType: file.type || file.originFileObj?.type || '',
    });
    if (!response.attachmentUploadLinkGet.data) return;

    const { fields, url } = response.attachmentUploadLinkGet.data;

    const formData = FormDataBuilder.buildUploadFormFromPresignedUrl({ file: file.originFileObj as File, fields });
    yield* call(machineAttachmentService.uploadByUrl, {
      url,
      formData,
    });

    yield* put(MachineDetailsActions.addMachineAttachmentSuccess());
    yield* put(
      MachineDetailsActions.pollMachineAttachmentsRequest({
        machineId,
        operation: IPollMachineAttachmentsOperation.ADD,
        attachment: {
          name: file.name,
          mimeType: file.type || file.originFileObj?.type || '',
          size: file.size || 0,
          time: currentTime,
        },
        pageSize,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.addMachineAttachmentError({
        error,
      })
    );
  } finally {
    yield* put(MachineModalsActions.addMachineAttachmentModalIsNotLoading());
    yield* put(MachineModalsActions.hideAddMachineAttachmentModal());
  }
}

export function* downloadMachineAttachmentSaga(
  action: IDownloadMachineAttachmentRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineAttachmentDownloadResponse> | void>
  | PutEffect<IDownloadMachineAttachmentSuccessAction>
  | PutEffect<IDownloadMachineAttachmentErrorAction>,
  void,
  IDependencies
> {
  const { machineId, attachmentId } = action.payload;
  const { machineAttachmentService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* call(toastService.info, {
      message: t('machineAttachmentList.subHeader') as string,
      description: t('machineAttachmentList.downloadDesc') as string,
    });
    const response = yield* call(machineAttachmentService.download, { machineId, attachmentId });

    const downloadUrl = response?.attachmentDownloadLinkGet.data;
    if (!downloadUrl) return;

    downloadLink({ fileUrl: downloadUrl });

    yield* put(MachineDetailsActions.downloadMachineAttachmentSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.downloadMachineAttachmentError({
        error,
      })
    );
  }
}

export function* pollMachineAttachmentsListSaga(
  action: IPollMachineAttachmentsRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | SagaGenerator<MachineAttachmentsListResponse, CallEffect<MachineAttachmentsListResponse>>
  | PutEffect<IGetMachineAttachmentsSuccessAction>
  | PutEffect<IMachineAttachmentsIsLoadingAction>
  | PutEffect<IGetMachineAttachmentsErrorAction>,
  void,
  any
> {
  const { machineId, attachment, operation, pageSize } = action.payload;
  const { machineService } = yield* getContext<IDependencies>('dependencies');
  const previousTotalCount: Optional<number> = yield* select(selectAttachmentsTotalCount);
  const getMachineAttachments = async (): Promise<MachineAttachmentsListResponse> => {
    let predicate: () => boolean;
    const response: MachineAttachmentsListResponse = await machineService.listAttachments({
      machineId,
      paginationOptions: {
        limit: pageSize,
      },
      sortOptions: {
        field: MACHINE_ATTACHMENT_LIST_DEFAULT_SORT_FIELD,
        order: SortOrders.Desc,
      },
    });

    const newMachineAttachment = response?.machine.data.attachments.data[0];
    const newTotalCount = response.machine.data.attachments.metadata?.totalCount || 0;

    switch (operation) {
      case IPollMachineAttachmentsOperation.ADD:
        predicate = (): boolean =>
          newMachineAttachment.name === attachment?.name &&
          newMachineAttachment.size === attachment?.size &&
          newMachineAttachment.mimeType === attachment?.mimeType &&
          +new Date(newMachineAttachment.createdAt) > attachment?.time;
        break;
      case IPollMachineAttachmentsOperation.DELETE:
        predicate = (): boolean => newTotalCount !== previousTotalCount;
        break;

      default:
        throw new Error(`Invalid operation: ${operation}`);
    }

    if (!predicate()) {
      throw new Error('Machine Attachments not updated');
    }

    return response;
  };

  try {
    yield* put(MachineDetailsActions.machineAttachmentsIsLoading());
    yield* put(MachineDetailsActions.setMachineAttachmentsActiveSortOrder(SortOrders.Desc));
    const response: MachineAttachmentsListResponse = yield retry(
      POLL_MAX_RETRIES,
      POLL_INTERVAL,
      getMachineAttachments
    );
    yield* put(MachineDetailsActions.getMachineAttachmentsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.getMachineAttachmentsError({
        error,
      })
    );
  }
}

export function* getTasksCompletionHistorySaga(
  action: IGetTaskCompletionHistoryRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardTasksCompletionStatisticResponse>
  | PutEffect<IGetTaskCompletionHistorySuccessAction>
  | PutEffect<IGetTaskCompletionHistoryErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getRobotDashboardTasksCompletion, action.payload);
    yield* put(MachineDetailsActions.getTaskCompletionHistorySuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineDetailsActions.getTaskCompletionHistoryError({
        error,
      })
    );
  }
}

export function* getRobotCTRListSaga(
  action: IGetCTRListRobotRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CleaningTaskReportListResponse>
  | PutEffect<IGetCTRListRobotSuccessAction>
  | PutEffect<IGetCTRListRobotErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { append, ...requestOptions } = action.payload;
    const response = yield* call(cleaningTaskReportService.list, requestOptions);

    const successActionPayload = { ...response, append };
    yield* put(MachineDetailsActions.getCTRListRobotSuccess(successActionPayload));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsActions.getCTRListRobotError({
        error,
      })
    );
  }
}

export function* getRobotCleaningConsumptionSummarySaga(
  action: IGetConsumptionsSummaryRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<IGetConsumptionsSummarySuccessAction>
  | PutEffect<IGetConsumptionsSummaryErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.getMachineRobotConsumptionSummary, action.payload);
    yield* put(MachineDetailsActions.getConsumptionsSummarySuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineDetailsActions.getConsumptionsSummaryError({
        error,
      })
    );
  }
}

export function* getMachinePlannedAndActualTimeSaga(
  action: IGetMachinePlannedAndActualTimeRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<GetMachinePlannedAndActualTimeForPeriod>
  | PutEffect<IGetMachinePlannedAndActualTimeSuccessAction>
  | PutEffect<IGetMachinePlannedAndActualTimeErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listPlannedAndActualForPeriod, action.payload);

    yield* put(MachineDetailsActions.getMachinePlannedAndActualTimeSuccess(response));
  } catch (error) {
    yield* put(
      MachineDetailsActions.getMachinePlannedAndActualTimeError({
        error,
      })
    );
  }
}

export function* machineDetailsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_DETAILS_REQUEST, getMachineDetailsSaga);
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_OPERATING_TIME_REQUEST, getMachineOperatingTimeSaga);
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_REMINDERS_REQUEST, getMachineRemindersSaga);
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_NOTES_REQUEST, getMachineNotesSaga);
  yield* takeLatest(MachineDetailsActions.UPDATE_MACHINE_REQUEST, updateMachineSaga);
  yield* takeLatest(MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_REQUEST, getTasksCompletionHistorySaga);
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST, getMachineNotificationListSaga);
  yield* takeLatest(MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST, getMachineAttachmentsListSaga);
  yield* takeLatest(MachineDetailsActions.DELETE_MACHINE_ATTACHMENT_REQUEST, deleteMachineAttachmentSaga);
  yield* takeLatest(MachineDetailsActions.ADD_MACHINE_ATTACHMENT_REQUEST, addMachineAttachmentSaga);
  yield* takeLatest(MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST, downloadMachineAttachmentSaga);
  yield* takeLatest(MachineDetailsActions.POLL_MACHINE_ATTACHMENTS_REQUEST, pollMachineAttachmentsListSaga);
  yield* takeLatest(MachineDetailsActions.GET_CTR_LIST_ROBOT_REQUEST, getRobotCTRListSaga);
  yield* takeLatest(MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_REQUEST, getRobotCleaningConsumptionSummarySaga);
  yield* takeLatest(
    MachineDetailsActions.GET_MACHINE_PLANNED_AND_ACTUAL_TIME_REQUEST,
    getMachinePlannedAndActualTimeSaga
  );
}
