import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { StyledMachineRobotCleaningDataChart } from './MachineRobotCleaningDataChart.styles';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineDetailsRobotCleaningDataTabs } from 'app/modules/machine-inventory/machine-details/components/MachineDetailsRobotCleaningData/MachineDetailsRobotCleaningData';
import { MachineDetailsRobotCleanedAreaChart } from 'app/modules/machine-inventory/machine-details-panel/components/MachineDetailsRobotPanel/MachineDetailsRobotCleaningData/MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotCleanedAreaChart/MachineDetailsRobotCleanedAreaChart';
import { MachineDetailsRobotCleanedHoursChart } from 'app/modules/machine-inventory/machine-details-panel/components/MachineDetailsRobotPanel/MachineDetailsRobotCleaningData/MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotCleanedHoursChart/MachineDetailsRobotCleanedHoursChart';
import { MachineDetailsRobotConsumablesChart } from 'app/modules/machine-inventory/machine-details-panel/components/MachineDetailsRobotPanel/MachineDetailsRobotCleaningData/MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotConsumablesChart/MachineDetailsRobotConsumablesChart';
import { MachineDetailsRobotDistanceDrivenChart } from 'app/modules/machine-inventory/machine-details-panel/components/MachineDetailsRobotPanel/MachineDetailsRobotCleaningData/MachineDetailsRobotsCleaningDataChart/MachineDetailsRobotDistanceDrivenChart/MachineDetailsRobotDistanceDrivenChart';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

interface MachineRobotCleaningDataChartProps {
  robot: Machine;
}

export const MachineRobotCleaningDataChart = ({ robot }: MachineRobotCleaningDataChartProps): JSX.Element => {
  const { t } = useTranslation();

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isRobotDetailsEnabled = features.ROBOTICS_DETAILS_PAGE;

  const tabs = [
    {
      label: t('machineDetails.robotCleaningData.tabs.cleanedArea'),
      key: MachineDetailsRobotCleaningDataTabs.CLEANED_AREA,
      children: <MachineDetailsRobotCleanedAreaChart robot={robot} />,
    },
    {
      label: t('machineDetails.robotCleaningData.tabs.cleanedHours'),
      key: MachineDetailsRobotCleaningDataTabs.CLEANED_HOURS,
      children: <MachineDetailsRobotCleanedHoursChart robot={robot} />,
    },
  ];

  if (isRobotDetailsEnabled && RobotUtils.getRobotType(robot?.type?.name) === 'B50') {
    tabs.push(
      {
        label: t('machineDetails.robotCleaningData.tabs.distanceDriven'),
        key: MachineDetailsRobotCleaningDataTabs.DISTANCE_DRIVEN,
        children: <MachineDetailsRobotDistanceDrivenChart robot={robot} />,
      },
      {
        label: t('machineDetails.robotCleaningData.tabs.consumables'),
        key: MachineDetailsRobotCleaningDataTabs.CONSUMABLES,
        children: <MachineDetailsRobotConsumablesChart robot={robot} />,
      }
    );
  }

  return (
    <StyledMachineRobotCleaningDataChart className="machine-robot-cleaning-data">
      <h3 tabIndex={0} className="machine-robot-cleaning-data__sub-header">
        {t('machineDetails.generalInfo.robotCleaningData')}
      </h3>
      <div className="machine-robot-cleaning-data__bar">
        <Tabs destroyInactiveTabPane={true} items={tabs} className="machine-robot-cleaning-data__tabs" />
      </div>
    </StyledMachineRobotCleaningDataChart>
  );
};
