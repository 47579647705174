import { createSlice } from '@reduxjs/toolkit';
import {
  CachingStorageGetImageErrorAction,
  CachingStorageGetImageRequestAction,
  CachingStorageGetImageSuccessAction,
  CachingStorageSetImageRequestAction,
} from './cachingStorageActions.types';

interface ImageStorage {
  foundImage: {
    key: string;
    machineId: string;
    finishedAt: string;
    imageObjectUrl?: string;
    isLoading: boolean;
  };
  listImage: Record<string, string>;
}

export interface ICachingStorageState {
  imageStorage: ImageStorage;
}

const initialState: ICachingStorageState = {
  imageStorage: {
    foundImage: {
      key: '',
      machineId: '',
      finishedAt: '',
      isLoading: false,
    },
    listImage: {},
  },
};

export const cachingStorageSlice = createSlice({
  name: 'cachingStorage',
  initialState,
  reducers: {
    getImageRequest: (state, action: CachingStorageGetImageRequestAction) => {
      const { machineId, finishedAt } = action.payload;
      const key = `${machineId}-${finishedAt}`;

      state.imageStorage.foundImage.machineId = machineId;
      state.imageStorage.foundImage.finishedAt = finishedAt;
      state.imageStorage.foundImage.key = key;
      state.imageStorage.foundImage.isLoading = true;
      // change imageObjectUrl to undefined to prevent showing the previous image
      // if the new image is not loaded or failed to load
      state.imageStorage.foundImage.imageObjectUrl = undefined;

      return state;
    },
    getImageSuccess: (state, action: CachingStorageGetImageSuccessAction) => {
      state.imageStorage.listImage[state.imageStorage.foundImage.key] = action.payload.imageObjectUrl;
      state.imageStorage.foundImage.imageObjectUrl = action.payload.imageObjectUrl;
      state.imageStorage.foundImage.isLoading = false;
      return state;
    },
    getImageError: (state, _action: CachingStorageGetImageErrorAction) => {
      state.imageStorage.foundImage.isLoading = false;
      return state;
    },
    setImageRequest: (state, action: CachingStorageSetImageRequestAction) => {
      state.imageStorage.foundImage.imageObjectUrl = action.payload;
      return state;
    },
    resetImageUrl: state => {
      state.imageStorage.foundImage.imageObjectUrl = undefined;
      return state;
    },
  },
});

export const cachingStorageActions = cachingStorageSlice.actions;
export const cachingStorageReducer = cachingStorageSlice.reducer;
