import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledRoutineDetailsDrawer = styled(Drawer)`
  .event-list-drawer__header__routine-name {
    ${(props): string => props.theme.fontStyles.redesign.heading3};
    text-transform: uppercase;
  }

  .event-list-drawer__header__machine-site {
    ${(props): string => props.theme.fontStyles.redesign.lg.normal};
  }
`;
