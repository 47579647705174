import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { cachingStorageActions } from './cachingStorageSlice';
import {
  CachingStorageGetImageErrorAction,
  CachingStorageGetImageRequestAction,
  CachingStorageGetImageSuccessAction,
} from './cachingStorageActions.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';

export function* getCachingStorageImageSaga(
  action: CachingStorageGetImageRequestAction
): Generator<
  | GetContextEffect
  | CallEffect
  | PutEffect<CachingStorageGetImageSuccessAction>
  | PutEffect<CachingStorageGetImageErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const {
      cleaningTaskReportRobotRouteImage: { data: robotRouteImageUrl },
    } = yield* call(cleaningTaskReportService.getRobotRouteImageUrl, action.payload);

    const imageObjectUrl = yield* call(cleaningTaskReportService.getRobotRouteImageData, {
      robotRouteImageUrl,
    });

    yield* put(
      cachingStorageActions.getImageSuccess({
        imageUrl: robotRouteImageUrl,
        imageObjectUrl,
      })
    );
  } catch (error) {
    console.error(error);
    yield* put(
      cachingStorageActions.getImageError({
        error,
      })
    );
  }
}

export function* cachingStorageSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(cachingStorageActions.getImageRequest, getCachingStorageImageSaga);
}
