import styled from 'styled-components';

export const StyledChevronBar = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;

  .button--size-s {
    padding: 0;
  }

  .chevron-bar__arrow-left {
    svg {
      transform: rotate(180deg);
    }
  }

  .chevron-bar__arrow-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  .chevron-bar__title {
    margin: auto;
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-size: ${(props): string => props.theme.fontSizes.p0};
  }
`;
