import React, { useEffect, useMemo, useState } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import cloneDeep from 'lodash-es/cloneDeep';
import chunk from 'lodash-es/chunk';
import { DATE_FORMAT_PATTERN } from '../../../../../../lib/utils/date-handling/DateTime.types';
import { OperatingHoursChartData, OperatingHoursDatum } from '../../../../cleaning/utils/OperatingHoursChartUtils';
import { MachineOperatingHoursChartGlobalStyles } from './MachineOperatingHoursChart.global.styles';
import { StyledMachineOperatingHoursChart } from './MachineOperatingHoursChart.styles';
import { MachineOperatingHoursChartHeader } from './MachineOperatingHoursChartHeader/MachineOperatingHoursChartHeader';
import { MachineOperatingHoursChartTooltip } from './MachineOperatingHoursChartTooltip/MachineOperatingHoursChartTooltip';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export const NUM_CHART_COLUMNS = 10;

export interface MachineOperatingHoursChartProps {
  data: OperatingHoursChartData[];
  chartIndex: number;
}

export const MachineOperatingHoursChart = ({ data, chartIndex }: MachineOperatingHoursChartProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [chartData, setChartData] = useState(data);

  const actualHrsData: OperatingHoursDatum[][] = chunk(data[0].data, NUM_CHART_COLUMNS);
  const plannedHrsData: OperatingHoursDatum[][] = chunk(data[1].data, NUM_CHART_COLUMNS);

  // Calculate chart width
  const numChartCol = chartData[0]?.data?.length || chartData[1]?.data?.length || NUM_CHART_COLUMNS;
  const widthChart = `${numChartCol <= 10 ? numChartCol * 10 : 100}%`;

  useEffect((): void => {
    // Get chart data base on chart index
    const chartDataClone = cloneDeep(chartData);
    chartDataClone[0].data = actualHrsData[chartIndex];
    chartDataClone[1].data = plannedHrsData[chartIndex];

    setChartData(chartDataClone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartIndex, actualHrsData.length, plannedHrsData.length]);

  const primaryAxis = useMemo<AxisOptions<OperatingHoursDatum>>(
    () => ({
      getValue: (datum): string =>
        DateTime.formatDateByLocale(i18n.language, datum.primary.toISOString(), DATE_FORMAT_PATTERN.DATE_DAY_AND_MONTH),
      showGrid: false,
    }),
    [i18n.language]
  );

  const secondaryAxes = useMemo<AxisOptions<OperatingHoursDatum>[]>(
    () => [
      {
        getValue: (datum): number => datum.secondary,
        min: 0,
        formatters: {
          scale: (value: number): string =>
            value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '',
          cursor: (value: number): string =>
            `${value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '0'} ${t(
              'operatingHoursComparisonChart.hUnit'
            )}`,
        },
      },
    ],
    [t]
  );

  const inValidChartData = !chartData[0]?.data?.length || !chartData[1]?.data?.length;
  if (inValidChartData) return <></>;

  return (
    <StyledMachineOperatingHoursChart className="machine-operating-hours-chart">
      <MachineOperatingHoursChartGlobalStyles />
      <div className="machine-operating-hours-chart__body">
        <MachineOperatingHoursChartHeader />
        <p className="machine-operating-hours-chart__hours-unit">{t('machineDetails.operatingHoursChart.hrsUnit')}</p>
        <div className="machine-operating-hours-chart__chart">
          <div className="machine-operating-hours-chart__bar-stacked-chart">
            <div className="machine-operating-hours-chart__bar-stacked-chart-wrapper" style={{ width: widthChart }}>
              <Chart
                options={{
                  data: chartData,
                  primaryAxis,
                  secondaryAxes,
                  defaultColors: [theme.colors.secondary, theme.colors.secondary100],
                  tooltip: {
                    render: (props): React.ReactNode => MachineOperatingHoursChartTooltip(t, i18n, theme, props),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledMachineOperatingHoursChart>
  );
};
