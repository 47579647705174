import {
  CleaningTaskCleanedAreaByDay,
  CleaningTaskCleanedHrsByDay,
  CleaningTaskConsumablesByDay,
  CleaningTaskDistanceDrivenByDay,
  CleaningTaskReport,
  ConsumablesSummary,
  RobotCleaningConsumption,
  RobotCleaningRoutesObject,
  RobotDashboardKpIsData,
  RobotTaskCompletionStatisticData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export const selectAvailableRoutes = (state: IState): Optional<RobotCleaningRoutesObject>[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.availableFilter
    ?.robotCleaningRoutesList.data.validRoute || [];

export const selectUnavailableRoutes = (state: IState): Optional<RobotCleaningRoutesObject>[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.availableFilter
    ?.robotCleaningRoutesList.data.unavailableRoute || [];
export const selectActiveRoutesName = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.active?.routeName;
export const selectRoutesNameIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.isLoading;

export const selectPeriodStartDate = (state: IState): string | null =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.active.period.startDate;
export const selectPeriodEndDate = (state: IState): string | null =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsRobotFilter.active.period.endDate;

export const selectCleaningDataCleanedAreaIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedArea.isLoading;
export const selectCleaningDataPeriodCleanedArea = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaSummary.periodCleanedArea;
export const selectCleaningDataTotalCleanedArea = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaSummary.totalCleanedArea;
export const selectCleaningDataCleanedAreaByDay = (state: IState): CleaningTaskCleanedAreaByDay[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaList || [];

export const selectCleaningDataCleanedHrsIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedHrs.isLoading;
export const selectCleaningDataPeriodCleanedHrs = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsSummary.periodCleanedHrs;
export const selectCleaningDataTotalCleanedHrs = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsSummary.totalCleanedHrs;
export const selectCleaningDataCleanedHrsByDay = (state: IState): CleaningTaskCleanedHrsByDay[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsList || [];

export const selectCleaningDataConsumablesIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataConsumables.isLoading;
export const selectCleaningDataConsumablesPeriod = (state: IState): Optional<ConsumablesSummary> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataConsumables.data
    ?.consumablesSummary;
export const selectCleaningDataConsumablesByDay = (state: IState): CleaningTaskConsumablesByDay[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataConsumables.data
    ?.consumablesList || [];

export const selectCleaningDataDistanceDrivenIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataDistanceDriven.isLoading;
export const selectCleaningDataPeriodDistanceDriven = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenSummary.periodDistanceDriven;
export const selectCleaningDataTotalDistanceDriven = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenSummary.totalDistanceDriven;
export const selectCleaningDataDistanceDrivenByDay = (state: IState): CleaningTaskDistanceDrivenByDay[] =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenList || [];

export const selectTaskCompletionData = (state: IState): Optional<RobotTaskCompletionStatisticData> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].taskCompletionHistory.data;

export const selectTaskCompletionIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].taskCompletionHistory.isLoading;

export const selectCTRListRobotData = (state: IState): Optional<CleaningTaskReport[]> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.data;

export const selectCTRListRobotLatestData = (state: IState): Optional<CleaningTaskReport> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.latestData;

export const selectCTRListPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.page;

export const selectCTRListRobotPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.paginationTokens;

export const selectCTRListRobotTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.totalCount;

export const selectCTRListIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.isInitialLoad;

export const selectCTRListIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningTaskReports.isLoadingMore;

export const selectRobotCleaningConsumptionSummary = (state: IState): Optional<RobotCleaningConsumption> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningConsumptionSummary.data;

export const selectRobotCleaningConsumptionSummaryIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningConsumptionSummary.isLoading;

export const selectRobotCleaningKPIs = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningKPIs.data;

export const selectRobotCleaningKPIsIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].cleaningConsumptionSummary.isLoading;

export const selectRobotExportCtrPdfCurrentId = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsExportCtrPdf.id;

export const selectRobotExportCtrPdfDownloadUrl = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].machineDetailsExportCtrPdf.downloadUrl;

export const selectIsRedirectFromRobotDetailsPanel = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].redirectedEventpage.isRedirected;

export const selectRobotIdRedirected = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-panel-robot'].redirectedEventpage.redirectedRobotId;
