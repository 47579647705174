import { useTranslation } from 'react-i18next';
import { IMachineGeofenceStatusItemData } from '../../interfaces/Machine.types';
import { StyledMachineGeofenceStatusIndicatorTooltip } from './MachineGeofenceStatusIndicatorTooltip.styles';

interface MachineGeofenceStatusIndicatorTooltipProps {
  geofenceStatusItem: IMachineGeofenceStatusItemData;
}
export const MachineGeofenceStatusIndicatorTooltip = ({
  geofenceStatusItem,
}: MachineGeofenceStatusIndicatorTooltipProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineGeofenceStatusIndicatorTooltip className="machine-geofence-status-indicator-tooltip">
      {t(geofenceStatusItem.title)}
    </StyledMachineGeofenceStatusIndicatorTooltip>
  );
};
