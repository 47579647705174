import styled from 'styled-components';

export const StyledShortInfoRoutineDetails = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid ${(props): string => props.theme.colors.brightGrey};
  padding: 16px;
  gap: 12px;

  .ant-flex {
    height: 100%;
    gap: 12px;
    text-align: center;
    align-items: center;
  }

  .short-info__icon {
    width: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .short-info__unit {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    line-height: 20px;
    font-weight: 700;
    word-break: break-word;
    white-space: normal;
    margin: 0;
  }

  .odometer-wrapper {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    font-weight: 700;
    height: 20px;
    line-height: 20px;
    word-break: break-word;
    white-space: normal;
  }
`;
