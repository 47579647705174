import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { RobotKpiBar } from '../../../RobotKpiBar/RobotKpiBar';
import { RobotSummaryRow } from '../../../RobotSummaryRow/RobotSummaryRow';
import { RobotSummaryRowCV50 } from '../../../RobotCV50/RobotRowCV50/RobotSummaryRowCV50';
import * as robotDashboardSelectors from '../../../../../state/RobotDashboardSelectors';
import { StyledRobotRowList } from './RobotRowList.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SiteData } from 'app/modules/site-management/interfaces/Site.types';
import {
  MachineConnectionStatus,
  RobotDashboardKpIsData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { NoStyleButton } from 'lib/components/Button/NoStyleButton/NoStyleButton';

export const RobotRowGroup = ({
  robots,
  cleaningStatistic,
  isShowStatus,
  site,
}: {
  cleaningStatistic: Optional<RobotDashboardKpIsData>;
  isShowStatus: boolean;
  robots?: Machine[];
  site?: SiteData;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areSiteRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLoading);
  const areSiteRobotsWithCleaningStatisticLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithCleaningStatisticLoading
  );
  const areSiteRobotListPicturesLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsPicturesLoading
  );
  const areSiteRobotListTelemetriesLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsTelemetriesLoading
  );
  const areSiteRobotListLatestCtrLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLatestCtrLoading
  );

  const areUnassignedRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotUnassignedListLoading);
  const areUnassignedRobotListPicturesLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListPicturesLoading
  );
  const areUnassignedRobotListTelemetriesLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListTelemetriesLoading
  );
  const areUnassignedRobotListLatestCtrLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListLatestCtrLoading
  );
  const areUnassignedRobotKpisLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotDashboardUnassignedRobotsKPIsLoading
  );

  const handleOpenRobotDetailsPanel = (robotId: string): void => {
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: robotId }));
  };

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  useEffect(
    () => () => {
      dispatch(DrawersActions.hideMachineDetailsDrawer());
    },
    [dispatch]
  );

  if (site?.machines?.data.length === 0) return <></>;

  if (
    site?.machines?.data.length === 0 ||
    site?.machines?.data.filter(robot =>
      isHidingOfflineRobots
        ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
          robot.connectionStatus !== MachineConnectionStatus.Unknown
        : robot
    ).length === 0
  ) {
    return <></>;
  }

  const renderGroupRobotSummaryRowList = (
    robotsData: Optional<Machine[]>,
    areRobotListPicturesLoading: boolean,
    areRobotListTelemetriesLoading: boolean,
    areRobotListLatestCtrLoading: boolean
  ): JSX.Element[] =>
    RobotUtils.getRobotListSorted(robotsData)
      .filter(robot =>
        isHidingOfflineRobots
          ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
            robot.connectionStatus !== MachineConnectionStatus.Unknown
          : robot
      )
      .map(robot => (
        <NoStyleButton key={robot.id} onClick={(): void => handleOpenRobotDetailsPanel(robot.id)}>
          {RobotUtils.getRobotType(robot?.type?.name) === 'B50' ? (
            <RobotSummaryRow
              key={robot.id}
              robot={robot}
              isShowStatus={isShowStatus}
              areRobotListPicturesLoading={areRobotListPicturesLoading}
              areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
              areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
            />
          ) : (
            <RobotSummaryRowCV50
              key={robot.id}
              robot={robot}
              isShowStatus={isShowStatus}
              areRobotListPicturesLoading={areRobotListPicturesLoading}
              areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
              areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
            />
          )}
        </NoStyleButton>
      ));

  return (
    <div className="robot-row-group__wrapper">
      <h4 className="robot-row-group__site-name">{site?.name || t('robotDashboard.unassigned')}</h4>
      <Spin spinning={areUnassignedRobotKpisLoading || areSiteRobotsWithCleaningStatisticLoading}>
        <RobotKpiBar
          totalCleaningHours={cleaningStatistic?.totalCleaningHrs}
          totalCleanedArea={cleaningStatistic?.totalCleanedArea}
          totalDistance={cleaningStatistic?.distanceDriven}
          taskCoverage={cleaningStatistic?.taskCoverage}
          taskCompletionRate={cleaningStatistic?.tasksCompleted}
        />
      </Spin>

      {site ? (
        <Spin spinning={areSiteRobotsLoading}>
          <div className="robot-row-group__list-container">
            {site?.machines?.data.length > 0 &&
              renderGroupRobotSummaryRowList(
                site?.machines?.data,
                areSiteRobotListPicturesLoading,
                areSiteRobotListTelemetriesLoading,
                areSiteRobotListLatestCtrLoading
              )}
          </div>
        </Spin>
      ) : (
        <Spin spinning={areUnassignedRobotsLoading}>
          <div className="robot-row-group__list-container">
            {renderGroupRobotSummaryRowList(
              robots,
              areUnassignedRobotListPicturesLoading,
              areUnassignedRobotListTelemetriesLoading,
              areUnassignedRobotListLatestCtrLoading
            )}
          </div>
        </Spin>
      )}
    </div>
  );
};

export const RobotRowList = (): JSX.Element => {
  const sitesRobots = useSelector(robotDashboardSelectors.selectRobotListGroupedBySiteSiteList);
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData);
  const unassignedRobotsKPIs = useSelector(robotDashboardSelectors.selectRobotDashboardUnassignedRobotsKPIsData);

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  const isRenderUnassignedRobotRowGroup = useMemo(
    () =>
      (unassignedRobots && unassignedRobots.length > 0) ||
      (unassignedRobots &&
        unassignedRobots.filter(robot =>
          isHidingOfflineRobots
            ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
              robot.connectionStatus !== MachineConnectionStatus.Unknown
            : robot
        ).length > 0),
    [unassignedRobots, isHidingOfflineRobots]
  );

  return (
    <StyledRobotRowList>
      {sitesRobots.map(site => (
        <RobotRowGroup key={site.id} cleaningStatistic={site.cleaningStatistic?.data} site={site} isShowStatus={true} />
      ))}
      {isRenderUnassignedRobotRowGroup && (
        // Group unassigned robots
        <RobotRowGroup cleaningStatistic={unassignedRobotsKPIs} robots={unassignedRobots || []} isShowStatus={true} />
      )}
    </StyledRobotRowList>
  );
};
