import { createSlice } from '@reduxjs/toolkit';
import {
  CleaningReportsExportGetFile,
  RoutineClientExportRoutinesRequest,
} from '../../interfaces/CleaningReport.types';
import {
  IExportRoutineErrorAction,
  IExportRoutineRequestAction,
  IExportRoutineSuccessAction,
  IGetRoutineCleaningTaskReportErrorAction,
  IGetRoutineCleaningTaskReportRequestAction,
  IGetRoutineCleaningTaskReportSuccessAction,
  IPollGetExportRoutinesFileErrorAction,
  IPollGetExportRoutinesFileRequestAction,
  IPollGetExportRoutinesFileSuccessAction,
} from './eventListActions.types';
import {
  ResponseCleaningTaskReportsData,
  ResponseRobotCleaningConsumptionSummary,
  ResponseRobotDashboardKpIsGet,
  ResponseRoutineData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface EventState {
  routineDetailsDrawer: {
    visible: boolean;
    executionId: string;
  };
  routineCleaningTaskReport: {
    isLoading: boolean;
    data: {
      cleaningTaskReports: ResponseCleaningTaskReportsData;
      robotConsumptionSummary: ResponseRobotCleaningConsumptionSummary;
      robotDashboardKPIs: ResponseRobotDashboardKpIsGet;
      routine: ResponseRoutineData;
    };
  };
  routineExportFile: {
    isLoading: boolean;
    downloadUrl: Optional<string>;
    id: Optional<string>;
  };
}

export const initialState: EventState = {
  routineDetailsDrawer: {
    visible: false,
    executionId: '',
  },
  routineCleaningTaskReport: {
    isLoading: false,
    data: {
      cleaningTaskReports: {
        data: [],
      },
      robotConsumptionSummary: {
        data: {
          battery: 0,
          water: 0,
          detergents: 0,
        },
      },
      robotDashboardKPIs: {
        data: {
          distanceDriven: 0,
          taskCoverage: 0,
          tasksCompleted: 0,
          totalCleanedArea: 0,
          totalCleaningHrs: 0,
        },
      },
      routine: {
        data: {
          currentRepeat: 0,
          executionId: '',
          numberOfRepeats: 0,
          routineName: '',
        },
      },
    },
  },
  routineExportFile: {
    isLoading: false,
    downloadUrl: null,
    id: null,
  },
};

const eventListSlice = createSlice({
  name: 'event-list',
  initialState,
  reducers: {
    showRoutineDetailsDrawer: state => {
      state.routineDetailsDrawer.visible = true;
      return state;
    },
    hideRoutineDetailsDrawer: state => {
      state.routineDetailsDrawer.visible = false;
      return state;
    },
    getRoutineCleaningTaskReportRequest: (state, _action: IGetRoutineCleaningTaskReportRequestAction) => {
      state.routineCleaningTaskReport.isLoading = true;
      return state;
    },
    getRoutineCleaningTaskReportSuccess: (state, action: IGetRoutineCleaningTaskReportSuccessAction) => {
      state.routineCleaningTaskReport.isLoading = false;
      state.routineCleaningTaskReport.data = {
        cleaningTaskReports: action.payload.routineCleaningTaskReport.data.cleaningTaskReports,
        robotConsumptionSummary: action.payload.routineCleaningTaskReport.data.robotConsumptionSummary,
        robotDashboardKPIs: action.payload.routineCleaningTaskReport.data.robotDashboardKPIs,
        routine: action.payload.routineCleaningTaskReport.data.routine,
      };
      return state;
    },
    getRoutineCleaningTaskReportError: (state, _: IGetRoutineCleaningTaskReportErrorAction) => {
      state.routineCleaningTaskReport.isLoading = false;
      return state;
    },

    pollGetRoutineExportRequest: (state, _: IPollGetExportRoutinesFileRequestAction) => {
      state.routineExportFile.isLoading = true;
      return state;
    },
    pollGetRoutineExportSuccess: (state, action: IPollGetExportRoutinesFileSuccessAction) => {
      const {
        cleaningReportsExportGetFile: { data },
      } = action.payload as unknown as CleaningReportsExportGetFile;
      state.routineExportFile.id = null;
      state.routineExportFile.downloadUrl = data.presignedUrl;
      state.routineExportFile.isLoading = false;
      return state;
    },
    pollGetRoutineExportError: (state, _: IPollGetExportRoutinesFileErrorAction) => {
      state.routineExportFile.isLoading = false;
      return state;
    },
    routineExportResetState: state => {
      state.routineExportFile = initialState.routineExportFile;
      return state;
    },
    routineExportRequest: (state, action: IExportRoutineRequestAction) => {
      state.routineExportFile.isLoading = true;
      state.routineExportFile.id = action.payload.executionId;
      return state;
    },
    routineExportSuccess: (state, action: IExportRoutineSuccessAction) => {
      const {
        routineReportExportRequest: { data },
      } = action.payload as unknown as RoutineClientExportRoutinesRequest;
      state.routineExportFile.isLoading = false;
      state.routineExportFile.id = data.requestId;

      return state;
    },
    routineExportError: (state, _: IExportRoutineErrorAction) => {
      state.routineExportFile.isLoading = false;
      return state;
    },
  },
});

export const eventListActions = eventListSlice.actions;
export const eventListReducer = eventListSlice.reducer;
