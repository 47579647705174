import { GraphQLErrors } from '@apollo/client/errors';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { inject, injectable } from 'tsyringe';
import i18next, { TFunction } from 'i18next';
import { RoutePaths } from '../../../../config/route-paths';
import { ToastService } from '../../toasts/ToastService';
import { AppSyncError } from 'app/cross-cutting-concerns/authentication/errors/AppSyncError';
import { AuthenticationService } from 'app/cross-cutting-concerns/authentication/AuthenticationService';
import { SiteDetailsValidationError } from 'app/modules/site-management/site-details/ValidationError';
import { BackendErrorMapping } from 'app/cross-cutting-concerns/translations/constants';

export const isErrorTokenHasExpired = (error: GraphQLError): boolean =>
  error.message === AppSyncError.TOKEN_HAS_EXPIRED;

export const isErrorValidAuthorizationHeaderNotProvided = (error: GraphQLError): boolean =>
  error.message === AppSyncError.VALID_AUTHORIZATION_HEADER_NOT_PROVIDED;

@injectable()
export class GraphQlErrorHandler {
  private readonly t: TFunction;
  private readonly operationsToIgnoreErrorsFrom: string[] = [
    'MachinePicture',
    'MachinesAvailableToBeAssignedPictures',
    'MachinesCoordinatesPictures',
    'MachinesPictures',
  ];

  constructor(
    private authenticationService: AuthenticationService,
    // Typescript is reporting the error "Attempted import error: 'BrowserHistory' is not exported from 'history'"
    // Set to any type as a workaround
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('History') private history: any,
    private toastService: ToastService
  ) {
    this.t = i18next.t;
  }

  public handle = (errorResponse: ErrorResponse): void => {
    const graphQlErrors: GraphQLErrors = errorResponse.graphQLErrors ?? [];

    if (graphQlErrors.some(isErrorTokenHasExpired)) {
      this.handleInvalidAccessToken();
      return;
    }

    if (graphQlErrors.some(isErrorValidAuthorizationHeaderNotProvided)) {
      this.handleInvalidAccessToken();
      return;
    }

    if (this.operationsToIgnoreErrorsFrom.includes(errorResponse.operation.operationName)) {
      return;
    }

    if (errorResponse.networkError) {
      this.toastService.error({
        message: this.t('toasts.networkError.title') as string,
        description: errorResponse.networkError.message,
      });
    }

    if (graphQlErrors.length) {
      this.handleError(graphQlErrors);
    }
  };

  private handleError(graphQlErrors: GraphQLErrors): void {
    graphQlErrors.forEach(graphQlError => {
      const isSiteDetailsValidationError =
        SiteDetailsValidationError[graphQlError.message as keyof typeof SiteDetailsValidationError];
      if (isSiteDetailsValidationError) return;

      this.toastService.error({
        message: this.t('toasts.error.title') as string,
        description:
          graphQlError.message in BackendErrorMapping && i18next.exists(BackendErrorMapping[graphQlError.message])
            ? (this.t(BackendErrorMapping[graphQlError.message]) as string)
            : graphQlError.message,
      });
    });
  }

  private handleInvalidAccessToken = async (): Promise<void> => {
    await this.authenticationService.signOut();
    this.history.push(RoutePaths.LOGIN);
  };
}
