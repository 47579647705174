import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectIsMachineDetailsDrawerVisible, selectMachineDetailsDrawerMachineId } from '../../state/drawersSelectors';
import { DrawersActions } from '../../state/drawersSlice';
import { MachineDetailsPanel } from 'app/modules/machine-inventory/machine-details-panel/components/MachineDetailsPanel/MachineDetailsPanel';
import { Drawer } from 'lib/components/Drawer/Drawer';
import { RoutePaths } from 'config/route-paths';

export const MachineDetailsDrawer = (): JSX.Element => {
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsMachineDetailsDrawerVisible);
  const machineId = useSelector(selectMachineDetailsDrawerMachineId);
  const location = useLocation();
  const navigate = useNavigate();

  if (!machineId) return <></>;

  return (
    <Drawer
      destroyOnClose
      className="machine-details-drawer"
      size="large"
      open={isVisible}
      mask={false}
      onClose={(): void => {
        dispatch(DrawersActions.hideMachineDetailsDrawer());
        if (location.pathname.startsWith(RoutePaths.MACHINES)) {
          navigate(RoutePaths.MACHINES);
        }
      }}
    >
      <MachineDetailsPanel id={machineId} />
    </Drawer>
  );
};
