import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import * as machineDetailsPanelRobotActionTypes from './machineDetailsPanelRobotActions.types';
import { Optional } from 'lib/types/Optional';
import { ALL_VALUE_SELECT_OPTION, DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import {
  CleaningTaskCleanedAreaByDayList,
  CleaningTaskCleanedHrsByDayList,
  CleaningTaskConsumablesByDayList,
  CleaningTaskDistanceDrivenByDayList,
  CleaningTaskReport,
  ResponseRobotCleaningRoutesList,
  RobotCleaningConsumption,
  RobotDashboardKpIsData,
  RobotDetailsReportsExportRequestOutput,
  RobotTaskCompletionStatisticData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

interface MachineDetailsRobotFilter {
  isLoading: boolean;
  availableFilter: {
    robotCleaningRoutesList: ResponseRobotCleaningRoutesList;
  };
  active: {
    routeName: Optional<string>;
    period: {
      startDate: string | null;
      endDate: string | null;
    };
  };
}

interface MachineDetailsRobotTaskCompletionHistory {
  isLoading: Optional<boolean>;
  data: Optional<RobotTaskCompletionStatisticData>;
}

interface MachineDetailsRobotCleaningKPIs {
  isLoading: Optional<boolean>;
  data: Optional<RobotDashboardKpIsData>;
}

interface MachineDetailsRobotCleaningConsumptionSummary {
  isLoading: Optional<boolean>;
  data: Optional<RobotCleaningConsumption>;
}

interface MachineDetailsRobotCleaningTaskReportList {
  data: Optional<CleaningTaskReport[]>;
  latestData: Optional<CleaningTaskReport>;
  isInitialLoad: Optional<boolean>;
  isLoadingMore: Optional<boolean>;
  totalCount: Optional<number>;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
}

interface MachineDetailsCleaningDataCleanedArea {
  isLoading: boolean;
  data: Optional<CleaningTaskCleanedAreaByDayList>;
}

interface MachineDetailsCleaningDataCleanedHrs {
  isLoading: boolean;
  data: Optional<CleaningTaskCleanedHrsByDayList>;
}

interface MachineDetailsCleaningDataConsumables {
  isLoading: boolean;
  data: Optional<CleaningTaskConsumablesByDayList>;
}

interface MachineDetailsCleaningDataDistanceDriven {
  isLoading: boolean;
  data: Optional<CleaningTaskDistanceDrivenByDayList>;
}

interface MachineDetailsRobotExportCtrPdf {
  requestExportData: Optional<RobotDetailsReportsExportRequestOutput>;
  isLoading: Optional<boolean>;
  downloadUrl: Optional<string>;
  id: Optional<string>;
}

interface MachineDetailsRobotRedirectEventPage {
  isRedirected: Optional<boolean>;
  redirectedRobotId: Optional<string>;
}

export interface MachineDetailsPanelRobotState {
  machineDetailsRobotFilter: MachineDetailsRobotFilter;
  taskCompletionHistory: MachineDetailsRobotTaskCompletionHistory;
  cleaningKPIs: MachineDetailsRobotCleaningKPIs;
  cleaningConsumptionSummary: MachineDetailsRobotCleaningConsumptionSummary;
  cleaningTaskReports: MachineDetailsRobotCleaningTaskReportList;
  machineDetailsCleaningDataCleanedArea: MachineDetailsCleaningDataCleanedArea;
  machineDetailsCleaningDataCleanedHrs: MachineDetailsCleaningDataCleanedHrs;
  machineDetailsCleaningDataConsumables: MachineDetailsCleaningDataConsumables;
  machineDetailsCleaningDataDistanceDriven: MachineDetailsCleaningDataDistanceDriven;
  machineDetailsExportCtrPdf: MachineDetailsRobotExportCtrPdf;
  redirectedEventpage: MachineDetailsRobotRedirectEventPage;
}

export const initialState: MachineDetailsPanelRobotState = {
  machineDetailsRobotFilter: {
    isLoading: false,
    availableFilter: {
      robotCleaningRoutesList: {
        data: {
          validRoute: [],
          unavailableRoute: [],
        },
      },
    },
    active: {
      routeName: '',
      period: {
        startDate: null,
        endDate: null,
      },
    },
  },
  taskCompletionHistory: {
    isLoading: null,
    data: null,
  },
  cleaningKPIs: {
    isLoading: null,
    data: null,
  },
  cleaningConsumptionSummary: {
    isLoading: null,
    data: null,
  },
  cleaningTaskReports: {
    data: null,
    latestData: null,
    isInitialLoad: null,
    isLoadingMore: null,
    totalCount: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
  },
  machineDetailsCleaningDataCleanedArea: {
    isLoading: false,
    data: {
      cleanedAreaList: [
        {
          cleanedArea: 0,
          finishedAt: '',
        },
      ],
      cleanedAreaSummary: {
        periodCleanedArea: 0,
        totalCleanedArea: 0,
      },
    },
  },
  machineDetailsCleaningDataCleanedHrs: {
    isLoading: false,
    data: {
      cleanedHrsList: [
        {
          cleanedHrs: 0,
          date: '',
        },
      ],
      cleanedHrsSummary: {
        periodCleanedHrs: 0,
        totalCleanedHrs: 0,
      },
    },
  },
  machineDetailsCleaningDataConsumables: {
    isLoading: false,
    data: {
      consumablesList: [
        {
          consumptions: {
            battery: 0,
            water: 0,
            detergents: 0,
          },
          finishedAt: '',
        },
      ],
      consumablesSummary: {
        periodBattery: 0,

        periodWater: 0,
        periodDetergents: 0,
      },
    },
  },
  machineDetailsCleaningDataDistanceDriven: {
    isLoading: false,
    data: {
      distanceDrivenList: [
        {
          distanceDriven: 0,
          finishedAt: '',
        },
      ],
      distanceDrivenSummary: {
        periodDistanceDriven: 0,
        totalDistanceDriven: 0,
      },
    },
  },
  machineDetailsExportCtrPdf: {
    requestExportData: null,
    isLoading: null,
    downloadUrl: null,
    id: null,
  },
  redirectedEventpage: {
    isRedirected: null,
    redirectedRobotId: null,
  },
};

const machineDetailsPanelRobotSlice = createSlice({
  name: 'machine-details-robot',
  initialState,
  reducers: {
    // robot task completion history
    machineDetailsRobotTaskCompletionHistoryRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotTaskCompletedHistoryRequestAction
    ) => {
      state.taskCompletionHistory.isLoading = true;
      return state;
    },
    machineDetailsRobotTaskCompletionHistorySuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotTaskCompletedHistorySuccessAction
    ) => {
      state.taskCompletionHistory.isLoading = false;
      state.taskCompletionHistory.data = action.payload?.robotTaskCompletionStatistic.data || null;
      return state;
    },
    machineDetailsRobotTaskCompletionHistoryError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotTaskCompletedHistoryErrorAction
    ) => {
      state.taskCompletionHistory.isLoading = false;
      return state;
    },

    // Robot Cleaning KPIs
    machineDetailsRobotCleaningKPIsRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningKPIsRequestAction
    ) => {
      state.cleaningKPIs.isLoading = true;
      return state;
    },
    machineDetailsRobotCleaningKPIsSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningKPIsSuccessAction
    ) => {
      const {
        robotDashboardKPIs: { data },
      } = action.payload;
      state.cleaningKPIs.isLoading = false;
      state.cleaningKPIs.data = data;
      return state;
    },
    machineDetailsRobotCleaningKPIsError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningKPIsErrorAction
    ) => {
      state.cleaningKPIs.isLoading = false;
      return state;
    },

    // Robot Cleaning consumption summary
    machineDetailsRobotCleaningConsumptionSummaryRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningConsumptionSummaryRequestAction
    ) => {
      state.cleaningConsumptionSummary.isLoading = true;
      return state;
    },
    machineDetailsRobotCleaningConsumptionSummarySuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningConsumptionSummarySuccessAction
    ) => {
      const {
        robotConsumptionSummary: { data },
      } = action.payload;
      state.cleaningConsumptionSummary.isLoading = false;
      state.cleaningConsumptionSummary.data = data;
      return state;
    },
    machineDetailsRobotCleaningConsumptionSummaryError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningConsumptionSummaryErrorAction
    ) => {
      state.cleaningConsumptionSummary.isLoading = false;
      return state;
    },

    // RoutesNameList
    machineDetailsRobotGetRoutesNameListRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListRoutesNameRequestAction
    ) => {
      state.machineDetailsRobotFilter.isLoading = true;
      return state;
    },
    machineDetailsRobotGetRoutesNameListSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListRoutesNameSuccessAction
    ) => {
      state.machineDetailsRobotFilter.isLoading = false;
      const { robotCleaningRoutesList } = action.payload || {};
      if (robotCleaningRoutesList) {
        state.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList = robotCleaningRoutesList;
      } else {
        state.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList =
          initialState.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList;
      }

      state.machineDetailsRobotFilter.active.routeName = ALL_VALUE_SELECT_OPTION;

      return state;
    },
    machineDetailsRobotGetRoutesNameListError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListRoutesNameErrorAction
    ) => {
      state.machineDetailsRobotFilter.isLoading = false;
      return state;
    },

    // MachineDetailsRobotFilter
    machineDetailsRobotFilterActiveRoutesName: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotFilterActiveRoutesNameAction
    ) => {
      state.machineDetailsRobotFilter.active.routeName = action.payload;
      return state;
    },
    machineDetailsRobotFilterActivePeriod: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotFilterActivePeriodAction
    ) => {
      state.machineDetailsRobotFilter.active.period = action.payload;
      return state;
    },
    machineDetailsRobotFilterResetState: state => {
      state.machineDetailsRobotFilter = initialState.machineDetailsRobotFilter;
      return state;
    },

    // Robot cleaning task report list
    machineDetailsRobotCleaningTaskReportsRequest: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningTaskReportRequestAction
    ) => {
      const { append } = action.payload;

      if (append) {
        state.cleaningTaskReports.isLoadingMore = true;
      } else {
        state.cleaningTaskReports.isInitialLoad = true;
      }
      return state;
    },
    machineDetailsRobotCleaningTaskReportsSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningTaskReportSuccessAction
    ) => {
      if (action.payload?.append) {
        state.cleaningTaskReports.isLoadingMore = false;
        state.cleaningTaskReports.page += 1;
        state.cleaningTaskReports.data = (state.cleaningTaskReports.data ?? []).concat(
          action.payload?.cleaningTaskReports.data
        );
      } else {
        state.cleaningTaskReports.isInitialLoad = false;
        state.cleaningTaskReports.page = 1;
        state.cleaningTaskReports.data = action.payload?.cleaningTaskReports.data;
      }

      state.cleaningTaskReports.latestData = state.cleaningTaskReports.data?.[0];

      state.cleaningTaskReports.totalCount = action.payload?.cleaningTaskReports.metadata?.totalCount;
      if (action.payload?.cleaningTaskReports.metadata?.paginationToken) {
        state.cleaningTaskReports.paginationTokens[state.cleaningTaskReports.page + 1] =
          action.payload?.cleaningTaskReports.metadata?.paginationToken;
      }

      return state;
    },
    machineDetailsRobotCleaningTaskReportsError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotCleaningTaskReportErrorAction
    ) => {
      state.cleaningTaskReports.isLoadingMore = false;
      state.cleaningTaskReports.isInitialLoad = false;
      return state;
    },
    machineDetailsRobotCleaningTaskReportsResetState: state => {
      state.cleaningTaskReports = initialState.cleaningTaskReports;
      return state;
    },

    // Cleaning data
    // Cleaned area
    machineDetailsRobotListCleaningDataCleanedAreaRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedAreaRequestAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedAreaSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = false;
      state.machineDetailsCleaningDataCleanedArea.data = action.payload?.cleaningTaskCleanedAreaByDayList?.data;

      return state;
    },
    machineDetailsRobotListCleaningDataCleanedAreaError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedAreaErrorAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = false;
      return state;
    },

    // Cleaned hours
    machineDetailsRobotListCleaningDataCleanedHrsRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedHrsRequestAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedHrsSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = false;

      state.machineDetailsCleaningDataCleanedHrs.data = action.payload?.cleaningTaskCleanedHrsByDayList?.data;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedHrsError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataCleanedHrsErrorAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = false;
      return state;
    },

    // Consumables
    machineDetailsRobotListCleaningDataConsumablesRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListConsumablesRequestAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataConsumablesSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListConsumablesSuccessAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = false;

      state.machineDetailsCleaningDataConsumables.data = action.payload?.cleaningTaskConsumablesByDayList?.data;
      return state;
    },
    machineDetailsRobotListCleaningDataConsumablesError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListConsumablesErrorAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = false;
      return state;
    },

    // Distance driven
    machineDetailsRobotListCleaningDataDistanceDrivenRequest: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataDistanceDrivenSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = false;

      state.machineDetailsCleaningDataDistanceDriven.data = action.payload?.cleaningTaskDistanceDrivenByDayList.data;
      return state;
    },
    machineDetailsRobotListCleaningDataDistanceDrivenError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotListCleaningDataDistanceDrivenErrorAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = false;
      return state;
    },
    machineDetailsRobotExportCtrPdfRequest: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotExportCtrPdfRequestAction
    ) => {
      state.machineDetailsExportCtrPdf.isLoading = true;
      state.machineDetailsExportCtrPdf.id = action.payload.id;
      return state;
    },
    machineDetailsRobotExportCtrPdfSuccess: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotExportCtrPdfSuccessAction
    ) => {
      const {
        robotDetailsReportsExportRequest: { data },
      } = action.payload;

      state.machineDetailsExportCtrPdf.requestExportData = data;
      return state;
    },
    machineDetailsRobotExportCtrPdfError: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotExportCtrPdfErrorAction
    ) => {
      state.machineDetailsExportCtrPdf.id = null;
      state.machineDetailsExportCtrPdf.isLoading = false;
      return state;
    },
    machineDetailsRobotExportCtrPdfResetState: state => {
      state.machineDetailsExportCtrPdf = initialState.machineDetailsExportCtrPdf;
      return state;
    },

    machineDetailsRobotPollGetExportCtrPdfRequestAction: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotPollGetExportCtrPdfRequestAction
    ) => state,
    machineDetailsRobotPollGetExportCtrPdfSuccessAction: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotPollGetExportCtrPdfSuccessAction
    ) => {
      const {
        cleaningReportsExportGetFile: { data },
      } = action.payload;

      state.machineDetailsExportCtrPdf.id = null;
      state.machineDetailsExportCtrPdf.isLoading = false;
      state.machineDetailsExportCtrPdf.downloadUrl = data.presignedUrl;
      return state;
    },
    machineDetailsRobotPollGetExportCtrPdfErrorAction: (
      state,
      _action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotPollGetExportCtrPdfErrorAction
    ) => {
      state.machineDetailsExportCtrPdf.id = null;
      state.machineDetailsExportCtrPdf.isLoading = false;
      return state;
    },

    machineDetailsRobotIsRedirectedActive: (
      state,
      action: machineDetailsPanelRobotActionTypes.MachineDetailsRobotIsRedirectedActiveAction
    ) => {
      state.redirectedEventpage.isRedirected = true;
      state.redirectedEventpage.redirectedRobotId = action.payload.machineId;
      return state;
    },
    machineDetailsRobotIsNotRedirectedActive: state => {
      state.redirectedEventpage.isRedirected = false;
      return state;
    },
  },
});

// ACTIONS WITHOUT REDUCER CODE // START

export interface SubscribeToMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type SubscribeToMachineUpdateAction = PayloadAction<SubscribeToMachineUpdateActionPayload>;
const subscribeToMachineUpdate = createAction<SubscribeToMachineUpdateActionPayload>(
  'machine-details-robot/subscribeToMachineUpdate'
);
export interface UnsubscribeFromMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type UnsubscribeFromMachineUpdateAction = PayloadAction<UnsubscribeFromMachineUpdateActionPayload>;
const unsubscribeFromMachineUpdate = createAction<UnsubscribeFromMachineUpdateActionPayload>(
  'machine-details-robot/unsubscribeFromMachineUpdate'
);

// ACTIONS WITHOUT REDUCER CODE // END
export const MachineDetailsPanelRobotActions = {
  ...machineDetailsPanelRobotSlice.actions,
  subscribeToMachineUpdate,
  unsubscribeFromMachineUpdate,
};
export const machineDetailsPanelRobotReducer = machineDetailsPanelRobotSlice.reducer;
