import classnames from 'classnames';
import React, { useMemo } from 'react';
import { isNil } from 'lodash-es';
import { StatusPill, StatusPillProps } from '../../../../../lib/components/StatusPill/StatusPill';
import {
  GeofenceStatus,
  IMachineGeofenceStatusItemData,
  MachineGeofenceStatusItemData,
} from '../../interfaces/Machine.types';
import { StyledMachineGeofenceStatusIndicator } from './MachineGeofenceStatusIndicator.styles';
import { MachineGeofenceStatusIndicatorTooltip } from './MachineGeofenceStatusIndicatorTooltip';
import { MachineGeofenceStatusIndicatorGlobalStyles } from './MachineGeofenceStatusIndicator.global.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { MachineGeofenceStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineGeofenceIndicatorStatusProps {
  className?: string;
  machineGeofenceStatus?: Optional<MachineGeofenceStatus>;
  size?: 'small' | 'medium';
}

export const MachineGeofenceStatusIndicator = ({
  className,
  machineGeofenceStatus,
  size = 'medium',
}: MachineGeofenceIndicatorStatusProps): React.JSX.Element | null => {
  const getStatusPillSize = (): StatusPillProps['size'] => {
    if (size === 'medium') {
      return 'large';
    }

    return 'small';
  };

  const geofenceStatusItem = useMemo((): IMachineGeofenceStatusItemData => {
    if (isNil(machineGeofenceStatus)) {
      return MachineGeofenceStatusItemData[GeofenceStatus.NoData];
    }

    return MachineGeofenceStatusItemData[machineGeofenceStatus];
  }, [machineGeofenceStatus]);

  return (
    <StyledMachineGeofenceStatusIndicator
      className={classnames(className, 'machine-geofence-status', `machine-geofence-status--size-${size}`)}
    >
      <MachineGeofenceStatusIndicatorGlobalStyles />
      <Tooltip
        title={<MachineGeofenceStatusIndicatorTooltip geofenceStatusItem={geofenceStatusItem} />}
        overlayClassName="machine-geofence-status-tooltip__overlay"
      >
        <div className={`machine-geofence-status__tooltip-wrapper`}>
          <StatusPill
            color={geofenceStatusItem.color}
            size={getStatusPillSize()}
            className={`machine-geofence-status__pill`}
          >
            <SvgIcon name={geofenceStatusItem.icon} className={`machine-geofence-status__icon`} />
          </StatusPill>
        </div>
      </Tooltip>
    </StyledMachineGeofenceStatusIndicator>
  );
};
