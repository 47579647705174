import React from 'react';
import classnames from 'classnames';
import { ReactComponent as arrowIcon } from '../../assets/images/icons/Arrow_Icon.svg';
import { ReactComponent as deleteIcon } from '../../assets/images/icons/Delete_icon.svg';
import { ReactComponent as editIcon } from '../../assets/images/icons/Edit_icon.svg';
import { ReactComponent as homeIcon } from '../../assets/images/icons/Home_icon.svg';
import { ReactComponent as tilesIcon } from '../../assets/images/icons/Tiles_icon.svg';
import { ReactComponent as notificationsIcon } from '../../assets/images/icons/Notifications_icon.svg';
import { ReactComponent as permissionsIcon } from '../../assets/images/icons/Permissions_icon.svg';
import { ReactComponent as reportsIcon } from '../../assets/images/icons/Reports_icon.svg';
import { ReactComponent as sitesIcon } from '../../assets/images/icons/Sites_icon.svg';
import { ReactComponent as battery25Icon } from '../../assets/images/icons/Battery25_icon.svg';
import { ReactComponent as battery50Icon } from '../../assets/images/icons/Battery50_icon.svg';
import { ReactComponent as battery75Icon } from '../../assets/images/icons/Battery75_icon.svg';
import { ReactComponent as battery100Icon } from '../../assets/images/icons/Battery100_icon.svg';
import { ReactComponent as batteryNullIcon } from '../../assets/images/icons/BatteryNull_icon.svg';
import { ReactComponent as clockIcon } from '../../assets/images/icons/Clock_icon.svg';
import { ReactComponent as clockCheckmarkIcon } from '../../assets/images/icons/Clock_Checkmark_icon.svg';
import { ReactComponent as clockAttentionIcon } from '../../assets/images/icons/Clock_Attention_icon.svg';
import { ReactComponent as noConnectivityIcon } from '../../assets/images/icons/No_Connectivity_icon.svg';
import { ReactComponent as deactivatedIcon } from '../../assets/images/icons/Deactivated_icon.svg';
import { ReactComponent as arrowUpIcon } from '../../assets/images/icons/Arrow_Up_icon.svg';
import { ReactComponent as arrowDownIcon } from '../../assets/images/icons/Arrow_Down_icon.svg';
import { ReactComponent as newArrowDownIcon } from '../../assets/images/icons/New_Arrow_Down_icon.svg';
import { ReactComponent as plusIcon } from '../../assets/images/icons/Plus_icon.svg';
import { ReactComponent as bellCircleIcon } from '../../assets/images/icons/Bell_Circle_icon.svg';
import { ReactComponent as bellIcon } from '../../assets/images/icons/Bell_icon.svg';
import { ReactComponent as infoIcon } from '../../assets/images/icons/Info_icon.svg';
import { ReactComponent as settingIcon } from '../../assets/images/icons/Setting_icon.svg';
import { ReactComponent as backIcon } from '../../assets/images/icons/Back_icon.svg';
import { ReactComponent as expandMapIcon } from '../../assets/images/icons/Expand_Map_icon.svg';
import { ReactComponent as deviceMapIcon } from '../../assets/images/icons/Device_Map_icon.svg';
import { ReactComponent as downloadIcon } from '../../assets/images/icons/Download_icon.svg';
import { ReactComponent as batteryGreen75Icon } from '../../assets/images/icons/BatteryGreen75_icon.svg';
import { ReactComponent as waterHighestIcon } from '../../assets/images/icons/Water_Highest_icon.svg';
import { ReactComponent as onlineMachineIcon } from '../../assets/images/icons/online_machine_icon.svg';
import { ReactComponent as offlineMachineIcon } from '../../assets/images/icons/offline_machine_icon.svg';
import { ReactComponent as wallClockIcon } from '../../assets/images/icons/Wall_Clock_icon.svg';
import { ReactComponent as cleanIcon } from '../../assets/images/icons/Clean_icon.svg';
import { ReactComponent as distanceIcon } from '../../assets/images/icons/Distance_icon.svg';
import { ReactComponent as coverageIcon } from '../../assets/images/icons/Coverage_icon.svg';
import { ReactComponent as successRaceIcon } from '../../assets/images/icons/Success_Race_icon.svg';
import { ReactComponent as xMarkIcon } from '../../assets/images/icons/Xmark_icon.svg';
import { ReactComponent as waterAmountDoubleIcon } from '../../assets/images/icons/Water_Amount_Double_icon.svg';
import { ReactComponent as containerDetergentIcon } from '../../assets/images/icons/Container_Detergent_icon.svg';
import { ReactComponent as eyeSeeIcon } from '../../assets/images/icons/Eye_See_icon.svg';
import { ReactComponent as viewListIcon } from '../../assets/images/icons/View_List_icon.svg';
import { ReactComponent as viewModuleIcon } from '../../assets/images/icons/View_Module_icon.svg';
import { ReactComponent as expandIcon } from '../../assets/images/icons/Expand_icon.svg';
import { ReactComponent as minimizeIcon } from '../../assets/images/icons/Minimize_icon.svg';
import { ReactComponent as shrinkIcon } from '../../assets/images/icons/Shrink_icon.svg';
import { ReactComponent as machineIcon } from '../../assets/images/icons/Machine_Icon.svg';
import { ReactComponent as remoteControlIcon } from '../../assets/images/icons/Remote_Control_icon.svg';
import { ReactComponent as doubleChevronRightIcon } from '../../assets/images/icons/Double_Chevron_Right_icon.svg';
import { ReactComponent as filterIcon } from '../../assets/images/icons/Filter_Icon.svg';
import { ReactComponent as columnIcon } from '../../assets/images/icons/Column_Icon.svg';
import { ReactComponent as checkCircleIcon } from '../../assets/images/icons/Check_Circle_Icon.svg';
import { ReactComponent as warningIcon } from '../../assets/images/icons/Warning_Icon.svg';
import { ReactComponent as notAvailableIcon } from '../../assets/images/icons/Not_Available_Icon.svg';
import { ReactComponent as robotsIcon } from '../../assets/images/icons/Robots_Icon.svg';
import { ReactComponent as iotIcon } from '../../assets/images/icons/Iot_Icon.svg';
import { ReactComponent as nonIoTIcon } from '../../assets/images/icons/NonIot_Icon.svg';
import { ReactComponent as eventsIcon } from '../../assets/images/icons/Events_Icon.svg';
import { ReactComponent as smallArrowRightIcon } from '../../assets/images/icons/SmallArrowRight_icon.svg';
import { ReactComponent as exportReportIcon } from '../../assets/images/icons/ExportReport_icon.svg';
import { ReactComponent as checkmarkIcon } from '../../assets/images/icons/Checkmark_icon.svg';
import { ReactComponent as chevronLeftIcon } from '../../assets/images/icons/Chevron_Left_icon.svg';
import { ReactComponent as chevronRightIcon } from '../../assets/images/icons/Chevron_Right_icon.svg';
import { ReactComponent as emptyCtrIcon } from '../../assets/images/icons/Empty_Ctr_icon.svg';
import { ReactComponent as emptyRouteImageIcon } from '../../assets/images/icons/Empty_Route_Image_icon.svg';
import { ReactComponent as arrowSkipIcon } from '../../assets/images/icons/Arrow_Skip_icon.svg';
import { ReactComponent as imageIcon } from '../../assets/images/icons/Image_icon.svg';
import { ReactComponent as battery00Icon } from '../../assets/images/icons/Battery00_icon.svg';
import { ReactComponent as batteryIcon } from '../../assets/images/icons/Battery_icon.svg';
import { ReactComponent as battery00PercentIcon } from '../../assets/images/icons/Battery00_percent_icon.svg';
import { ReactComponent as battery25PercentIcon } from '../../assets/images/icons/Battery25_percent_icon.svg';
import { ReactComponent as battery50PercentIcon } from '../../assets/images/icons/Battery50_percent_icon.svg';
import { ReactComponent as battery75PercentIcon } from '../../assets/images/icons/Battery75_percent_icon.svg';
import { ReactComponent as battery100PercentIcon } from '../../assets/images/icons/Battery100_percent_icon.svg';
import { ReactComponent as water00PercentIcon } from '../../assets/images/icons/Water00_percent_icon.svg';
import { ReactComponent as water25PercentIcon } from '../../assets/images/icons/Water25_percent_icon.svg';
import { ReactComponent as water50PercentIcon } from '../../assets/images/icons/Water50_percent_icon.svg';
import { ReactComponent as water75PercentIcon } from '../../assets/images/icons/Water75_percent_icon.svg';
import { ReactComponent as water100PercentIcon } from '../../assets/images/icons/Water100_percent_icon.svg';
import { ReactComponent as eventReportIcon } from '../../assets/images/icons/Event_Report_icon.svg';
import { ReactComponent as batteryChargingIcon } from '../../assets/images/icons/BatteryCharging_icon.svg';
import { ReactComponent as houseIcon } from '../../assets/images/icons/House_icon.svg';
import { ReactComponent as WarningInformationCriticalIcon } from '../../assets/images/icons/Warning_Information_Critical_icon.svg';
import { ReactComponent as MinusCircleIcon } from '../../assets/images/icons/Minus_Circle_icon.svg';
import { ReactComponent as routeIcon } from '../../assets/images/icons/Route_icon.svg';
import { ReactComponent as routineIcon } from '../../assets/images/icons/Routine_icon.svg';
import { ReactComponent as imageMultiIcon } from '../../assets/images/icons/Image_Multi_icon.svg';
import { ReactComponent as arrowBackIcon } from '../../assets/images/icons/Arrow_Back.svg';
import '../../assets/fonts/icons/css/kaercher_system_icons.css';
import '../../assets/fonts/icons/css/kaercher_system_icons-codes.css';
import { ISvgIconName } from './interfaces/SvgIconNames';
import { StyledIcon } from './SvgIcon.styles';

const ICON_COMPONENTS = {
  arrow: arrowIcon,
  arrowBack: arrowBackIcon,
  delete: deleteIcon,
  edit: editIcon,
  home: homeIcon,
  notifications: notificationsIcon,
  permissions: permissionsIcon,
  reports: reportsIcon,
  sites: sitesIcon,
  dataAgeLessThanOneDay: clockCheckmarkIcon,
  dataAgeOneToThreeDays: clockIcon,
  dataAgeOverThreeDays: clockAttentionIcon,
  nonIot: noConnectivityIcon,
  deactivated: deactivatedIcon,
  info: infoIcon,
  arrowUp: arrowUpIcon,
  arrowDown: arrowDownIcon,
  newArrowDown: newArrowDownIcon,
  battery00: battery00Icon,
  battery25: battery25Icon,
  battery50: battery50Icon,
  battery75: battery75Icon,
  battery100: battery100Icon,
  batteryNull: batteryNullIcon,
  setting: settingIcon,
  plus: plusIcon,
  bellCircle: bellCircleIcon,
  bell: bellIcon,
  back: backIcon,
  expandMap: expandMapIcon,
  deviceMap: deviceMapIcon,
  download: downloadIcon,
  tiles: tilesIcon,
  batteryGreen75: batteryGreen75Icon,
  waterHighest: waterHighestIcon,
  onlineMachine: onlineMachineIcon,
  offlineMachine: offlineMachineIcon,
  wallClock: wallClockIcon,
  clean: cleanIcon,
  distance: distanceIcon,
  coverage: coverageIcon,
  successRace: successRaceIcon,
  xmark: xMarkIcon,
  waterAmountDouble: waterAmountDoubleIcon,
  containerDetergent: containerDetergentIcon,
  eyeSee: eyeSeeIcon,
  viewList: viewListIcon,
  viewModule: viewModuleIcon,
  expand: expandIcon,
  minimize: minimizeIcon,
  shrink: shrinkIcon,
  remoteControl: remoteControlIcon,
  machine: machineIcon,
  doubleChevronRight: doubleChevronRightIcon,
  filter: filterIcon,
  column: columnIcon,
  checkCircle: checkCircleIcon,
  warning: warningIcon,
  notAvailable: notAvailableIcon,
  robots: robotsIcon,
  iotNew: iotIcon,
  nonIotNew: nonIoTIcon,
  events: eventsIcon,
  smallArrowRight: smallArrowRightIcon,
  exportReport: exportReportIcon,
  checkmark: checkmarkIcon,
  chevronLeft: chevronLeftIcon,
  chevronRight: chevronRightIcon,
  emptyCtr: emptyCtrIcon,
  emptyRouteImage: emptyRouteImageIcon,
  arrowSkip: arrowSkipIcon,
  image: imageIcon,
  battery00Percent: battery00PercentIcon,
  battery25Percent: battery25PercentIcon,
  battery50Percent: battery50PercentIcon,
  battery75Percent: battery75PercentIcon,
  battery100Percent: battery100PercentIcon,
  water00Percent: water00PercentIcon,
  water25Percent: water25PercentIcon,
  water50Percent: water50PercentIcon,
  water75Percent: water75PercentIcon,
  water100Percent: water100PercentIcon,
  eventReport: eventReportIcon,
  batteryCharging: batteryChargingIcon,
  battery: batteryIcon,
  house: houseIcon,
  warningInformationCritical: WarningInformationCriticalIcon,
  minusCircle: MinusCircleIcon,
  route: routeIcon,
  routine: routineIcon,
  imageMulti: imageMultiIcon,
};

export interface ISvgIconProps {
  name: ISvgIconName;
  className?: string;
  verticalAlign?: number;
}

/**
 * SvgIcon component
 *
 * @param props - Icon props
 * @param props.name - Icon name
 * @param props.className - Additional class name to add to the icon
 * @param props.verticalAlign - Vertical align offset in px to apply to the icon
 * @constructor
 */
export const SvgIcon: React.FC<ISvgIconProps> = ({ name, className, verticalAlign = 0 }) => {
  const iconClassName = classnames('svg-icon', className, name);

  const IconComponent = ICON_COMPONENTS[name];

  if (!IconComponent) {
    throw new Error(`Icon identifier ${name} is not supported.`);
  }

  return (
    <StyledIcon className={iconClassName} verticalAlign={verticalAlign}>
      <IconComponent />
    </StyledIcon>
  );
};
