import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxOptionType, Form, Checkbox, Row, Col } from 'antd';
import isNil from 'lodash-es/isNil';
import find from 'lodash-es/find';
import dayjs, { Dayjs } from 'dayjs';
import { ValidatorRule } from 'rc-field-form/lib/interface';
// import { TimeRangeValidator } from '../../../../../../lib/utils/date-handling/TimeRangeValidator';
import { InCreation, IWorkIntervalLocal, IWorkIntervalUtc } from '../../../../cleaning/interfaces/CleaningPlan.types';
// import { CALENDAR_EVENT_TIME_FORMAT } from '../../../../cleaning/utils/CalendarUtils';
import { CleaningPlanUtils } from '../../../../cleaning/utils/CleaningPlanUtils';
import * as siteDetailsPanelSelectors from '../../../site-details-panel/state/siteDetailsPanelSelectors';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { SiteModalsActions } from '../../state/siteModalsActions';
import { StyledEditWorkIntervalModal } from './EditWorkIntervalModal.styles';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
// import { TimeRangePicker } from 'lib/components/TimeRangePicker/TimeRangePicker';
// import { LegacySelect } from 'lib/components/LegacySelect/LegacySelect';
import { MachineClassification, Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { GRAPHQL_MAX_INT_VALUE } from 'config/constants';
import { CleaningActions } from 'app/modules/cleaning/state/cleaningActions';
import { ToastService } from 'app/cross-cutting-concerns/toasts/ToastService';
import { SiteDetailsValidationError } from 'app/modules/site-management/site-details/ValidationError';
import { TimePicker } from 'lib/components/TimePicker/TimePicker';
import DurationPicker from 'app/utils/DurationPicker/DurationPicker';
import { Select } from 'lib/components/Select/Select';

export interface EditWorkIntervalFormValues {
  daysOfWeekLocal: number[];
  startTime: Dayjs;
  endTime: Dayjs;
  machine: string;
  // durationMinutes: number;
  plannedDurationMs?: number | null;
}

export interface EditWorkIntervalModalProps {
  site: Site;
}

const toastService = new ToastService();

export const EditWorkIntervalModal = ({ site }: EditWorkIntervalModalProps): JSX.Element | null => {
  const [formInstance] = Form.useForm();

  const startTimeValue = Form.useWatch('startTime', formInstance);
  const endTimeValue = Form.useWatch('endTime', formInstance);
  const durationValue = Form.useWatch('plannedDurationMs', formInstance);

  useEffect(() => {
    if (startTimeValue && durationValue) {
      formInstance.validateFields(['endTime']);
    }
  }, [formInstance, startTimeValue, durationValue, endTimeValue]);

  const [isWorkIntervalOpen, setIsWorkIntervalOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(siteModalsSelectors.selectIsEditWorkIntervalModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsEditWorkIntervalModalLoading);
  const workIntervals = useSelector(siteDetailsPanelSelectors.selectWorkIntervals);
  const workIntervalToEdit = useSelector(siteModalsSelectors.selectEditWorkIntervalModalWorkIntervalToEdit);
  const updateError = useSelector(siteDetailsPanelSelectors.selectUpdateWorkIntervalError);
  const workIntervalToEditPreviousStateLocal = find(workIntervals, { id: workIntervalToEdit?.id });
  const workIntervalToEditPreviousStateUtc = !isNil(workIntervalToEditPreviousStateLocal)
    ? CleaningPlanUtils.convertLocalWorkIntervalToUtc(workIntervalToEditPreviousStateLocal)
    : null;
  const availableSiteMachines = useSelector(siteModalsSelectors.selectAvailableSiteMachines);
  const areAvailableSiteMachinesLoading = useSelector(siteModalsSelectors.selectAreAvailableSiteMachinesLoading);
  const startTimeInit = dayjs()
    .local()
    .hour(workIntervalToEdit?.startHoursLocal ?? 0)
    .minute(workIntervalToEdit?.startMinutesLocal ?? 0)
    .second(0)
    .millisecond(0);

  const endTimeInit = dayjs(startTimeInit).add(workIntervalToEdit?.durationMs ?? 0, 'ms');

  const initialFormValues: EditWorkIntervalFormValues | null = workIntervalToEdit
    ? {
        daysOfWeekLocal: workIntervalToEdit.weekdaysLocal,
        machine: workIntervalToEdit.machine.id,
        startTime: startTimeInit,
        endTime: endTimeInit,
        plannedDurationMs: workIntervalToEdit.plannedDurationMs,
      }
    : null;

  const daysOfWeekOptions = t('siteDetails.addWorkIntervalModal.daysOfWeekOptions', {
    returnObjects: true,
  }) as CheckboxOptionType[];

  useEffect(() => {
    if (isVisible) {
      formInstance.setFieldsValue(initialFormValues);
    } else {
      formInstance.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstance, isVisible]);

  useEffect(() => {
    if (isVisible) {
      dispatch(
        SiteModalsActions.availableSiteMachinesRequest({
          id: site.id,
          filter: {
            classifications: [MachineClassification.Gcd, MachineClassification.Robot],
          },
          machinePaginationOptions: {
            limit: GRAPHQL_MAX_INT_VALUE,
          },
        })
      );
    }
  }, [dispatch, isVisible, site.id]);

  const handleCancel = useCallback(() => {
    dispatch(SiteModalsActions.hideEditWorkIntervalModal());
  }, [dispatch]);

  useEffect(() => {
    if (updateError) {
      toastService.error({
        message: t('toasts.validationError.title') as string,
        description: t(SiteDetailsValidationError[updateError.message as keyof typeof SiteDetailsValidationError], {
          machineName:
            availableSiteMachines.find(machine => machine.id === formInstance.getFieldValue('machine'))?.name || '',
        }),
      });
    }
  }, [availableSiteMachines, updateError, formInstance, t]);

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const onFinish = useCallback(
    ({ machine, startTime, endTime, daysOfWeekLocal, plannedDurationMs }: EditWorkIntervalFormValues) => {
      const workIntervalLocalInCreation: InCreation<IWorkIntervalLocal> =
        CleaningPlanUtils.createLocalWorkIntervalInCreation(
          machine,
          startTime,
          endTime,
          daysOfWeekLocal,
          plannedDurationMs || 0
        );

      const workIntervalUTCInCreation: InCreation<IWorkIntervalUtc> =
        CleaningPlanUtils.convertLocalWorkIntervalToUtc(workIntervalLocalInCreation);

      if (isNil(workIntervalToEdit)) return;

      if (
        !isNil(workIntervalToEditPreviousStateUtc) &&
        workIntervalUTCInCreation.machineId === workIntervalToEditPreviousStateUtc.machine.id &&
        workIntervalUTCInCreation.startHoursUtc === workIntervalToEditPreviousStateUtc.startHoursUtc &&
        workIntervalUTCInCreation.startMinutesUtc === workIntervalToEditPreviousStateUtc.startMinutesUtc &&
        workIntervalUTCInCreation.durationMs === workIntervalToEditPreviousStateUtc.durationMs &&
        isEqual(workIntervalUTCInCreation.weekdaysUtc, workIntervalToEditPreviousStateUtc.weekdaysUtc) &&
        workIntervalUTCInCreation.plannedDurationMs === workIntervalToEditPreviousStateUtc.plannedDurationMs
      ) {
        // Work interval was not changed. Close dialog.
        dispatch(SiteModalsActions.hideEditWorkIntervalModal());
      } else {
        dispatch(
          CleaningActions.updateWorkIntervalRequest({
            id: workIntervalToEdit?.id,
            siteId: site.id,
            input: {
              machineId: workIntervalUTCInCreation.machineId,
              timeSpan: {
                startHoursUtc: workIntervalUTCInCreation.startHoursUtc,
                startMinutesUtc: workIntervalUTCInCreation.startMinutesUtc,
                durationMs: workIntervalUTCInCreation.durationMs,
                plannedDurationMs: workIntervalUTCInCreation.plannedDurationMs || 0,
              },
              weekdaysUtc: workIntervalUTCInCreation.weekdaysUtc,
            },
          })
        );
      }
    },
    [dispatch, site.id, workIntervalToEdit, workIntervalToEditPreviousStateUtc]
  );

  const normalizedDuration = (timeDuration: Dayjs): any => {
    const d = (timeDuration.hour() * 60 + timeDuration.minute()) * 60 * 1000;
    return d;
  };

  const isEndTimeValid: ValidatorRule['validator'] = (_, value) => {
    if (value && startTimeValue && dayjs(value).isBefore(dayjs(startTimeValue).add(durationValue, 'milliseconds'))) {
      return Promise.reject(t('timeRangeValidator.errors.endTimeMustAfterStartTimePlusDuration'));
    }
    return Promise.resolve(true);
  };

  return (
    <StyledEditWorkIntervalModal
      className="edit-work-interval-modal"
      title={t('siteDetails.editWorkIntervalModal.modalTitle')}
      open={isVisible}
      width={600}
      footer={[
        <LegacySecondaryButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.save')}
        </LegacyPrimaryButton>,
      ]}
    >
      <Form form={formInstance} name="edit-work-interval" layout="vertical" onFinish={onFinish} autoComplete="off">
        <Form.Item
          name="daysOfWeekLocal"
          required
          rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.daysOfWeekRequired') }]}
        >
          <Checkbox.Group className="edit-work-interval-modal__days-of-week" options={daysOfWeekOptions} />
        </Form.Item>

        {/* <Form.Item
          name="timeSpanLocal"
          label={t('siteDetails.addWorkIntervalModal.timeSpan')}
          required
          rules={[
            { required: true, message: t('timeRangeValidator.errors.timeSpanRequired') },
            {
              validator: TimeRangeValidator.validateStartTimeEndTimeNotIdentical,
            },
          ]}
        >
          <TimeRangePicker
            format={CALENDAR_EVENT_TIME_FORMAT}
            className="edit-work-interval-modal__time-range-picker"
          />
        </Form.Item> */}

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="startTime"
              label={t('siteDetails.addWorkIntervalModal.startTime')}
              required
              rules={[{ required: true, message: t('timeRangeValidator.errors.startTimeRequired') }]}
            >
              <TimePicker
                use12Hours={false}
                minuteStep={5}
                hourStep={1}
                format="HH:mm"
                className="edit-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="plannedDurationMs"
              label={t('siteDetails.addWorkIntervalModal.duration')}
              required
              normalize={normalizedDuration}
              initialValue={3600000}
              rules={[{ required: true, message: t('timeRangeValidator.errors.durationRequired') }]}
            >
              <DurationPicker
                use12Hours={false}
                minuteStep={15}
                hourStep={1}
                className="edit-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="endTime"
              label={t('siteDetails.addWorkIntervalModal.endTime')}
              required
              rules={[
                { required: true, message: t('timeRangeValidator.errors.endTimeRequired') },
                {
                  validator: isEndTimeValid,
                },
              ]}
            >
              <TimePicker
                use12Hours={false}
                minuteStep={5}
                hourStep={1}
                format="HH:mm"
                className="edit-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="machine"
              label={t('siteDetails.addWorkIntervalModal.device')}
              required
              rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.machineRequired') }]}
            >
              <Select
                className="edit-work-interval-modal__dropdown"
                optionLabelProp="name"
                maxTagCount="responsive"
                loading={areAvailableSiteMachinesLoading}
                filterOption={(input, option): boolean => option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
                options={[
                  ...(availableSiteMachines.map(siteMachine => ({
                    label: [siteMachine.name, siteMachine.type?.name].filter(Boolean).join(', '),
                    value: siteMachine.id,
                    name: siteMachine.name,
                  })) || []),
                ]}
                dropdownVisibleState={isWorkIntervalOpen}
                onDropdownVisibleChange={(isOpen: boolean): void => setIsWorkIntervalOpen(isOpen)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledEditWorkIntervalModal>
  );
};
