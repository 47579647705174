import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledLogout } from './Logout.styles';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AuthenticationActions } from 'app/cross-cutting-concerns/authentication/state/authenticationActions';
import logout from 'app/assets/images/logout.svg';

export const Logout = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const logoutUrl = process.env.REACT_APP_MY_KARCHER_AUTH_URL_LOGOUT;

  const handleLogout = (): void => {
    setTimeout(() => {
      window.location.href = logoutUrl as string;
    }, 2000);
  };

  return (
    <StyledLogout>
      <TextButton
        size="m"
        className="logout__logout-button"
        onClick={async (): Promise<void> => {
          analyticsLinkActivated({
            linkName: AnalyticsLink.LOGOUT,
          });
          dispatch(AuthenticationActions.signOut());
          handleLogout();
        }}
      >
        <div className="logout__logout-button-title">
          <span className="logout__logout-button-text">{t('profile.logoutButton')}</span>
          <img src={logout} alt="logout" />
        </div>
      </TextButton>
    </StyledLogout>
  );
};
