import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Col, Flex, Row } from 'antd';
import { StyledShortInfoRoutineDetails } from './ShortInfoRoutineDetails.styles';
import { Optional } from 'lib/types/Optional';
import { Odometer } from 'lib/components/Odometer/Odometer';

export interface ShortInfoRoutineDetailsProps {
  className?: string;
  icon: ReactElement;
  value: number;
  unit?: Optional<string>;
}

export const ShortInfoRoutineDetails = ({
  className,
  icon,
  value,
  unit,
}: ShortInfoRoutineDetailsProps): JSX.Element => (
  <StyledShortInfoRoutineDetails className={classnames('short-info', className)}>
    <Flex className="short-info__container">
      <div className="short-info__icon">
        <div>{icon}</div>
      </div>
      <Row gutter={[4, 4]}>
        <Col>
          <Odometer value={value.toString()} />
        </Col>
        <Col>
          <p className="short-info__unit">{unit}</p>
        </Col>
      </Row>
    </Flex>
  </StyledShortInfoRoutineDetails>
);
