import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space, Spin } from 'antd';
import { DateTime } from '../../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import { SingleRoutesAndRoutineData } from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotRouteImagePreviewGlobalStyles } from './RobotRouteImagePreview.global.styles';
import { ROBOT_CTR_ROUTE_IMAGE_FALLBACK } from 'app/modules/machine-inventory/utils/constants';
import { CachingStorageSelector } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSelector';
import { cachingStorageActions } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSlice';
import { downloadLink } from 'app/utils/download-link/downloadLink';

interface RobotRouteImagePreviewProps {
  cleaningTaskReport: SingleRoutesAndRoutineData;
  onVisibilityChange: (value: boolean) => void;
  visible: boolean;
}

export const RobotRouteImagePreview = ({
  cleaningTaskReport,
  onVisibilityChange,
  visible,
}: RobotRouteImagePreviewProps): JSX.Element => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const imageAsDataUrl = useSelector(CachingStorageSelector.selectImageStorageObjectUrl);
  const ctrImage = useSelector(CachingStorageSelector.selectImageStorageList);
  const isRobotRouteImageLoading = useSelector(CachingStorageSelector.selectImageStorageIsLoading);
  const imageFinishedTime = useSelector(CachingStorageSelector.selectImageStorageFinshedAt);

  const cleaningRouteDate = DateTime.formatDateByLocale(i18n.language, imageFinishedTime, DATE_FORMAT_PATTERN.DATE);

  const handleVisibleChange = (value: boolean): void => {
    onVisibilityChange(value);
  };

  const imageTitle = `${cleaningTaskReport.machineName} - Cleaning Route ${cleaningRouteDate}`;

  useEffect(() => {
    if (visible) {
      if (!cleaningTaskReport.routineMaps || cleaningTaskReport.routineMaps.length === 0) {
        return;
      }

      const key = `${cleaningTaskReport.machineId}-${cleaningTaskReport.routineMaps[0]}`;
      const image = ctrImage[key];

      if (!image) {
        dispatch(
          cachingStorageActions.getImageRequest({
            machineId: cleaningTaskReport.machineId || '',
            finishedAt: cleaningTaskReport.routineMaps[0] || '',
          })
        );
      } else {
        dispatch(cachingStorageActions.setImageRequest(image));
      }
    }
  }, [
    cleaningTaskReport.finishedAt,
    cleaningTaskReport.machineId,
    dispatch,
    visible,
    cleaningTaskReport.routineMaps,
    ctrImage,
  ]);

  const onChange = (current: number): void => {
    if (!cleaningTaskReport.routineMaps || cleaningTaskReport.routineMaps.length === 0) {
      return;
    }
    const finishedAt = cleaningTaskReport.routineMaps[current] || '';
    const key = `${cleaningTaskReport.machineId}-${finishedAt}`;
    const image = ctrImage[key] || '';
    if (!image) {
      dispatch(
        cachingStorageActions.getImageRequest({
          machineId: cleaningTaskReport.machineId || '',
          finishedAt,
        })
      );
    }
  };

  const keyList =
    cleaningTaskReport.routineMaps?.map(routineMap => `${cleaningTaskReport.machineId}-${routineMap}`) || [];

  const handleDownload = useCallback(() => {
    if (imageAsDataUrl) {
      downloadLink({ fileUrl: imageAsDataUrl, fileName: imageTitle });
    }
  }, [imageAsDataUrl, imageTitle]);

  return (
    <>
      <RobotRouteImagePreviewGlobalStyles />
      <Image.PreviewGroup
        items={keyList?.map(key => ctrImage[key || ''] || '')}
        fallback={ROBOT_CTR_ROUTE_IMAGE_FALLBACK}
        preview={{
          visible,
          onVisibleChange: handleVisibleChange,
          imageRender: originalNode => (
            <div className="cleaning-task-report-robot__preview-route-image-render">
              {isRobotRouteImageLoading ? (
                <div className="cleaning-task-report-robot__preview-route-image-loading-indicator">
                  <Spin size="default" />
                </div>
              ) : (
                <>
                  <div className="cleaning-task-report-robot__preview-route-image-title">{imageTitle}</div>
                  {originalNode}
                </>
              )}
            </div>
          ),
          onChange,

          toolbarRender: (
            _,
            { transform: { scale }, actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
          ) => (
            <Space size={12} className="toolbar-wrapper">
              <DownloadOutlined
                onClick={handleDownload}
                disabled={!imageAsDataUrl}
                style={{ cursor: !imageAsDataUrl ? 'not-allowed' : 'pointer' }}
              />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      >
        <Image
          width={200}
          className="cleaning-task-report-robot__preview-route-image"
          wrapperClassName="cleaning-task-report-robot__preview-route-image-wrapper"
        />
      </Image.PreviewGroup>
    </>
  );
};
