import { useMemo } from 'react';
import { TextButton } from '../Button/TextButton/TextButton';
import { StyledChevronBar } from './ChevronBar.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { getMonthsByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';

interface ChevronBarProps {
  startDate: string | null;
  endDate: string | null;
  titleArray?: string[];
  titleIndex: number;
  changeTitleIndex: (value: number) => void;
  className?: string;
  isHideTitle?: boolean;

  getTimeRangeByPeriod?: (startDate: string, endDate: string) => string[];
}

export const ChevronBar = ({
  startDate,
  endDate,
  titleIndex,
  changeTitleIndex,
  getTimeRangeByPeriod,
  isHideTitle,
  className,
}: ChevronBarProps): JSX.Element => {
  const titleArray = useMemo(() => {
    let dates: string[] = [];
    if (startDate && endDate) {
      if (getTimeRangeByPeriod) {
        dates = getTimeRangeByPeriod(startDate, endDate);
      } else {
        dates = getMonthsByPeriod(startDate, endDate);
      }
    }
    return dates;
  }, [startDate, endDate, getTimeRangeByPeriod]);
  const isShowArrowRightBtn = useMemo(() => titleIndex !== titleArray.length - 1, [titleIndex, titleArray.length]);

  const isShowArrowLeftBtn = useMemo(() => titleIndex !== 0, [titleIndex]);

  const onLeftArrowClick = (): void => {
    if (!isShowArrowLeftBtn) {
      return;
    }
    changeTitleIndex(titleIndex - 1);
  };

  const onRightArrowClick = (): void => {
    if (!isShowArrowRightBtn) {
      return;
    }
    changeTitleIndex(titleIndex + 1);
  };

  return (
    <StyledChevronBar className={className}>
      <TextButton
        size="s"
        onClick={onLeftArrowClick}
        className={isShowArrowLeftBtn ? 'chevron-bar__arrow' : 'chevron-bar__arrow-hidden'}
        icon={<SvgIcon className="chevron-bar__arrow-left" name="arrow" />}
      />
      <span className="chevron-bar__title">{!isHideTitle && titleArray[titleIndex]}</span>
      <TextButton
        size="s"
        onClick={onRightArrowClick}
        className={isShowArrowRightBtn ? 'chevron-bar__arrow' : 'chevron-bar__arrow-hidden'}
        icon={<SvgIcon className="chevron-bar__arrow-right" name="arrow" />}
      />
    </StyledChevronBar>
  );
};
