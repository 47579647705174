import { singleton } from 'tsyringe';
import {
  CleaningTaskReportClient,
  CleaningTaskReportClientListOptions,
  CleaningTaskReportClientGetRobotRouteImageUrlOptions,
  CleaningTaskReportClientGetRobotRouteImageDataOptions,
  RobotEventClientListOptions,
  RoutineDetailsClientOptions,
} from './CleaningTaskReportClient';
import {
  CleaningTaskReportListResponse,
  CleaningTaskReportRouteImageResponse,
  RobotEventListResponse,
  RoutineCleaningTaskReportResponse,
} from './interfaces/CleaningTaskReport.types';

export type CleaningTaskReportServiceListOptions = CleaningTaskReportClientListOptions;

export type CleaningTaskReportServiceRouteImageOptions = CleaningTaskReportClientGetRobotRouteImageUrlOptions;

export type CleaningTaskReportRestServiceDownloadOptions = CleaningTaskReportClientGetRobotRouteImageDataOptions;
export type RobotEventServiceListOptions = RobotEventClientListOptions;

export type CleaningTaskReportServiceRoutineDetailsOptions = RoutineDetailsClientOptions;
@singleton()
export class CleaningTaskReportService {
  constructor(private cleaningTaskReportClient: CleaningTaskReportClient) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: CleaningTaskReportServiceListOptions): Promise<CleaningTaskReportListResponse> => {
    const { data } = await this.cleaningTaskReportClient.list({ filter, sortOptions, paginationOptions });
    return data;
  };

  public getRobotRouteImageUrl = async ({
    machineId,
    finishedAt,
  }: CleaningTaskReportServiceRouteImageOptions): Promise<CleaningTaskReportRouteImageResponse> => {
    const { data } = await this.cleaningTaskReportClient.getRobotRouteImageUrl({ machineId, finishedAt });
    return data;
  };

  public getRobotRouteImageData = async ({
    robotRouteImageUrl,
  }: CleaningTaskReportRestServiceDownloadOptions): Promise<string> => {
    const { data } = await this.cleaningTaskReportClient.getRobotRouteImageData({ robotRouteImageUrl });

    const imageObjectUrl = URL.createObjectURL(data);

    return imageObjectUrl;
  };

  public listRobotEvents = async ({
    filter,
    sortOptions,
    search,
    paginationOptions,
  }: RobotEventServiceListOptions): Promise<RobotEventListResponse> => {
    const { data } = await this.cleaningTaskReportClient.listRobotEvents({
      filter,
      sortOptions,
      search,
      paginationOptions,
    });
    return data;
  };

  public getRoutineDetails = async ({
    filter,
  }: CleaningTaskReportServiceRoutineDetailsOptions): Promise<RoutineCleaningTaskReportResponse> => {
    const { data } = await this.cleaningTaskReportClient.getRoutineDetails({ filter });
    return data;
  };
}
