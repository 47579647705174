import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export class CachingStorageSelector {
  public static selectImageStorageIsLoading(state: IState): boolean {
    return state['cross-cutting-concerns'].cachingStorage.imageStorage.foundImage.isLoading;
  }

  public static selectImageStorageList(state: IState): Record<string, string> {
    return state['cross-cutting-concerns'].cachingStorage.imageStorage.listImage;
  }

  public static selectImageStorageObjectUrl(state: IState): string | undefined {
    return state['cross-cutting-concerns'].cachingStorage.imageStorage.foundImage.imageObjectUrl;
  }

  public static selectImageStorageFinshedAt(state: IState): string {
    return state['cross-cutting-concerns'].cachingStorage.imageStorage.foundImage.finishedAt;
  }
}
