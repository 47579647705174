export const LANGUAGES_USER_SNAP_SUPPORTED: Record<string, string> = {
  en: 'en',
  cs: 'cs',
  de: 'de',
  es: 'es',
  fr: 'fr',
  hr: 'hr',
  hu: 'hu',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  lt: 'lt',
  nl: 'nl',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sv: 'sv',
  tr: 'tr',
  pt: 'pt',
  zh: 'zh-CN',
};

export const BackendErrorMapping: Record<string, string> = {
  'BackendError.REPORT_DOES_NOT_HAVE_MAP_ID': 'toasts.backendError.reportDoesNotHaveMapId',
  'BackendError.MAP_NOT_FOUND_FOR_CLEANING_ROUTE': 'toasts.backendError.mapNotFoundForCleaningRoute',
  'BackendError.UNKNOWN_MAP_ERROR': 'toasts.backendError.unknownMapError',
};
