import {
  ResponseCleaningTaskCleanedAreaByDayList,
  ResponseMachineVncRequest,
  ResponseRobotDashboardKpIsGet,
  ResponseRobotDashboardTotalCleanedAreaGet,
  ResponseRobotDashboardTotalCleanedHrsGet,
  ResponseRobotTaskCompletionStatisticGet,
  Site,
  ResponseMachineVncStatusGet,
  ResponseCleaningTaskCleanedHrsByDayList,
  ResponseCleaningTaskDistanceDrivenByDayList,
  ResponseCleaningTaskConsumablesByDayList,
  ResponseRobotCleaningRoutesList,
  RobotCleaningRoutesListData,
  ResponseMachineVncRequestV2,
  ResponseMachineVncStatusGetV2,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { theme } from 'config/theme';

export enum RobotStatusDisplayName {
  Cleaning = 'Cleaning',
  Standby = 'Standby',
  Docking = 'Docking',
  Offline = 'Offline',
  Exploration = 'Exploration',
  NA = 'NA',
}

export enum Telemetry {
  RepeatProgress = 'repeatProgress',
  ScheduledTasks = 'scheduledTasks',
  BatteryChargeLevel = 'batteryChargeLevel',
  FreshWaterLevel = 'freshWaterLevel',
  BatteryData = 'batteryData',
  Charging = 'charging',
}

export interface RobotDashboardTotalCleanedAreaResponse {
  robotDashboardTotalCleanedArea: ResponseRobotDashboardTotalCleanedAreaGet;
}

export interface RobotDashboardTotalCleanedHourResponse {
  robotDashboardTotalCleanedHrs: ResponseRobotDashboardTotalCleanedHrsGet;
}

export interface RobotDashboardKPIsResponse {
  robotDashboardKPIs: ResponseRobotDashboardKpIsGet;
}
export interface RobotDashboardTasksCompletionStatisticResponse {
  robotTaskCompletionStatistic: ResponseRobotTaskCompletionStatisticGet;
}

export interface RobotAvailableFilters {
  sites: Site[];
}

export interface RepeatProgress {
  progress: number;
  repeatPathId: string;
  routeName: string;
}

export interface ScheduledTime {
  routineId: string;
  taskId: string;
  startDate: string;
  routeName?: string;
  taskName: string;
}

export interface BatteryData {
  batteryPack1: {
    stateOfCharge: number;
  };
  batteryPack2: {
    stateOfCharge: number;
  };
}

export interface RobotRequestVncResponse {
  machineVncRequest: ResponseMachineVncRequest;
}

export interface RobotRequestVncResponseV2 {
  machineVncRequestV2: ResponseMachineVncRequestV2;
}

export interface RobotGetVncStatusResponse {
  machineVncStatusGet: ResponseMachineVncStatusGet;
}

export interface RobotGetVncStatusV2Response {
  machineVncStatusGetV2: ResponseMachineVncStatusGetV2;
}

export interface RobotCleaningTaskCleanedAreaByDayListResponse {
  cleaningTaskCleanedAreaByDayList: ResponseCleaningTaskCleanedAreaByDayList;
}

export interface RobotCleaningTaskCleanedHrsByDayListResponse {
  cleaningTaskCleanedHrsByDayList: ResponseCleaningTaskCleanedHrsByDayList;
}

export interface RobotCleaningTaskConsumablesByDayListResponse {
  cleaningTaskConsumablesByDayList: ResponseCleaningTaskConsumablesByDayList;
}

export interface RobotCleaningTaskDistanceDrivenByDayListResponse {
  cleaningTaskDistanceDrivenByDayList: ResponseCleaningTaskDistanceDrivenByDayList;
}

export const CHART_STYLE = {
  X_TICKS_FONT: {
    family: theme.fonts.captionMedium,
    size: 14,
    weight: theme.fontWeights.p1Medium,
    lineHeight: theme.lineHeights.caption,
  },
  Y_TICKS_FONT: {
    family: theme.fonts.captionNews,
    size: 11,
    weight: theme.fontWeights.captionSmall,
    lineHeight: theme.lineHeights.caption,
  },
  TITLE_FONT: {
    family: theme.fonts.captionMedium,
    size: 11,
    weight: theme.fontWeights.p1Medium,
    lineHeight: theme.lineHeights.caption,
  },
  BODY_FONT: {
    family: theme.fonts.captionNews,
    size: 11,
    weight: theme.fontWeights.captionSmall,
    lineHeight: theme.lineHeights.caption,
  },
};

export enum ChartColor {
  CELADON_GREEN = '#238C96',
  WHITE = 'rgba(255, 255, 255)',
  BLACK = 'rgba(0, 0, 0, 0.8)',
  GREY = 'rgba(212, 212, 212, 1)',
  LIGHT_SECONDARY = '#BDDDE0',
  SEMANTIC_SS = '#4AAD40',
}

export interface RoutesNameListResponse {
  robotCleaningRoutesList: ResponseRobotCleaningRoutesList;
}

export interface MachineDetailsRobotAvailableFilters {
  routesName: RobotCleaningRoutesListData[];
}

export enum DateFormats {
  DATE_IN_YEAR_MONTH_DAY = 'YYYY-MM-DD',
  DATE_IN_DAY_MONTH = 'DD.MM',
  DATE_IN_MONTH_DAY_YEAR = 'MMM DD YYYY',
  DATE_IN_WEEKDAY_DAY_MONTH_YEAR = 'dddd, DD.MM.YYYY',
  DATE_IN_MONTH_WEEKDAY = 'MMM dd',
  DATE_IN_MONTH_DAY_SHORT = 'M/d',
}
