import { find, isEqual, isNil } from 'lodash-es';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, retry, SagaGenerator, select, takeLatest } from 'typed-redux-saga';
import { GraphQLErrors } from '@apollo/client/errors';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from '../../../../config/constants';
import { Optional } from '../../../../lib/types/Optional';
import { IDependencies } from '../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IAddWorkIntervalModalIsLoadingAction,
  IAddWorkIntervalModalIsNotLoadingAction,
  IDeleteWorkIntervalModalIsLoadingAction,
  IDeleteWorkIntervalModalIsNotLoadingAction,
  IEditWorkIntervalModalIsLoadingAction,
  IEditWorkIntervalModalIsNotLoadingAction,
  IHideAddWorkIntervalModalAction,
  IHideDeleteWorkIntervalModalAction,
  IHideEditWorkIntervalModalAction,
  SiteModalsActions,
} from '../../site-management/modals/state/siteModalsActions';
import * as siteDetailsSelectors from '../../site-management/site-details/state/siteDetailsSelectors';
import {
  WorkIntervalCreateResponse,
  CleaningPlanDeleteResponse,
  WorkIntervalListResponse,
  IWorkIntervalUtc,
  WorkIntervalUpdateResponse,
  IWorkIntervalLocal,
} from '../interfaces/CleaningPlan.types';
import { CleaningPlanUtils } from '../utils/CleaningPlanUtils';
import {
  CleaningActions,
  ICreateWorkIntervalErrorAction,
  ICreateWorkIntervalRequestAction,
  ICreateWorkIntervalSuccessAction,
  IDeleteWorkIntervalErrorAction,
  IDeleteWorkIntervalRequestAction,
  IDeleteWorkIntervalSuccessAction,
  IGetWorkIntervalsErrorAction,
  IGetWorkIntervalsRequestAction,
  IGetWorkIntervalsSuccessAction,
  IPollWorkIntervalOperation,
  IPollWorkIntervalsRequestAction,
  IUpdateWorkIntervalErrorAction,
  IUpdateWorkIntervalRequestAction,
  IUpdateWorkIntervalSuccessAction,
  IWorkIntervalsIsLoadingAction,
} from './cleaningActions';
import { SiteDetailsValidationError } from 'app/modules/site-management/site-details/ValidationError';
import { SiteDetailsPanelActions } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelActions';

export function* getWorkIntervalsSaga(
  action: IGetWorkIntervalsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<WorkIntervalListResponse>
  | PutEffect<IGetWorkIntervalsSuccessAction>
  | PutEffect<IGetWorkIntervalsErrorAction>,
  void,
  IDependencies
> {
  const { cleaningPlanService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningPlanService.listWorkIntervals, action.payload);

    yield* put(CleaningActions.getWorkIntervalsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningActions.getWorkIntervalsError({
        error,
      })
    );
  }
}

export function* createWorkIntervalSaga(
  action: ICreateWorkIntervalRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<WorkIntervalCreateResponse>>
  | PutEffect<IAddWorkIntervalModalIsLoadingAction>
  | PutEffect<ICreateWorkIntervalRequestAction>
  | PutEffect<ICreateWorkIntervalSuccessAction>
  | CallEffect<WorkIntervalListResponse>
  | PutEffect<IGetWorkIntervalsSuccessAction>
  | PutEffect<IAddWorkIntervalModalIsNotLoadingAction>
  | PutEffect<IHideAddWorkIntervalModalAction>
  | PutEffect<ICreateWorkIntervalErrorAction>,
  void,
  IDependencies
> {
  const { siteId, workIntervalUtc } = action.payload;
  const { cleaningPlanService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.addWorkIntervalModalIsLoading());

    const response: Optional<WorkIntervalCreateResponse> = yield* call(cleaningPlanService.createWorkInterval, {
      ...workIntervalUtc,
      machineId: workIntervalUtc.machineId,
    });

    yield* put(CleaningActions.createWorkIntervalSuccess(response));

    const workIntervalId = response?.workIntervalCreate.data.id;

    if (isNil(workIntervalId)) {
      throw new Error('Invalid response. Cleaning plan id cannot be undefined.');
    }

    // Load updated list of work intervals
    yield* put(
      CleaningActions.pollWorkIntervalsRequest({
        siteId,
        workIntervalId,
        operation: IPollWorkIntervalOperation.CREATE,
      })
    );
    yield* put(SiteModalsActions.hideAddWorkIntervalModal());
  } catch (errors) {
    console.error(errors);
    const isValidationError = (errors as GraphQLErrors).find(
      error => SiteDetailsValidationError[error.message as keyof typeof SiteDetailsValidationError]
    );
    if (isValidationError) {
      yield* put(
        CleaningActions.createWorkIntervalError({
          error: isValidationError,
        })
      );
    }
  } finally {
    yield* put(SiteModalsActions.addWorkIntervalModalIsNotLoading());
    yield* put(SiteDetailsPanelActions.resetCreateWorkIntervalError());
  }
}

export function* deleteWorkIntervalSaga(
  action: IDeleteWorkIntervalRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<CleaningPlanDeleteResponse>>
  | PutEffect<IDeleteWorkIntervalModalIsLoadingAction>
  | PutEffect<IDeleteWorkIntervalRequestAction>
  | PutEffect<IDeleteWorkIntervalSuccessAction>
  | PutEffect<IDeleteWorkIntervalModalIsNotLoadingAction>
  | PutEffect<IHideDeleteWorkIntervalModalAction>
  | PutEffect<IDeleteWorkIntervalErrorAction>,
  void,
  IDependencies
> {
  const { siteId, id: workIntervalId } = action.payload;
  const { cleaningPlanService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.deleteWorkIntervalModalIsLoading());

    const response: Optional<CleaningPlanDeleteResponse> = yield* call(
      cleaningPlanService.deleteWorkInterval,
      action.payload
    );

    yield* put(CleaningActions.deleteWorkIntervalSuccess(response));

    // Load updated list of work intervals
    yield* put(
      CleaningActions.pollWorkIntervalsRequest({
        siteId,
        workIntervalId,
        operation: IPollWorkIntervalOperation.DELETE,
      })
    );
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningActions.deleteWorkIntervalError({
        error: (error as Error).message,
      })
    );
  } finally {
    yield* put(SiteModalsActions.deleteWorkIntervalModalIsNotLoading());
    yield* put(SiteModalsActions.hideDeleteWorkIntervalModal());
  }
}

export function* updateWorkIntervalSaga(
  action: IUpdateWorkIntervalRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<WorkIntervalUpdateResponse>>
  | PutEffect<IEditWorkIntervalModalIsLoadingAction>
  | PutEffect<IUpdateWorkIntervalRequestAction>
  | PutEffect<IUpdateWorkIntervalSuccessAction>
  | PutEffect<IEditWorkIntervalModalIsNotLoadingAction>
  | PutEffect<IHideEditWorkIntervalModalAction>
  | PutEffect<IUpdateWorkIntervalErrorAction>,
  void,
  IDependencies
> {
  const { id: workIntervalId, siteId, input } = action.payload;
  const { cleaningPlanService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.editWorkIntervalModalIsLoading());

    const response: Optional<WorkIntervalUpdateResponse> = yield* call(cleaningPlanService.updateWorkInterval, {
      id: workIntervalId,
      input,
    });

    yield* put(CleaningActions.updateWorkIntervalSuccess(response));

    // Load updated list of work intervals
    yield* put(
      CleaningActions.pollWorkIntervalsRequest({
        siteId,
        workIntervalId,
        operation: IPollWorkIntervalOperation.UPDATE,
      })
    );
    yield* put(SiteModalsActions.hideEditWorkIntervalModal());
  } catch (errors) {
    console.error(errors);

    const isValidationError = (errors as GraphQLErrors).find(
      error => SiteDetailsValidationError[error.message as keyof typeof SiteDetailsValidationError]
    );
    if (isValidationError) {
      yield* put(
        CleaningActions.updateWorkIntervalError({
          error: isValidationError,
        })
      );
    }
  } finally {
    yield* put(SiteModalsActions.editWorkIntervalModalIsNotLoading());
    yield* put(SiteDetailsPanelActions.resetUpdateWorkIntervalError());
  }
}

export function* pollWorkIntervals(
  action: IPollWorkIntervalsRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | SagaGenerator<WorkIntervalListResponse, CallEffect<WorkIntervalListResponse>>
  | PutEffect<IWorkIntervalsIsLoadingAction>
  | PutEffect<IGetWorkIntervalsSuccessAction>
  | PutEffect<IGetWorkIntervalsErrorAction>,
  void,
  any
> {
  const { workIntervalId: modifiedWorkIntervalId, siteId, operation } = action.payload;
  const { cleaningPlanService } = yield* getContext<IDependencies>('dependencies');
  const previousWorkIntervalsLocal: Optional<IWorkIntervalLocal[]> = yield* select(
    siteDetailsSelectors.selectWorkIntervals
  );
  const previousWorkIntervalsUtc: Optional<IWorkIntervalUtc[]> = previousWorkIntervalsLocal?.map(
    (workIntervalLocal: IWorkIntervalLocal): IWorkIntervalUtc =>
      CleaningPlanUtils.convertLocalWorkIntervalToUtc(workIntervalLocal)
  );

  const getWorkIntervalsAfterModification = async (): Promise<WorkIntervalListResponse> => {
    let predicate: () => boolean;
    const response: WorkIntervalListResponse = await cleaningPlanService.listWorkIntervals({ filter: { siteId } });

    const newWorkIntervals: IWorkIntervalUtc[] = response?.workIntervals?.data;

    switch (operation) {
      case IPollWorkIntervalOperation.CREATE: {
        predicate = (): boolean => newWorkIntervals.some(interval => interval.id === modifiedWorkIntervalId);
        break;
      }

      case IPollWorkIntervalOperation.UPDATE: {
        predicate = (): boolean => {
          const modifiedWorkIntervalPrevious = find(previousWorkIntervalsUtc, { id: modifiedWorkIntervalId });
          const modifiedWorkIntervalNew = find(newWorkIntervals, { id: modifiedWorkIntervalId });

          if (isNil(modifiedWorkIntervalPrevious) || isNil(modifiedWorkIntervalNew)) {
            return false;
          }

          if (
            modifiedWorkIntervalNew.machine.id === modifiedWorkIntervalPrevious.machine.id &&
            modifiedWorkIntervalNew.startHoursUtc === modifiedWorkIntervalPrevious.startHoursUtc &&
            modifiedWorkIntervalNew.startMinutesUtc === modifiedWorkIntervalPrevious.startMinutesUtc &&
            modifiedWorkIntervalNew.durationMs === modifiedWorkIntervalPrevious.durationMs &&
            modifiedWorkIntervalNew.plannedDurationMs === modifiedWorkIntervalPrevious.plannedDurationMs &&
            isEqual(modifiedWorkIntervalNew.weekdaysUtc, modifiedWorkIntervalPrevious.weekdaysUtc)
          ) {
            return false;
          }

          return true;
        };
        break;
      }

      case IPollWorkIntervalOperation.DELETE: {
        predicate = (): boolean => newWorkIntervals.every(interval => interval.id !== modifiedWorkIntervalId);
        break;
      }

      default: {
        throw new Error(`Invalid operation: ${operation}`);
      }
    }

    if (!predicate()) {
      throw new Error('Work intervals not updated');
    }

    return response;
  };

  try {
    yield* put(CleaningActions.workIntervalsIsLoading());
    const response: WorkIntervalListResponse = yield retry(
      POLL_MAX_RETRIES,
      POLL_INTERVAL,
      getWorkIntervalsAfterModification
    );
    yield* put(CleaningActions.getWorkIntervalsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningActions.getWorkIntervalsError({
        error,
      })
    );
  }
}

export function* cleaningSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(CleaningActions.GET_WORK_INTERVALS_REQUEST, getWorkIntervalsSaga);
  yield* takeLatest(CleaningActions.CREATE_WORK_INTERVAL_REQUEST, createWorkIntervalSaga);
  yield* takeLatest(CleaningActions.UPDATE_WORK_INTERVAL_REQUEST, updateWorkIntervalSaga);
  yield* takeLatest(CleaningActions.DELETE_WORK_INTERVAL_REQUEST, deleteWorkIntervalSaga);
  yield* takeLatest(CleaningActions.POLL_WORK_INTERVALS_REQUEST, pollWorkIntervals);
}
