import { TimePickerProps } from 'antd/lib/time-picker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { TimePicker } from 'lib/components/TimePicker/TimePicker';

type DurationPickerProps = TimePickerProps;

type HandleTimeChange = (time: Dayjs | null, onChange: (value: Dayjs) => void) => void;

const handleTimeChange: HandleTimeChange = (time, onChange) => {
  onChange(time || dayjs().startOf('day'));
};

const msToTime = (minutes: number | Dayjs): Dayjs => {
  if (typeof minutes === 'number') {
    return dayjs().startOf('day').add(minutes, 'milliseconds');
  }

  return minutes;
};

const DurationPicker = ({ value, onChange, ...rest }: DurationPickerProps): JSX.Element => {
  const transformedValue = useMemo(() => (value ? msToTime(value) : undefined), [value]);
  const memoizedHandleTimeChange = useCallback((time: any) => handleTimeChange(time, onChange as any), [onChange]);
  return (
    <TimePicker
      value={transformedValue}
      onChange={memoizedHandleTimeChange}
      inputReadOnly
      showNow={false}
      format="HH:mm"
      {...rest}
    />
  );
};

export default DurationPicker;
