import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getRoutineCleaningReportListRobotColumns } from '../columns/RoutineCleaningTaskReportList.colums';
import { StyledRoutineCleaningReportListRobot } from './RoutineCleaningTaskReportList.styles';
import { RobotRouteImagePreview } from './RobotRouteImagePreview';
import { Table } from 'lib/components/Table/Table';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { CleaningTaskReport } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { CleaningReportListActions } from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListActions';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import * as cleaningReportListSelectors from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListSelectors';

export const RoutineCleaningReportListRobot = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isUSusers = false;
  const [routeImageModalVisible, setRouteImageModalVisible] = useState<boolean>(false);
  const [cleaningReportSelected, setCleaningReportSelected] = useState<CleaningTaskReport>();

  const currentExportingId = useSelector(cleaningReportListSelectors.selectExportRobotDetailsExportingId);
  const routineCleaningTaskReports = useSelector(cleaningReportListSelectors.selectRoutineCleaningTaskReportData);
  const isLoading = useSelector(cleaningReportListSelectors.selectRoutineCleaningTaskReportLoading);

  const handleClickDownload = (cleaningTaskReport: CleaningTaskReport): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXPORT_ROBOT_DETAILS,
    });

    dispatch(
      CleaningReportListActions.requestExportRobotDetailsCleaningReportsRequest({
        id: cleaningTaskReport.id || '',
        timezone: DateTime.getBrowserTimeZone(),
        machineId: cleaningTaskReport.machine?.id || '',
        lang: Translations.getSupportedLanguageCode(i18n.language),
      })
    );
  };

  const handleOpenRouteImage = (cleaningTaskReport: CleaningTaskReport): void => {
    setRouteImageModalVisible(true);
    setCleaningReportSelected(cleaningTaskReport);
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT__OPEN_ROUTE_IMAGE,
    });
  };

  const tableColumns = getRoutineCleaningReportListRobotColumns({
    t,
    i18n,
    handleClickDownload,
    currentExportingId,
    handleOpenRouteImage,
    isUSusers,
  });

  return (
    <StyledRoutineCleaningReportListRobot>
      <Table
        columns={tableColumns}
        loading={isLoading}
        dataSource={routineCleaningTaskReports || []}
        rowKey="id"
        scroll={{ x: 1288 }}
        showScrollButtons={true}
      />
      {cleaningReportSelected && (
        <RobotRouteImagePreview
          cleaningTaskReport={cleaningReportSelected}
          onVisibilityChange={(value: boolean): void => {
            setRouteImageModalVisible(value);
            if (!value) {
              setCleaningReportSelected(undefined);
            }
          }}
          visible={routeImageModalVisible}
        />
      )}
    </StyledRoutineCleaningReportListRobot>
  );
};
