import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { injectable, inject } from 'tsyringe';
import {
  CleaningReportsExportRequest,
  CleaningReportsExportGetFile,
  CleaningReportsExportRobotDetailsRequest,
  RoutineClientExportRoutinesRequest,
} from './interfaces/CleaningReport.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputFilterCleaningReportsExportRequest,
  InputSortOptions,
  SupportedLanguage,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface CleaningReportClientRequestExportCleaningReportsOptions {
  filter: InputFilterCleaningReportsExportRequest;
  sortOptions?: InputSortOptions;
  timezone: string;
  lang: SupportedLanguage;
}

export interface CleaningReportClientGetExportCleaningReportsFileOptions {
  requestId: string;
}

export interface CleaningReportClientRequestRobotDetailsExportOptions {
  id: string;
  timezone: string;
  machineId: string;
  lang: SupportedLanguage;
}

export interface RoutineClientRequestExportRoutinesOptions {
  executionId: string;
  timezone: string;
  lang: SupportedLanguage;
}

@injectable()
export class CleaningReportClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public requestExportCleaningReports = async ({
    filter,
    sortOptions,
    timezone,
    lang,
  }: CleaningReportClientRequestExportCleaningReportsOptions): Promise<FetchResult<CleaningReportsExportRequest>> =>
    this.client.mutate({
      mutation: gql`
        mutation cleaningReportsExportRequest(
          $filter: InputFilterCleaningReportsExportRequest!
          $sortOptions: InputSortOptions
          $timezone: String!
          $lang: SupportedLanguage!
        ) {
          cleaningReportsExportRequest(filter: $filter, sortOptions: $sortOptions, timezone: $timezone, lang: $lang) {
            data {
              requestId
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        timezone,
        lang,
      },
    });

  public getExportCleaningReportsFile = async ({
    requestId,
  }: CleaningReportClientGetExportCleaningReportsFileOptions): Promise<
    ApolloQueryResult<CleaningReportsExportGetFile>
  > =>
    this.client.query({
      query: gql`
        query GetCleaningReportExportFile($requestId: String!) {
          cleaningReportsExportGetFile(requestId: $requestId) {
            data {
              presignedUrl
              objectKey
              status
            }
          }
        }
      `,
      variables: {
        requestId,
      },
    });

  public requestRobotDetailsReportsExport = async ({
    id,
    timezone,
    machineId,
    lang,
  }: CleaningReportClientRequestRobotDetailsExportOptions): Promise<
    FetchResult<CleaningReportsExportRobotDetailsRequest>
  > =>
    this.client.mutate({
      mutation: gql`
        mutation RobotDetailsReportsExportRequest(
          $id: String!
          $machineId: String!
          $timezone: String!
          $lang: SupportedLanguage!
        ) {
          robotDetailsReportsExportRequest(timezone: $timezone, id: $id, machineId: $machineId, lang: $lang) {
            data {
              requestId
            }
          }
        }
      `,
      variables: {
        id,
        timezone,
        machineId,
        lang,
      },
    });

  public requestExportRoutines = async ({
    executionId,
    timezone,
    lang,
  }: RoutineClientRequestExportRoutinesOptions): Promise<FetchResult<RoutineClientExportRoutinesRequest>> =>
    this.client.mutate({
      mutation: gql`
        mutation RoutineReportExportRequest($executionId: String!, $timezone: String!, $lang: SupportedLanguage!) {
          routineReportExportRequest(executionId: $executionId, timezone: $timezone, lang: $lang) {
            data {
              requestId
            }
          }
        }
      `,
      variables: {
        executionId,
        timezone,
        lang,
      },
    });
}
