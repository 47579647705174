import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RobotListActions } from '../../../../modules/machine-inventory/robot-list/state/robotListActions';
import { downloadLink } from 'app/utils/download-link/downloadLink';
import * as cleaningReportListSelectors from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListSelectors';
import * as machineListSelectors from 'app/modules/machine-inventory/machine-list/state/machineListSelectors';
import * as robotListSelectors from 'app/modules/machine-inventory/robot-list/state/robotListSelectors';
import { CleaningReportListActions } from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListActions';
import { MachineListActions } from 'app/modules/machine-inventory/machine-list/state/machineListActions';
import { selectRobotExportCtrPdfDownloadUrl } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { eventListActions } from 'app/modules/cleaning/cleaning-report-list/state/eventListSlice';

export interface AsyncJobHandlerProps {
  children: React.ReactNode;
}

export const AsyncJobHandler = ({ children }: AsyncJobHandlerProps): JSX.Element => {
  const dispatch = useDispatch();
  const cleaningReportsRobotDetailsDownloadUrl = useSelector(
    cleaningReportListSelectors.selectExportRobotDetailsDownloadUrl
  );
  const machinesDownloadUrl = useSelector(machineListSelectors.selectExportDownloadUrl);
  const robotsDownloadUrl = useSelector(robotListSelectors.selectExportDownloadUrl);
  const robotDetailsPanelCtrPdfDownloadUrl = useSelector(selectRobotExportCtrPdfDownloadUrl);
  const routinesDownloadUrl = useSelector(cleaningReportListSelectors.selectExportRoutinesDownloadUrl);
  const routeDownloadUrl = useSelector(cleaningReportListSelectors.selectExportRoutesDownloadUrl);

  useEffect(() => {
    if (cleaningReportsRobotDetailsDownloadUrl) {
      downloadLink({ fileUrl: cleaningReportsRobotDetailsDownloadUrl });
      dispatch(CleaningReportListActions.exportRobotDetailsCleaningReportsResetState());
    }
  }, [cleaningReportsRobotDetailsDownloadUrl, dispatch]);

  useEffect(() => {
    if (machinesDownloadUrl) {
      downloadLink({ fileUrl: machinesDownloadUrl });
      dispatch(MachineListActions.exportResetState());
    }
  }, [dispatch, machinesDownloadUrl]);

  useEffect(() => {
    if (robotsDownloadUrl) {
      downloadLink({ fileUrl: robotsDownloadUrl });
      dispatch(RobotListActions.exportResetState());
    }
  }, [dispatch, robotsDownloadUrl]);

  useEffect(() => {
    if (robotDetailsPanelCtrPdfDownloadUrl) {
      downloadLink({ fileUrl: robotDetailsPanelCtrPdfDownloadUrl });
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotExportCtrPdfResetState());
    }
  }, [dispatch, robotDetailsPanelCtrPdfDownloadUrl]);

  useEffect(() => {
    if (routinesDownloadUrl) {
      downloadLink({ fileUrl: routinesDownloadUrl });
      dispatch(eventListActions.routineExportResetState());
    }
  }, [routinesDownloadUrl, dispatch]);

  useEffect(() => {
    if (routeDownloadUrl) {
      downloadLink({ fileUrl: routeDownloadUrl });
      dispatch(CleaningReportListActions.exportRobotDetailsCleaningReportsResetState());
    }
  }, [routeDownloadUrl, dispatch]);

  return <>{children}</>;
};
