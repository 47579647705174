import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { Machine } from '../../../interfaces/Machine.types';
import { DateFormats } from '../../../interfaces/Robot.types';
import { selectPlannedAndActualData } from '../../state/machineDetailsSelectors';
import { InputPeriod } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { theme } from 'config/theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

const splitDataByDay = (data: any, selectedDates: Dayjs[]): void => {
  const result: any = [];
  const fixedDate = '1970-01-01'; // Fixed date to set the time to 00:00:00
  const nextFixedDate = '1970-01-02';
  data.map((item: any) => {
    if (!item.startAt || !item.endAt) {
      return item;
    }

    let start = new Date(item.startAt);
    const end = new Date(item.endAt);

    while (start < end) {
      const nextDay = new Date(start);
      nextDay.setHours(24, 0, 0, 0); // Move to the start of the next day

      const splitEnd = nextDay < end ? nextDay : end;

      // convert to current date for y

      const startTime = dayjs(start.toUTCString()).format('HH:mm:ss');
      const endTime = dayjs(splitEnd.toUTCString()).format('HH:mm:ss');
      const startDate = dayjs(start).format(DateFormats.DATE_IN_YEAR_MONTH_DAY);
      const isShow = selectedDates?.find(d => startDate === d.format(DateFormats.DATE_IN_YEAR_MONTH_DAY));
      if (isShow) {
        result.push({
          // Use the original date for the x-axis
          x: new Date(`${startDate}T00:00:00`),
          // Use the fixed date with original time for the y-axis
          y: [
            new Date(`${fixedDate}T${startTime}`),
            new Date(`${nextDay > end ? fixedDate : nextFixedDate}T${endTime}`),
          ],
        });
      }

      start = splitEnd;
    }
    return item;
  });
  return result;
};

interface PlannedAndActualForPeriodChartProps {
  id: string | undefined;
  machine: Machine;
  period?: InputPeriod;

  selectedDates: Dayjs[];
}

const PlannedAndActualForPeriodChart = ({ selectedDates }: PlannedAndActualForPeriodChartProps): JSX.Element => {
  const plannedAndActualData = useSelector(selectPlannedAndActualData);
  const plannedData = useMemo(
    () =>
      plannedAndActualData?.plannedCleaning?.map(item => ({
        ...item,
        startAt: item?.startAt,
        endAt: item?.endAt,
      })) || [],
    [plannedAndActualData?.plannedCleaning]
  );

  const actualData = useMemo(
    () =>
      plannedAndActualData?.actualCleaning?.map(item => ({
        ...item,
        startAt: item?.startAt,
        endAt: item?.endAt,
      })) || [],
    [plannedAndActualData?.actualCleaning]
  );

  const data = useMemo(
    () => ({
      datasets: [
        {
          label: 'Actual',
          data: splitDataByDay(actualData, selectedDates),
          backgroundColor: '#238C96',
          borderRadius: 2,
          borderSkipped: false,
          barThickness: 16,
          grouped: false,
        },
        {
          label: 'Planned',
          data: splitDataByDay(plannedData, selectedDates),
          backgroundColor: '#BDDDE0',
          borderRadius: 2,
          borderSkipped: false,
          barThickness: 24,
          grouped: false,
        },
      ],
    }),
    [actualData, plannedData, selectedDates]
  );

  return (
    <div className="machine-cleaning-data__machine-planned-and-actual-for-period-chart-wrapper">
      <Bar
        data={data}
        options={{
          interaction: {
            mode: 'x',
          },
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title(context: any): string {
                  return dayjs(context[0].raw.x).format(DateFormats.DATE_IN_WEEKDAY_DAY_MONTH_YEAR);
                },
                label(context: any): string {
                  const start = context.raw.y[0];
                  const end = context.raw.y[1];
                  const totalHours = Number.parseInt(((end - start) / (1000 * 60 * 60)).toString(), 10);
                  const totalMins = Number.parseInt((((end - start) % (1000 * 60 * 60)) / (1000 * 60)).toString(), 10);
                  return `${context.dataset.label}     ${totalHours}:${Number.parseInt(
                    totalMins.toString(),
                    10
                  )}h   ${dayjs(start).format('HH:mm a')} - ${dayjs(end).format('HH:mm a')}`;
                },
              },
              titleFont: {
                family: theme.fonts.captionNews,
                size: 11,
                weight: theme.fontWeights.captionSmall,
                lineHeight: theme.lineHeights.caption,
              },
              bodyFont: {
                size: 14,
              },
              backgroundColor: '#ffffff',
              bodyColor: '#000000',
              titleColor: 'rgba(0, 0, 0, 0.65)',
              cornerRadius: 0,
              padding: 12,
              borderColor: '#c2c2c2',
              borderWidth: 0.5,
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: DateFormats.DATE_IN_MONTH_DAY_SHORT,
                displayFormats: {
                  day: DateFormats.DATE_IN_MONTH_DAY_SHORT,
                },
              },
              min:
                selectedDates.length > 0
                  ? selectedDates[0].format(DateFormats.DATE_IN_YEAR_MONTH_DAY)
                  : '1970-01-02T00:00:00',
              max:
                selectedDates.length > 0
                  ? selectedDates[selectedDates.length - 1].format(DateFormats.DATE_IN_YEAR_MONTH_DAY)
                  : '1970-01-02T00:00:00',
              stacked: true,
              ticks: {
                stepSize: 1,
                font: {
                  size: 10,
                },
              },
              grid: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
              },
            },
            y: {
              type: 'time',
              time: {
                unit: 'hour',
                tooltipFormat: 'h:mm a',
                displayFormats: {
                  hour: 'h:mm a',
                },
              },
              min: '1970-01-01T00:00:00',
              max: '1970-01-02T00:00:00',
              reverse: true,
              ticks: {
                font: {
                  size: 10,
                },
                stepSize: 2, // Set step size to 1 hour
              },
              grid: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default PlannedAndActualForPeriodChart;
