import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledEditWorkIntervalModal = styled(Modal)`
  .edit-work-interval-modal__time-range-picker {
    height: 32px;
    width: 228px;
    transition: 0.2s width;

    &.ant-picker-focused {
      width: 228px;
    }

    .ant-picker-time-range-wrapper {
      min-width: 228px !important;
    }
  }

  .edit-work-interval-modal__days-of-week {
    .ant-checkbox-wrapper {
      span.ant-checkbox {
        display: none;
      }

      span:last-child {
        color: ${(props): string => props.theme.colors.black};
        ${(props): string => props.theme.fontStyles.p1.news};
        padding-right: 15px;
        padding-left: 15px;
        height: 32px;
        line-height: 32px;
        transition: 0.25s background-color;
      }

      &:hover {
        span:last-child {
          background-color: ${(props): string => props.theme.colors.brightGrey};
        }
      }

      &.ant-checkbox-wrapper-checked {
        span:last-child {
          background-color: ${(props): string => props.theme.colors.black};
          color: ${(props): string => props.theme.colors.white};
        }
      }
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.semanticError};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.semanticError};
  }

  .ant-select-selection-search-input {
    height: 44px !important;
  }

  .edit-work-interval-modal__dropdown {
    width: 228px;
    transition: 0.2s width;
    border: 1px solid ${(props): string => props.theme.colors.darkGrey};
  }
`;
