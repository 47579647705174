import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import { Empty, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { DateTime } from '../../../../../../../lib/utils/date-handling/DateTime';
import { CleaningReportListActions } from '../../../state/cleaningReportListActions';
import * as cleaningReportListSelectors from '../../../state/cleaningReportListSelectors';
import { RoutineDetailsDrawer } from '../../RoutineDetailsDrawer/RoutineDetailsDrawer';
import { eventListActions } from '../../../state/eventListSlice';
import { StyledCleaningReportListRobot } from './CleaningReportListRobot.styles';
import { getCleaningReportListRobotColumns } from './columns/CleaningReportListRobot.columns';
import { RobotRouteImagePreview } from './RobotRouteImagePreview';
import {
  SortOrders,
  MachineClassification,
  CleaningTaskReport,
  TaskCompletion,
  InputSortOptions,
  InputFilterSingleRoutesAndRoutinesList,
  SingleRoutesAndRoutineData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useGetMachineIdsFilter } from 'app/modules/cleaning/hooks/useGetMachineIdsFilter';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { Table } from 'lib/components/Table/Table';
import { InfiniteScrollConstants } from 'config/constants';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

export const CleaningReportListRobot = ({ activeTabKey }: { activeTabKey: MachineClassification }): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [routeImageModalVisible, setRouteImageModalVisible] = useState<boolean>(false);
  const [cleaningReportSelected, setCleaningReportSelected] = useState<SingleRoutesAndRoutineData>();
  const [selectedRowKey, setSelectedRowKey] = useState<string>('');
  const [routineSelected, setRoutineSelected] = useState<SingleRoutesAndRoutineData>();

  const data = useSelector(cleaningReportListSelectors.selectRobotData);

  const nextPaginationToken = useSelector(cleaningReportListSelectors.selectRobotNextPaginationToken);
  const isLoading = !!useSelector(cleaningReportListSelectors.selectRobotIsLoading);
  const isLoadingMoreData = useSelector(cleaningReportListSelectors.selectRobotIsLoadingMoreData);
  const sortField = useSelector(cleaningReportListSelectors.selectRobotSortField) || 'startedAt';
  const sortOrder = useSelector(cleaningReportListSelectors.selectRobotSortOrder) || SortOrders.Asc;

  const availableFilters = useSelector(cleaningReportListSelectors.selectFilters);
  const areFiltersLoading = useSelector(cleaningReportListSelectors.selectAreFiltersLoading);
  const activeSiteFilter = useSelector(cleaningReportListSelectors.selectActiveSiteFilter);
  const activeMachinesFilter = useSelector(cleaningReportListSelectors.selectActiveMachinesFilter);
  const startDate = useSelector(cleaningReportListSelectors.selectPeriodStartDate);
  const endDate = useSelector(cleaningReportListSelectors.selectPeriodEndDate);
  const isUSusers = false;

  const currentExportingId = useSelector(cleaningReportListSelectors.selectExportRoutinesExportingId);
  const currentExportingRouteId = useSelector(cleaningReportListSelectors.selectExportRoutesExportingId);

  const machineIdsFilter = useGetMachineIdsFilter(
    activeMachinesFilter,
    activeSiteFilter,
    availableFilters,
    activeTabKey
  );
  const taskCompletionFilter = useSelector(cleaningReportListSelectors.selectActiveTaskCompletionFilter);

  const requestParams = (
    startAt: string,
    endAt: string
  ): {
    filter?: InputFilterSingleRoutesAndRoutinesList;
    sortOptions?: InputSortOptions;
  } => ({
    filter: {
      machineIds: machineIdsFilter,
      period: {
        startAt,
        endAt,
      },
      taskCompletions: taskCompletionFilter as TaskCompletion[],
    },
    sortOptions: {
      field: sortField,
      order: sortOrder,
    },
  });

  useEffect(() => {
    if (isNil(startDate) || isNil(endDate)) {
      return;
    }

    // Skip duplicate notifications loading on initial state or filters are loading
    if (
      (typeof availableFilters?.machines === 'undefined' &&
        typeof activeMachinesFilter === 'undefined' &&
        typeof taskCompletionFilter === 'undefined') ||
      areFiltersLoading
    ) {
      return;
    }

    dispatch(
      CleaningReportListActions.getCleaningReportListRobotRequest({
        ...requestParams(startDate, endDate),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_EVENT_ITEMS,
          paginationToken: '',
        },
        search: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    machineIdsFilter,
    startDate,
    endDate,
    sortField,
    sortOrder,
    availableFilters?.machines,
    activeMachinesFilter,
    areFiltersLoading,
    taskCompletionFilter,
  ]);

  const loadMore = (): void => {
    if (isNil(startDate) || isNil(endDate)) {
      return;
    }
    dispatch(
      CleaningReportListActions.getCleaningReportListRobotMoreDataRequest({
        ...requestParams(startDate, endDate),
        search: '',
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_EVENT_ITEMS,
          paginationToken: nextPaginationToken,
        },
      })
    );
  };

  useEffect(
    () => (): void => {
      dispatch(CleaningReportListActions.robotResetState());
      dispatch(DrawersActions.hideMachineDetailsDrawer());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  const handleClickMachineName = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT__OPEN_MACHINE_DETAILS,
    });
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: cleaningTaskReport?.machineId || '' }));
  };

  const handleClickSiteName = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT__OPEN_SITE_DETAILS,
    });

    if (cleaningTaskReport.machine?.site?.id) {
      dispatch(DrawersActions.showSiteDetailsDrawer({ siteId: cleaningTaskReport.machine.site.id }));
    }
  };

  const handleClickDownloadRoutine = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXPORT_ROUTINE,
    });

    if (cleaningTaskReport.executionId) {
      dispatch(
        eventListActions.routineExportRequest({
          executionId: cleaningTaskReport.executionId,
          timezone: DateTime.getBrowserTimeZone(),
          lang: Translations.getSupportedLanguageCode(i18n.language),
        })
      );
    }
  };

  const handleClickDownloadRoute = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXPORT_ROUTE,
    });

    if (cleaningTaskReport.CTRId) {
      dispatch(
        CleaningReportListActions.requestExportRobotDetailsCleaningReportsRequest({
          id: cleaningTaskReport.CTRId || '',
          timezone: DateTime.getBrowserTimeZone(),
          machineId: cleaningTaskReport.machineId || '',
          lang: Translations.getSupportedLanguageCode(i18n.language),
        })
      );
    }
  };

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CleaningTaskReport> | SorterResult<CleaningTaskReport>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    const sorterOrder = sorter.order === 'descend' ? SortOrders.Desc : SortOrders.Asc;
    // Handle for field as array like: ['machine', 'name'] => machine.name
    const sorterField = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;

    dispatch(CleaningReportListActions.robotSetActiveSortField(sorterField as Optional<string>));
    dispatch(CleaningReportListActions.robotSetActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const handleOpenRouteImage = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    setRouteImageModalVisible(true);
    setCleaningReportSelected(cleaningTaskReport);
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT__OPEN_ROUTE_IMAGE,
    });
  };

  const handleOpenRoutineDetails = (cleaningTaskReport: SingleRoutesAndRoutineData): void => {
    dispatch(eventListActions.showRoutineDetailsDrawer());
    setRoutineSelected(cleaningTaskReport);
  };

  const isCV50 = (cleaningTaskReport: SingleRoutesAndRoutineData): boolean =>
    RobotUtils.getRobotType(cleaningTaskReport.machine?.type?.name) === 'CV50';

  const tableColumns = getCleaningReportListRobotColumns({
    t,
    i18n,
    handleClickMachineName,
    handleClickSiteName,
    handleClickDownloadRoutine,
    currentExportingId,
    handleOpenRouteImage,
    isUSusers,
    handleOpenRoutineDetails,
    handleClickDownloadRoute,
    currentExportingRouteId,
    isCV50,
  });

  const rowClassName = (record: SingleRoutesAndRoutineData): string =>
    `${record.machineId}-${record.executionId || record.CTRId}` === selectedRowKey ? 'table__selected-row' : '';

  return (
    <StyledCleaningReportListRobot className="cleaning-report-list-robot">
      <Table
        dataSource={data || []}
        loading={isLoading}
        className="cleaning-report-list-robot__table"
        columns={tableColumns}
        rowKey={(record: SingleRoutesAndRoutineData): string =>
          `${record.machineId}-${record.executionId || record.CTRId}`
        }
        onChange={onTableChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        scroll={{ x: 1288 }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('cleaningReportList.noEventsFound')} />,
        }}
        onRow={(record: SingleRoutesAndRoutineData): { onClick(): void } => ({
          onClick: (): void => {
            setSelectedRowKey(`${record.machineId}-${record.executionId || record.CTRId}`);
          },
        })}
        rowClassName={rowClassName}
        infiniteScroll={{
          height: 810,
          id: 'cleaning-report-list-robot-infinite-scroll',
          next: loadMore,
          nextPaginationToken,
          isLoadingMoreData,
        }}
        showScrollButtons={true}
      />
      {cleaningReportSelected && (
        <RobotRouteImagePreview
          cleaningTaskReport={cleaningReportSelected}
          onVisibilityChange={(value: boolean): void => setRouteImageModalVisible(value)}
          visible={routeImageModalVisible}
        />
      )}
      {routineSelected && (
        <RoutineDetailsDrawer executionId={routineSelected.executionId} routineInfo={routineSelected} />
      )}
    </StyledCleaningReportListRobot>
  );
};
