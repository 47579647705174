import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { TFunction, useTranslation } from 'react-i18next';
import { SvgIcon } from '../../../../../../lib/components/SvgIcon/SvgIcon';
import { DateTime } from '../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../lib/utils/date-handling/DateTime.types';
import { Machine } from '../../../interfaces/Machine.types';
import { StyledMachineDetailsPanelStatus } from './MachineDetailsPanelStatus.styles';
import { MachineStatusIndicator } from 'app/modules/machine-inventory/components/MachineStatusIndicator/MachineStatusIndicator';
import { MachineBatteryDischargeStatusIndicator } from 'app/modules/machine-inventory/components/MachineStatusIndicator/MachineStatusBatteryDeepCharge/MachineStatusBatteryDeepCharge';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';

const getLocationContent = (machine: Machine, t: TFunction<'translation', undefined>): string => {
  const isNonIoT = !machine?.isIoTDevice;
  const isNonIoTWithSite = isNonIoT && !!machine?.site;
  let locationText: string;

  if (isNonIoTWithSite) {
    locationText = t('machineDetails.generalInfo.nonIoTWithSiteLocation');
  } else if (isNonIoT) {
    locationText = t('common.NA');
  } else {
    locationText = machine.location?.address ?? t('common.NA');
  }
  return locationText;
};

export interface MachineDetailsPanelStatusProps {
  machine: Machine;
  className?: string;
}

export const MachineDetailsPanelStatus = ({
  machine,
  className,
}: MachineDetailsPanelStatusProps): React.JSX.Element => {
  const { t, i18n } = useTranslation();
  const isNonIoT = !machine?.isIoTDevice;
  const locationContent: string = getLocationContent(machine, t);

  let lastActivityAt: string;
  if (machine?.lastActivityAt && !isNonIoT) {
    lastActivityAt = `${DateTime.formatDateByLocale(
      i18n.language,
      machine.lastActivityAt,
      DATE_FORMAT_PATTERN.DATE
    )} ${DateTime.formatDateByLocale(i18n.language, machine.lastActivityAt, DATE_FORMAT_PATTERN.TIME)}`;
  } else {
    lastActivityAt = t('common.NA');
  }

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);

  const isBatteryDeepDischargeEnabled = features.BATTERY_DEEP_DISCHARGE;

  return (
    <StyledMachineDetailsPanelStatus className={classnames('machine-details-panel-status', className)}>
      <div className="machine-details-panel-status__heading">
        <h3 tabIndex={0} className="machine-details-panel-status__title">
          {t('machineDetailsPanel.machineStatus')}
        </h3>
        <div className="machine-details-panel-status__info-icon">
          <SvgIcon name="info" />
        </div>
      </div>
      <div className="machine-details-panel-status__details">
        {isBatteryDeepDischargeEnabled && (
          <>
            <MachineBatteryDischargeStatusIndicator
              status={machine.batteryDischargeStatus}
              showIcon
              showText
              showDescription
              size="small"
            />

            <hr className="machine-details-panel-status__separate-line" />
          </>
        )}

        <MachineStatusIndicator lastActivityAt={machine.lastActivityAt} showText size="small" />

        <hr className="machine-details-panel-status__separate-line" />

        <div className="machine-details-panel-status__status-item">
          <div className="machine-details-panel-status__status-item-label">{t('machineDetailsPanel.lastDataAt')}</div>
          <div className="machine-details-panel-status__status-item-value">{lastActivityAt}</div>
        </div>

        <div className="machine-details-panel-status__status-item">
          <div className="machine-details-panel-status__status-item-label">
            {t('machineDetailsPanel.lastKnownAddress')}
          </div>
          <div className="machine-details-panel-status__status-item-value">{locationContent}</div>
        </div>

        <div className="machine-details-panel-status__accuracy">
          {!isNonIoT && (
            <>
              {machine.location
                ? t('machineDetails.generalInfo.accuracy', { accuracy: machine.location?.accuracy })
                : ''}
            </>
          )}
        </div>
      </div>
    </StyledMachineDetailsPanelStatus>
  );
};
