import { Optional } from '../../../../lib/types/Optional';
import { MachineListColumn } from '../machine-list/components/MachineList/columns/MachineList.columns';
import { MachineClassification, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export enum MachineListPresetId {
  ALL_DATA = 'ALL_DATA',
  INVENTORY_MACHINES = 'INVENTORY_MACHINES',
  CLEANING_ANALYSIS = 'CLEANING_ANALYSIS',
}

export interface MachineListFilters {
  classifications: Optional<MachineClassification[]>;
  machineTypes: Optional<string[]>;
  sites: Optional<string[]>;
  machineStatus: Optional<string>;
  materialNumbers: Optional<string[]>;
  iotClassification: string | undefined;
  financeType: string[] | undefined;
  serviceAgreementType: string[] | undefined;
}

export interface MachineListPreset {
  id: MachineListPresetId;
  columnCustomizationConfig: MachineListColumn[];
  filter: MachineListFilters;
  sorting: {
    field: string;
    order: SortOrders;
  };
}

export const PRESET_EDITED = 'PRESET_EDITED';

export const MachineListPresets: {
  [key in MachineListPresetId]: MachineListPreset;
} = {
  [MachineListPresetId.ALL_DATA]: {
    id: MachineListPresetId.ALL_DATA,
    // NOTE: Non-customizable columns (like MachineListColumn.CATEGORY) need to
    // be omitted here because they are not part of the columnCustomizationConfig.
    // IF not omitted this preset while not be able to be detected as active
    columnCustomizationConfig: [
      // MachineListColumn.Category, <== NEEDS TO BE EXCLUDED
      MachineListColumn.MachineName,
      MachineListColumn.MachineType,
      MachineListColumn.InventoryNumber,
      MachineListColumn.SiteName,
      MachineListColumn.PlannedOperatingHours,
      MachineListColumn.OperatingHours,
      MachineListColumn.TotalOperatingHours,
      MachineListColumn.Deviation,
      MachineListColumn.SiteManagers,
      MachineListColumn.ActivationDate,
      MachineListColumn.DataStatus,
      MachineListColumn.LastConnection,
      MachineListColumn.MaterialNumber,
      MachineListColumn.SerialNumber,
      MachineListColumn.Classification,
      MachineListColumn.FinanceContractType,
      MachineListColumn.FinanceContractNumber,
      MachineListColumn.ServiceAgreementType,
      MachineListColumn.CleaningDays,
      MachineListColumn.AvgWorkingTimePerDay,
      MachineListColumn.BatteryDischargeStatus,
      MachineListColumn.PlannedShifts,
      MachineListColumn.FullfillmentInPercentage,
      MachineListColumn.SumMissedCleaningJobs,
      MachineListColumn.GeofenceStatus,
      MachineListColumn.Accuracy,
      MachineListColumn.Address,
    ],
    filter: {
      classifications: [MachineClassification.Gcd, MachineClassification.Robot],
      machineTypes: [],
      sites: [],
      machineStatus: '',
      materialNumbers: [],
      iotClassification: '',
      financeType: undefined,
      serviceAgreementType: undefined,
    },
    sorting: {
      field: 'machineTypeName',
      order: SortOrders.Asc,
    },
  },
  [MachineListPresetId.INVENTORY_MACHINES]: {
    id: MachineListPresetId.INVENTORY_MACHINES,
    columnCustomizationConfig: [
      // MachineListColumn.Category, <== NEEDS TO BE EXCLUDED
      MachineListColumn.MachineType,
      MachineListColumn.SiteName,
      MachineListColumn.ActivationDate,
      MachineListColumn.SerialNumber,
      MachineListColumn.TotalOperatingHours,
      MachineListColumn.InventoryNumber,
      MachineListColumn.Classification,
    ],
    filter: {
      classifications: [MachineClassification.Gcd, MachineClassification.Robot],
      machineTypes: [],
      sites: [],
      machineStatus: '',
      materialNumbers: [],
      iotClassification: '',
      financeType: undefined,
      serviceAgreementType: undefined,
    },
    sorting: {
      field: 'machineTypeName',
      order: SortOrders.Asc,
    },
  },
  [MachineListPresetId.CLEANING_ANALYSIS]: {
    id: MachineListPresetId.CLEANING_ANALYSIS,
    // NOTE: Non-customizable columns (like MachineListColumn.CATEGORY) need to
    // be omitted here because they are not part of the columnCustomizationConfig.
    // IF not omitted this preset while not be able to be detected as active
    columnCustomizationConfig: [
      // MachineListColumn.Category, <== NEEDS TO BE EXCLUDED
      MachineListColumn.MachineName,
      MachineListColumn.MachineType,
      MachineListColumn.InventoryNumber,
      MachineListColumn.PlannedOperatingHours,
      MachineListColumn.OperatingHours,
      MachineListColumn.TotalOperatingHours,
      MachineListColumn.Deviation,
      MachineListColumn.CleaningDays,
      MachineListColumn.AvgWorkingTimePerDay,
      MachineListColumn.PlannedShifts,
      MachineListColumn.FullfillmentInPercentage,
      MachineListColumn.SumMissedCleaningJobs,
      MachineListColumn.SiteName,
      MachineListColumn.SiteManagers,
    ],
    filter: {
      classifications: [MachineClassification.Gcd, MachineClassification.Robot],
      machineTypes: [],
      sites: [],
      machineStatus: '',
      materialNumbers: [],
      iotClassification: '',
      financeType: undefined,
      serviceAgreementType: undefined,
    },
    sorting: {
      field: 'machineTypeName',
      order: SortOrders.Asc,
    },
  },
};
