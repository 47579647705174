import { clone, isEmpty, isNil, isObject, size } from 'lodash-es';
import { orderMap } from '../../modules/cleaning/widgets/robot-dashboard/utils/constants';
import {
  MachineConnectionStatus,
  RobotStatus,
  Site,
  State,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotStatusDisplayName, Telemetry } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Optional } from 'lib/types/Optional';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SiteData } from 'app/modules/site-management/interfaces/Site.types';
import {
  MachineSiteChangedEvent,
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
  MachineUpdateRoutine,
  SubscriptionMachineEvent,
  MachineCleaningRoutineStatus,
} from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';
import { OpenSearch } from 'config/constants';

interface CleaningDataInput {
  filter: { period: { startAt: string; endAt: string }; machineIds: string[] };
  paginationOptions: { limit: number };
}

export class RobotUtils {
  public static getKeyByValue(value: string, enums: { [key: string]: string }): string {
    const indexOfS = Object.values(enums).indexOf(value);

    const key = Object.keys(enums)[indexOfS];

    return key;
  }

  public static getStateData(name: Telemetry, robot: Machine): Optional<State> {
    switch (name) {
      case Telemetry.BatteryChargeLevel:
        return robot.states
          ? robot.states.find((state: State) => state.stateName === Telemetry.BatteryChargeLevel)
          : null;

      case Telemetry.FreshWaterLevel:
        return robot.states ? robot.states.find((state: State) => state.stateName === Telemetry.FreshWaterLevel) : null;

      case Telemetry.RepeatProgress:
        return robot.states ? robot.states.find((state: State) => state.stateName === Telemetry.RepeatProgress) : null;

      case Telemetry.ScheduledTasks:
        return robot.states ? robot.states.find((state: State) => state.stateName === Telemetry.ScheduledTasks) : null;

      case Telemetry.BatteryData:
        return robot.states ? robot.states.find((state: State) => state.stateName === Telemetry.BatteryData) : null;

      case Telemetry.Charging:
        return robot.states ? robot.states.find((state: State) => state.stateName === Telemetry.Charging) : null;

      default:
        return null;
    }
  }

  public static getRobotListSorted(robots: Optional<Machine[]>): Machine[] {
    if (robots) {
      const sortedRobots: Machine[] = clone(robots);

      return sortedRobots.sort((robotA, robotB) => {
        const aIndex = orderMap[robotA.robotStatus as keyof typeof orderMap];
        const bIndex = orderMap[robotB.robotStatus as keyof typeof orderMap];

        // check for null values and move them to the end of the list
        if (aIndex === undefined && bIndex === undefined) {
          return 0;
        }

        if (aIndex === undefined) {
          return 1;
        }

        if (bIndex === undefined) {
          return -1;
        }

        if (
          (robotA.connectionStatus === MachineConnectionStatus.Offline ||
            robotA.connectionStatus === MachineConnectionStatus.Unknown) &&
          robotB.connectionStatus !== MachineConnectionStatus.Offline
        ) {
          return 1;
        }

        if (
          robotA.connectionStatus !== MachineConnectionStatus.Offline &&
          (robotB.connectionStatus === MachineConnectionStatus.Offline ||
            robotB.connectionStatus === MachineConnectionStatus.Unknown)
        ) {
          return -1;
        }

        return aIndex - bIndex;
      });
    }

    return [];
  }

  public static getRobotsGroupedByStatus(robots: Optional<Machine[]>): {
    [key in RobotStatusDisplayName]: Machine[];
  } {
    const robotsGroupedByStatus: {
      [key in RobotStatusDisplayName]: Machine[];
    } = {
      [RobotStatusDisplayName.Cleaning]: [],
      [RobotStatusDisplayName.Docking]: [],
      [RobotStatusDisplayName.Standby]: [],
      [RobotStatusDisplayName.Offline]: [],
      [RobotStatusDisplayName.Exploration]: [],
      [RobotStatusDisplayName.NA]: [],
    };

    if (!robots) {
      return robotsGroupedByStatus;
    }

    robots.forEach(robot => {
      if (robot.connectionStatus === MachineConnectionStatus.Online) {
        switch (robot.robotStatus) {
          case RobotStatus.Autonomous:
            robotsGroupedByStatus[RobotStatusDisplayName.Cleaning].push(robot);
            break;
          case RobotStatus.Docking:
            robotsGroupedByStatus[RobotStatusDisplayName.Docking].push(robot);
            break;

          case RobotStatus.Charging:
          case RobotStatus.Idle:
          case RobotStatus.ManualCleaning:
            robotsGroupedByStatus[RobotStatusDisplayName.Standby].push(robot);
            break;
          case RobotStatus.Exploration:
            robotsGroupedByStatus[RobotStatusDisplayName.Exploration].push(robot);
            break;
          default:
            robotsGroupedByStatus[RobotStatusDisplayName.NA].push(robot);
            break;
        }
      } else {
        robotsGroupedByStatus[RobotStatusDisplayName.Offline].push(robot);
      }
    });

    return robotsGroupedByStatus;
  }

  public static getRobotStatusDisplayName(
    robotStatus: Optional<RobotStatus>,
    connectionStatus: MachineConnectionStatus
  ): string {
    if (connectionStatus === MachineConnectionStatus.Online) {
      switch (robotStatus) {
        case RobotStatus.Autonomous:
          return RobotUtils.getKeyByValue(RobotStatusDisplayName.Cleaning, RobotStatusDisplayName);

        case RobotStatus.Idle:
        case RobotStatus.ManualCleaning:
        case RobotStatus.Charging:
          return RobotUtils.getKeyByValue(RobotStatusDisplayName.Standby, RobotStatusDisplayName);

        case RobotStatus.Docking:
          return RobotUtils.getKeyByValue(RobotStatusDisplayName.Docking, RobotStatusDisplayName);

        case RobotStatus.Exploration:
          return RobotUtils.getKeyByValue(RobotStatusDisplayName.Exploration, RobotStatusDisplayName);

        default:
          return RobotUtils.getKeyByValue(RobotStatusDisplayName.NA, RobotStatusDisplayName);
      }
    }

    return RobotUtils.getKeyByValue(RobotStatusDisplayName.Offline, RobotStatusDisplayName);
  }

  public static getRobotStatusesFilter(robotStatusesFilter: string[]): {
    robotStatuses: RobotStatus[];
    connectionStatuses: MachineConnectionStatus[];
  } {
    const robotStatuses: RobotStatus[] = [];
    const connectionStatuses: MachineConnectionStatus[] = [];

    if (robotStatusesFilter) {
      robotStatusesFilter.forEach(robotStatusFilter => {
        switch (robotStatusFilter) {
          case RobotStatusDisplayName.Cleaning:
            robotStatuses.push(RobotStatus.Autonomous);
            connectionStatuses.push(MachineConnectionStatus.Online);
            break;
          case RobotStatusDisplayName.Standby:
            robotStatuses.push(RobotStatus.Idle);
            robotStatuses.push(RobotStatus.Charging);
            robotStatuses.push(RobotStatus.ManualCleaning);
            connectionStatuses.push(MachineConnectionStatus.Online);
            break;
          case RobotStatusDisplayName.Docking:
            robotStatuses.push(RobotStatus.Docking);
            connectionStatuses.push(MachineConnectionStatus.Online);
            break;
          case RobotStatusDisplayName.Exploration:
            robotStatuses.push(RobotStatus.Exploration);
            connectionStatuses.push(MachineConnectionStatus.Online);
            break;
          case RobotStatusDisplayName.Offline:
            connectionStatuses.push(MachineConnectionStatus.Offline);
            connectionStatuses.push(MachineConnectionStatus.Unknown);
            break;
          case RobotStatusDisplayName.NA:
            connectionStatuses.push(MachineConnectionStatus.Online);
            break;
          default:
            break;
        }
      });
    }

    return {
      robotStatuses,
      connectionStatuses,
    };
  }

  public static getRobotIndexInList(robots: Optional<Machine[]>, robotId: Optional<string>): number {
    if (!isNil(robots) && !isEmpty(robots) && !isNil(robotId)) {
      return robots.findIndex(robot => robot.id === robotId);
    }

    return -1;
  }

  public static getRobotAndSiteIndexInRobotBySiteList(
    sites: Optional<SiteData[]>,
    robotId: Optional<string>
  ): {
    siteIndex: number;
    robotIndex: number;
  } {
    let robotIndex = -1;
    let siteIndex = -1;

    if (!isNil(sites) && !isEmpty(sites) && !isNil(robotId)) {
      siteIndex = sites.findIndex(site => {
        robotIndex = site.machines.data?.findIndex(robot => robot.id === robotId);
        return robotIndex !== -1;
      });

      return {
        siteIndex,
        robotIndex,
      };
    }

    return {
      siteIndex,
      robotIndex,
    };
  }

  public static getMachineUpdatedData(encodedUpdatedTelemetry: Optional<string>): Optional<Record<string, string>> {
    if (!isNil(encodedUpdatedTelemetry)) {
      const decodedUpdatedTelemetry = atob(encodedUpdatedTelemetry);
      const parsedUpdatedTelemetry = JSON.parse(decodedUpdatedTelemetry);

      return parsedUpdatedTelemetry;
    }

    return null;
  }

  public static getRobotTelemetryUpdated(
    updatedTelemetry: Optional<Record<string, string>>,
    robotStates: Optional<State[]>,
    robotId: string
  ): Optional<State[]> {
    if (!isNil(updatedTelemetry) && isObject(updatedTelemetry) && size(updatedTelemetry) > 0 && !isNil(robotStates)) {
      const updatedTelemetryName = Object.keys(updatedTelemetry)[0];
      const stateValueJSON = JSON.stringify(updatedTelemetry[updatedTelemetryName]);
      const foundRobotAlreadyHasState = robotStates.find(robotState => robotState.stateName === updatedTelemetryName);

      if (!Object.values(Telemetry).includes(updatedTelemetryName as Telemetry)) {
        return robotStates;
      }

      if (!updatedTelemetryName) {
        return robotStates;
      }

      if (!foundRobotAlreadyHasState) {
        return [
          ...robotStates,
          {
            stateName: updatedTelemetryName,
            stateValue: stateValueJSON,
            machineId: robotId,
          },
        ];
      }

      return robotStates.map(robotState =>
        robotState.stateName === updatedTelemetryName ? { ...robotState, stateValue: stateValueJSON } : robotState
      );
    }

    return robotStates;
  }

  public static getRobotSiteChangedUpdated(
    decodedUpdatedSiteChanged: Optional<Record<string, string>>,
    robot: Machine
  ): Machine {
    if (!isNil(decodedUpdatedSiteChanged) && isObject(decodedUpdatedSiteChanged)) {
      const siteChangedEvent = decodedUpdatedSiteChanged.event;

      switch (siteChangedEvent) {
        case MachineSiteChangedEvent.MACHINE_ASSIGNED:
          return {
            ...robot,
            site: {
              ...robot.site,
              id: decodedUpdatedSiteChanged.siteId,
              name: decodedUpdatedSiteChanged.siteName,
            } as unknown as Site,
          };
        case MachineSiteChangedEvent.MACHINE_UNASSIGNED:
          return {
            ...robot,
            site: null,
          };
        default:
          break;
      }
    }

    return robot;
  }

  public static getCleaningDataInput = (startAt: string, endAt: string, robotIds: string[]): CleaningDataInput => ({
    filter: {
      period: {
        startAt,
        endAt,
      },
      machineIds: robotIds,
    },
    paginationOptions: { limit: OpenSearch.MAX_RESULT_WINDOW },
  });

  public static getRobotPropertiesUpdated = (
    robot: Machine,
    updatedData: MachineUpdateConnectionStatus | MachineUpdateRobotStatus | MachineUpdateTelemetry
  ): Machine => {
    if (updatedData.event === SubscriptionMachineEvent.TELEMETRY_CHANGED) {
      const updatedTelemetry = RobotUtils.getRobotTelemetryUpdated(
        updatedData.data,
        robot.states,
        updatedData.machineId
      );

      return {
        ...robot,
        states: updatedTelemetry,
      };
    }

    return {
      ...robot,
      ...updatedData.data,
    };
  };

  public static getPercentageData = ({ data, total }: { data: Optional<number>; total: Optional<number> }): number => {
    if (isNil(total) || isNil(data) || total === 0) {
      return 0;
    }

    return Math.min(Math.max(Math.round((data / total) * 100), 0), 100);
  };

  public static getRobotRoutineUpdated = (robot: Machine, updatedRoutine: Optional<string>): Machine => {
    if (!isNil(updatedRoutine)) {
      const decodeMachineRoutineUpdated = atob(updatedRoutine);
      const parseMachineRoutineUpdated = JSON.parse(decodeMachineRoutineUpdated) as MachineUpdateRoutine;
      const lastRoutine = robot.latestRoutine?.data;
      const status = parseMachineRoutineUpdated.status;

      switch (status) {
        case MachineCleaningRoutineStatus.STARTED: {
          const lastRoutineUpdated = {
            data: {
              currentRepeat: parseMachineRoutineUpdated.currentRepeat,
              customerId: robot.customerId || '',
              executionId: parseMachineRoutineUpdated.executionId,
              machineId: robot.id,
              name: parseMachineRoutineUpdated.routineName,
              numberOfRepeats: parseMachineRoutineUpdated.numberOfRepeats,
            },
          };
          return {
            ...robot,
            latestRoutine: lastRoutineUpdated,
          };
        }
        case MachineCleaningRoutineStatus.IN_PROGRESS: {
          if (lastRoutine?.executionId === parseMachineRoutineUpdated.executionId) {
            return {
              ...robot,
              latestRoutine: {
                ...robot.latestRoutine,
                data: {
                  ...lastRoutine,
                  currentRepeat: parseMachineRoutineUpdated.currentRepeat,
                  numberOfRepeats: parseMachineRoutineUpdated.numberOfRepeats,
                  name: parseMachineRoutineUpdated.routineName,
                },
              },
            };
          }
          break;
        }
        case MachineCleaningRoutineStatus.ENDED:
          return {
            ...robot,
            latestRoutine: null,
          };
        default:
          break;
      }
      return robot;
    }
    return robot;
  };

  public static getRobotType = (robotTypeName?: string | null): string => {
    if (
      robotTypeName?.toLocaleUpperCase().includes('KIRA CV 50') ||
      robotTypeName?.toLocaleUpperCase().includes('KIRA CV50')
    ) {
      return 'CV50';
    }
    if (
      robotTypeName?.toLocaleUpperCase().includes('KIRA B 50') ||
      robotTypeName?.toLocaleUpperCase().includes('KIRA B50')
    ) {
      return 'B50';
    }

    return 'B50';
  };
}
