import styled from 'styled-components';

export const StyledRoutineCleaningReportListRobot = styled.div`
  position: relative;

  .route-list__battery-wrapper {
    i {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .routine-list__column-title {
    div {
      ${(props): string => props.theme.fontStyles.redesign.base.strong};
      font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    }
  }

  .ant-table-container {
    .route-list__table_export-icon {
      path {
        fill: ${(props): string => props.theme.colors.black};
      }
      svg {
        width: 24px;
        height: 24px;
        margin-bottom: -2px;
      }
    }

    .route-list__image-route-icon {
      cursor: pointer;

      .device-map-icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      .image-icon {
        svg {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }

    table {
      .ant-table-tbody {
        tr > td.route-list__task-column {
          padding: 4px ${(props): string => props.theme.spacing.sm};
        }
        tr > td.route-list__image-icon-column {
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        tr > td {
          .route-list__status-pill {
            ${(props): string => props.theme.fontStyles.redesign.base.normal};
            font-weight: ${(props): number => props.theme.fontWeights.redesign.base};
          }
        }
      }
    }
  }

  .route-list__preview-route-image {
    display: none;
  }
`;
