import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { CleaningReportListAvailableFilters } from '../../interfaces/CleaningReport.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';
import { MachineWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import {
  CleaningReportSubscription,
  CleaningTaskReport,
  FilterLastActivityAt,
  MachineClassification,
  RobotCleaningConsumption,
  RobotDashboardKpIsData,
  Routine,
  SingleRoutesAndRoutineData,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const selectTotalsData = (state: IState): Optional<MachineWithCleaningReport[]> =>
  state.modules.cleaning['cleaning-report-list'].totals.data;

export const selectTotalsTotalCount = (state: IState): Optional<number> =>
  state.modules.cleaning['cleaning-report-list'].totals.totalCount;

export const selectTotalsIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].totals.isLoading;

export const selectTotalsPaginationTokens = (state: IState): PaginationTokens =>
  state.modules.cleaning['cleaning-report-list'].totals.paginationTokens;

export const selectTotalsPage = (state: IState): number => state.modules.cleaning['cleaning-report-list'].totals.page;

export const selectTotalsPageSize = (state: IState): number =>
  state.modules.cleaning['cleaning-report-list'].totals.pageSize;

export const selectTotalsSortField = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].totals.sortOptions.field;

export const selectTotalsSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules.cleaning['cleaning-report-list'].totals.sortOptions.order;

export const selectExactData = (state: IState): Optional<CleaningTaskReport[]> =>
  state.modules.cleaning['cleaning-report-list'].exact.data;

export const selectExactTotalCount = (state: IState): Optional<number> =>
  state.modules.cleaning['cleaning-report-list'].exact.totalCount;

export const selectExactIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].exact.isLoading;

export const selectExactIsLoadingMoreData = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].exact.isLoadingMoreData;

export const selectExactPaginationTokens = (state: IState): PaginationTokens =>
  state.modules.cleaning['cleaning-report-list'].exact.paginationTokens;

export const selectExactNextPaginationToken = (state: IState): string | null =>
  state.modules.cleaning['cleaning-report-list'].exact.nextPaginationToken;

export const selectExactPage = (state: IState): number => state.modules.cleaning['cleaning-report-list'].exact.page;

export const selectExactPageSize = (state: IState): number =>
  state.modules.cleaning['cleaning-report-list'].exact.pageSize;

export const selectExactSortField = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].exact.sortOptions.field;

export const selectExactSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules.cleaning['cleaning-report-list'].exact.sortOptions.order;

export const selectRobotData = (state: IState): Optional<SingleRoutesAndRoutineData[]> =>
  state.modules.cleaning['cleaning-report-list'].robot.data;

export const selectRobotTotalCount = (state: IState): Optional<number> =>
  state.modules.cleaning['cleaning-report-list'].robot.totalCount;

export const selectRobotIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].robot.isLoading;

export const selectRobotIsLoadingMoreData = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].robot.isLoadingMoreData;

export const selectRobotPaginationTokens = (state: IState): PaginationTokens =>
  state.modules.cleaning['cleaning-report-list'].robot.paginationTokens;

export const selectRobotNextPaginationToken = (state: IState): string | null =>
  state.modules.cleaning['cleaning-report-list'].robot.nextPaginationToken;

export const selectRobotPage = (state: IState): number => state.modules.cleaning['cleaning-report-list'].robot.page;

export const selectRobotPageSize = (state: IState): number =>
  state.modules.cleaning['cleaning-report-list'].robot.pageSize;

export const selectRobotSortField = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].robot.sortOptions.field;

export const selectRobotSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules.cleaning['cleaning-report-list'].robot.sortOptions.order;

export const selectFilters = (state: IState): Optional<CleaningReportListAvailableFilters> =>
  state.modules.cleaning['cleaning-report-list'].filters.available;

export const selectAreFiltersLoading = (state: IState): boolean =>
  state.modules.cleaning['cleaning-report-list'].filters.isLoading;

export const selectPeriodStartDate = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.period.startDate;

export const selectPeriodEndDate = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.period.endDate;

export const selectActiveMachinesFilter = (state: IState): Optional<string[]> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.machines || undefined;

export const selectActiveSiteFilter = (state: IState): Optional<string[]> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.siteIds || undefined;

export const selectActiveLastActivityAtFilter = (state: IState): Optional<FilterLastActivityAt> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.lastActivityAt;

export const selectActiveTaskCompletionFilter = (state: IState): Optional<string[]> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.taskCompletion;

export const selectActiveDeviceTab = (state: IState): MachineClassification =>
  state.modules.cleaning['cleaning-report-list'].selectTab.deviceType;

export const selectExportRequestId = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].export.requestId;

export const selectExportIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning['cleaning-report-list'].export.isLoading;

export const selectExportRobotDetailsDownloadUrl = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].exportRobotDetails.downloadUrl;

export const selectExportRobotDetailsExportingId = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].exportRobotDetails.id;

export const selectActiveSearchTextFilter = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].filters.active.searchText || undefined;

export const selectIsReportSubscriptionModalVisible = (state: IState): boolean =>
  state.modules.cleaning['cleaning-report-list'].reportSubscriptionDrawer.visible;

export const selectIsReportSubscriptionModalLoading = (state: IState): boolean =>
  state.modules.cleaning['cleaning-report-list'].reportSubscriptionDrawer.isLoading;

export const selectIsGetSubscriptionLoading = (state: IState): boolean =>
  state.modules.cleaning['cleaning-report-list'].reportSubscriptionDrawer.reportSubscription.isLoading;

export const selectIsGetSubscriptionData = (state: IState): CleaningReportSubscription[] =>
  state.modules.cleaning['cleaning-report-list'].reportSubscriptionDrawer.reportSubscription.data;

export const selectIsSaveSubscriptionLoading = (state: IState): boolean =>
  state.modules.cleaning['cleaning-report-list'].reportSubscriptionDrawer.saveReportSubscription.isLoading;

export const selectRoutineDetailsDrawerVisible = (state: IState): boolean =>
  state.modules.cleaning['event-list'].routineDetailsDrawer.visible;

export const selectRoutineDetailsDrawerExecutionId = (state: IState): string =>
  state.modules.cleaning['event-list'].routineDetailsDrawer.executionId;

export const selectRoutineCleaningTaskReportLoading = (state: IState): boolean =>
  state.modules.cleaning['event-list'].routineCleaningTaskReport.isLoading;

export const selectRoutineCleaningTaskReportData = (state: IState): Optional<CleaningTaskReport[]> =>
  state.modules.cleaning['event-list'].routineCleaningTaskReport.data.cleaningTaskReports.data;

export const selectRoutineKPIsData = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning['event-list'].routineCleaningTaskReport.data.robotDashboardKPIs.data;

export const selectRoutineConsumptionData = (state: IState): Optional<RobotCleaningConsumption> =>
  state.modules.cleaning['event-list'].routineCleaningTaskReport.data.robotConsumptionSummary.data;

export const selectRoutineData = (state: IState): Optional<Routine> =>
  state.modules.cleaning['event-list'].routineCleaningTaskReport.data.routine.data;

export const selectExportRoutinesDownloadUrl = (state: IState): Optional<string> =>
  state.modules.cleaning['event-list'].routineExportFile.downloadUrl;

export const selectExportRoutinesExportingId = (state: IState): Optional<string> =>
  state.modules.cleaning['event-list'].routineExportFile.id;

export const selectExportRoutesExportingId = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].exportRobotDetails.id;

export const selectExportRoutesDownloadUrl = (state: IState): Optional<string> =>
  state.modules.cleaning['cleaning-report-list'].exportRobotDetails.downloadUrl;
