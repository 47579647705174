import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'antd';
import { eventListActions } from '../../state/eventListSlice';
import { selectRoutineDetailsDrawerVisible } from '../../state/cleaningReportListSelectors';
import { StyledRoutineDetailsDrawer } from './RoutineDetailsDrawer.styles';
import { RoutineKpiBar } from './RoutineKpiBar/RoutineKpiBar';
import { RoutineCleaningReportListRobot } from './RoutineCleaningTaskReportList/component/RoutineCleaningTaskReportList';
import { SingleRoutesAndRoutineData } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ThemeConstants } from 'config/theme';

interface RoutineDetailsDrawerProps {
  executionId?: string | null;
  routineInfo?: SingleRoutesAndRoutineData;
}
export const RoutineDetailsDrawer = ({ executionId, routineInfo }: RoutineDetailsDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectRoutineDetailsDrawerVisible);

  const hideRoutineDetailsDrawer = useCallback(() => {
    dispatch(eventListActions.hideRoutineDetailsDrawer());
  }, [dispatch]);

  useEffect(() => {
    if (executionId) {
      dispatch(
        eventListActions.getRoutineCleaningTaskReportRequest({
          filter: {
            executionId,
          },
        })
      );
    }
  }, [dispatch, executionId]);

  return (
    <StyledRoutineDetailsDrawer size="large" open={isVisible} onClose={hideRoutineDetailsDrawer}>
      <Flex gap={ThemeConstants.BASE_GAP} vertical>
        <div className="event-list-drawer__header">
          <h1 className="event-list-drawer__header__routine-name">{routineInfo?.routineName || t('common.NA')}</h1>
          <h3 className="event-list-drawer__header__machine-site">
            {routineInfo?.machineName || t('common.NA')},{' '}
            {routineInfo?.siteName || t('cleaningReportList.unassignedLabel')}
          </h3>
        </div>
        <Flex gap={ThemeConstants.BASE_GAP} vertical>
          <RoutineKpiBar />
          <RoutineCleaningReportListRobot />
        </Flex>
      </Flex>
    </StyledRoutineDetailsDrawer>
  );
};
