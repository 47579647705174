import { createGlobalStyle } from 'styled-components';

export const RobotRouteImagePreviewGlobalStyles = createGlobalStyle`
  .ant-image-preview-img {
    border: 1px solid ${(props): string => props.theme.colors.black};
    ${(props): string => props.theme.mixins.dropShadow}
  }
  
  .cleaning-task-report-robot__preview-route-image-render {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 40px;
    background-color:  rgba(0, 0, 0, 0.4);
  }

  .cleaning-task-report-robot__preview-route-image-title {
    ${(props): string => props.theme.fontStyles.heading3};
    color: ${(props): string => props.theme.colors.white};
    display: block;
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
  }

  .cleaning-task-report-robot__preview-route-image-content {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    z-index: 1000;
  }

  .cleaning-task-report-robot__preview-route-image-loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100;
  }

  .ant-image-preview-operations-wrapper {
    .toolbar-wrapper {
      padding: 0px 24px;
      color: ${(props): string => props.theme.colors.white};
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 100px; 

      span {
        padding: 10px;
        cursor: pointer;
      }
    }
  }

  .ant-image-preview-footer {
    margin-bottom: 25px;
  }

  .ant-image-preview-switch-right-disabled, .ant-image-preview-switch-right {
    margin-right: 40px;
  }
  .ant-image-preview-switch-left-disabled, .ant-image-preview-switch-left {
    margin-left: 40px;
  }
`;
