import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, SagaGenerator, retry } from 'typed-redux-saga';
import { CleaningConstants } from '../../CleaningConstants';
import {
  RoutineCleaningTaskReportResponse,
  CleaningTaskReportListResponse,
  RobotEventListResponse,
} from '../../interfaces/CleaningTaskReport.types';
import {
  CleaningReportsExportGetFile,
  CleaningReportListAvailableFilters,
  CleaningReportsExportRequest,
  CleaningReportsExportRobotDetailsRequest,
  RoutineClientExportRoutinesRequest,
} from '../../interfaces/CleaningReport.types';
import {
  IGetCleaningReportListTotalsErrorAction,
  IGetCleaningReportListTotalsRequestAction,
  IGetCleaningReportListTotalsSuccessAction,
  CleaningReportListActions,
  IGetCleaningReportListFiltersSuccessAction,
  IGetCleaningReportListFiltersErrorAction,
  IGetCleaningReportListExactErrorAction,
  IGetCleaningReportListExactRequestAction,
  IGetCleaningReportListExactSuccessAction,
  IGetMachineListFilterSuccessAction,
  IGetMachineListFilterErrorAction,
  IGetMachineListFilterRequestAction,
  IGetCleaningReportListRobotRequestAction,
  IGetCleaningReportListRobotErrorAction,
  IGetCleaningReportListRobotSuccessAction,
  IRequestExportCleaningReportsErrorAction,
  IRequestExportCleaningReportsRequestAction,
  IRequestExportCleaningReportsSuccessAction,
  IPollGetExportRobotDetailsCleaningReportsFileErrorAction,
  IPollGetExportRobotDetailsCleaningReportsFileRequestAction,
  IPollGetExportRobotDetailsCleaningReportsFileSuccessAction,
  IRequestExportRobotDetailsCleaningReportsRequestAction,
  IRequestExportRobotDetailsCleaningReportsSuccessAction,
  IRequestExportRobotDetailsCleaningReportsErrorAction,
  IGetStandAloneMachineListFilterErrorAction,
  IGetStandAloneMachineListFilterRequestAction,
  IGetStandAloneMachineListFilterSuccessAction,
  IGetCleaningReportListExactMoreDataErrorAction,
  IGetCleaningReportListExactMoreDataRequestAction,
  IGetCleaningReportListExactMoreDataSuccessAction,
  IGetCleaningReportListRobotMoreDataErrorAction,
  IGetCleaningReportListRobotMoreDataRequestAction,
  IGetCleaningReportListRobotMoreDataSuccessAction,
  IGetReportSubscriptionErrorAction,
  IGetReportSubscriptionSuccessAction,
  IHideReportSubscriptionDrawerAction,
  ISaveReportSubscriptionErrorAction,
  ISaveReportSubscriptionRequestAction,
  ISaveReportSubscriptionSuccessAction,
  IGetCleaningReportListFiltersRequestAction,
} from './cleaningReportListActions';
import {
  IExportRoutineErrorAction,
  IExportRoutineRequestAction,
  IExportRoutineSuccessAction,
  IGetRoutineCleaningTaskReportErrorAction,
  IGetRoutineCleaningTaskReportRequestAction,
  IGetRoutineCleaningTaskReportSuccessAction,
  IPollGetExportRoutinesFileErrorAction,
  IPollGetExportRoutinesFileRequestAction,
  IPollGetExportRoutinesFileSuccessAction,
} from './eventListActions.types';
import { eventListActions } from './eventListSlice';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  MachinesAvailableToFilter,
  MachinesListWithCleaningReport,
} from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';
import {
  AsyncJobStatus,
  ResponseCleaningReportSubscriptions,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getCleaningReportListTotalsSaga(
  action: IGetCleaningReportListTotalsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachinesListWithCleaningReport>
  | PutEffect<IGetCleaningReportListTotalsSuccessAction>
  | PutEffect<IGetCleaningReportListTotalsErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listMachinesWithCleaningReport, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListTotalsSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningReportListActions.getCleaningReportListTotalsError({
        error,
      })
    );
  }
}

export function* getCleaningReportListExactSaga(
  action: IGetCleaningReportListExactRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CleaningTaskReportListResponse>
  | PutEffect<IGetCleaningReportListExactSuccessAction>
  | PutEffect<IGetCleaningReportListExactErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningTaskReportService.list, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListExactSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningReportListActions.getCleaningReportListExactError({
        error,
      })
    );
  }
}

export function* getCleaningReportListExactMoreDataSaga(
  action: IGetCleaningReportListExactMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CleaningTaskReportListResponse>
  | PutEffect<IGetCleaningReportListExactMoreDataSuccessAction>
  | PutEffect<IGetCleaningReportListExactMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningTaskReportService.list, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListExactMoreDataSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningReportListActions.getCleaningReportListExactMoreDataError({
        error,
      })
    );
  }
}

export function* getCleaningReportListRobotSaga(
  action: IGetCleaningReportListRobotRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotEventListResponse>
  | PutEffect<IGetCleaningReportListRobotSuccessAction>
  | PutEffect<IGetCleaningReportListRobotErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningTaskReportService.listRobotEvents, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListRobotSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningReportListActions.getCleaningReportListRobotError({
        error,
      })
    );
  }
}

export function* getCleaningReportListRobotMoreDataSaga(
  action: IGetCleaningReportListRobotMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotEventListResponse>
  | PutEffect<IGetCleaningReportListRobotMoreDataSuccessAction>
  | PutEffect<IGetCleaningReportListRobotMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningTaskReportService.listRobotEvents, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListRobotMoreDataSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CleaningReportListActions.getCleaningReportListRobotMoreDataError({
        error,
      })
    );
  }
}

export function* getCleaningReportListFiltersSaga(
  action: IGetCleaningReportListFiltersRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CleaningReportListAvailableFilters>
  | PutEffect<IGetCleaningReportListFiltersSuccessAction>
  | PutEffect<IGetCleaningReportListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportService.availableFilters, action.payload);

    yield* put(CleaningReportListActions.getCleaningReportListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.getCleaningReportListFiltersError({
        error,
      })
    );
  }
}

export function* getMachineListFilterSaga(
  action: IGetMachineListFilterRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachinesAvailableToFilter>
  | PutEffect<IGetMachineListFilterSuccessAction>
  | PutEffect<IGetMachineListFilterErrorAction>,
  void,
  IDependencies
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machineResponse = yield* call(cleaningReportService.machineListFilter, action.payload);

    yield* put(CleaningReportListActions.getMachineListFilterSuccess(machineResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.getMachineListFilterError({
        error,
      })
    );
  }
}

export function* requestExportCleaningReportsSaga(
  action: IRequestExportCleaningReportsRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<CleaningReportsExportRequest> | void>
  | PutEffect<IRequestExportCleaningReportsSuccessAction>
  | PutEffect<IRequestExportCleaningReportsErrorAction>,
  void,
  IDependencies
> {
  const { cleaningReportService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportService.requestExportCleaningReports, action.payload);
    yield* put(CleaningReportListActions.requestExportCleaningReportsSuccess(response));
    yield* call(toastService.success, {
      message: t('cleaningReportList.toast.success.message'),
      description: t('cleaningReportList.toast.success.descriptionViaEmail'),
    });
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.requestExportCleaningReportsError({
        error,
      })
    );
  }
}

export function* pollGetExportRobotDetailsCleaningReportsFileSaga(
  action: IPollGetExportRobotDetailsCleaningReportsFileRequestAction
): Generator<
  | GetContextEffect
  | SagaGenerator<CleaningReportsExportGetFile, CallEffect<CleaningReportsExportGetFile>>
  | PutEffect<IPollGetExportRobotDetailsCleaningReportsFileSuccessAction>
  | PutEffect<IPollGetExportRobotDetailsCleaningReportsFileErrorAction>,
  void,
  any
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');
  const getExportCleaningReportsFile = async (): Promise<CleaningReportsExportGetFile> => {
    const getFileResponse = await cleaningReportService.getExportCleaningReportsFile(action.payload);
    const { status, presignedUrl } = getFileResponse?.cleaningReportsExportGetFile.data || {};
    if (status !== AsyncJobStatus.Done) {
      throw new Error('Download link is not ready yet!');
    }

    if (!presignedUrl) {
      throw new Error('Download link is ready but no presignedUrl!');
    }

    return getFileResponse;
  };

  try {
    const response: CleaningReportsExportGetFile = yield retry(
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_MAX_RETRIES,
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_INTERVAL,
      getExportCleaningReportsFile
    );

    yield* put(CleaningReportListActions.pollGetExportRobotDetailsCleaningReportsFileSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.pollGetExportRobotDetailsCleaningReportsFileError({
        error,
      })
    );
  }
}

export function* requestExportRobotDetailsCleaningReportsSaga(
  action: IRequestExportRobotDetailsCleaningReportsRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<CleaningReportsExportRobotDetailsRequest> | void>
  | PutEffect<IRequestExportRobotDetailsCleaningReportsSuccessAction>
  | PutEffect<IRequestExportRobotDetailsCleaningReportsErrorAction>
  | PutEffect<IPollGetExportRobotDetailsCleaningReportsFileRequestAction>,
  void,
  IDependencies
> {
  const { cleaningReportService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportService.requestRobotDetailsReportsExport, action.payload);
    yield* call(toastService.success, {
      message: t('cleaningReportList.toast.success.message'),
      description: t('cleaningReportList.toast.success.description'),
    });
    const requestId = response?.robotDetailsReportsExportRequest?.data?.requestId;
    if (!requestId) {
      throw new Error('No requestId!');
    }

    yield* put(CleaningReportListActions.pollGetExportRobotDetailsCleaningReportsFileRequest({ requestId }));
    yield* put(CleaningReportListActions.requestExportRobotDetailsCleaningReportsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.requestExportRobotDetailsCleaningReportsError({
        error,
      })
    );
  }
}

export function* getStandAloneMachineListFilterSaga(
  action: IGetStandAloneMachineListFilterRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachinesAvailableToFilter>
  | PutEffect<IGetStandAloneMachineListFilterSuccessAction>
  | PutEffect<IGetStandAloneMachineListFilterErrorAction>,
  void,
  IDependencies
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machineResponse = yield* call(cleaningReportService.machineListFilter, action.payload);

    yield* put(CleaningReportListActions.getStandAloneMachineListFilterSuccess(machineResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.getStandAloneMachineListFilterError({
        error,
      })
    );
  }
}

export function* getCleaningReportSubscriptionSaga(): Generator<
  | GetContextEffect
  | CallEffect<ResponseCleaningReportSubscriptions>
  | PutEffect<IGetReportSubscriptionSuccessAction>
  | PutEffect<IGetReportSubscriptionErrorAction>,
  void,
  IDependencies
> {
  const { cleaningReportSubscriptionService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportSubscriptionService.get);

    yield* put(CleaningReportListActions.getReportSubscriptionSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.getReportSubscriptionError({
        error,
      })
    );
  }
}

export function* saveCleaningReportSubscriptionSaga(
  action: ISaveReportSubscriptionRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<ResponseCleaningReportSubscriptions>>
  | PutEffect<ISaveReportSubscriptionSuccessAction>
  | PutEffect<ISaveReportSubscriptionErrorAction>
  | PutEffect<IHideReportSubscriptionDrawerAction>,
  void,
  IDependencies
> {
  const { cleaningReportSubscriptionService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportSubscriptionService.save, action.payload);

    yield* put(CleaningReportListActions.saveReportSubscriptionSuccess(response));
    yield* put(CleaningReportListActions.hideReportSubscriptionDrawer());
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningReportListActions.saveReportSubscriptionError({
        error,
      })
    );
  }
}

export function* getRoutineCleaningTaskReportSaga(
  action: IGetRoutineCleaningTaskReportRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RoutineCleaningTaskReportResponse>
  | PutEffect<IGetRoutineCleaningTaskReportRequestAction>
  | PutEffect<IGetRoutineCleaningTaskReportSuccessAction>
  | PutEffect<IGetRoutineCleaningTaskReportErrorAction>,
  void,
  IDependencies
> {
  const { cleaningTaskReportService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningTaskReportService.getRoutineDetails, action.payload);

    yield* put(eventListActions.getRoutineCleaningTaskReportSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      eventListActions.getRoutineCleaningTaskReportError({
        error,
      })
    );
  }
}

export function* getRoutineExportSaga(
  action: IExportRoutineRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<RoutineClientExportRoutinesRequest> | void>
  | PutEffect<IExportRoutineSuccessAction>
  | PutEffect<IExportRoutineErrorAction>
  | PutEffect<IPollGetExportRoutinesFileRequestAction>,
  void,
  IDependencies
> {
  const { cleaningReportService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(cleaningReportService.requestExportRoutines, action.payload);
    yield* call(toastService.success, {
      message: t('cleaningReportList.toast.success.message'),
      description: t('cleaningReportList.toast.success.description'),
    });
    const requestId = response?.routineReportExportRequest.data.requestId;
    if (!requestId) {
      throw new Error('No requestId!');
    }

    yield* put(eventListActions.pollGetRoutineExportRequest({ executionId: requestId }));
    yield* put(
      eventListActions.routineExportSuccess({ routineReportExportRequest: response?.routineReportExportRequest })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      eventListActions.routineExportError({
        error,
      })
    );
  }
}

export function* pollGetExportRoutinesFileSaga(
  action: IPollGetExportRoutinesFileRequestAction
): Generator<
  | GetContextEffect
  | SagaGenerator<CleaningReportsExportGetFile, CallEffect<CleaningReportsExportGetFile>>
  | PutEffect<IPollGetExportRoutinesFileSuccessAction>
  | PutEffect<IPollGetExportRoutinesFileErrorAction>,
  void,
  any
> {
  const { cleaningReportService } = yield* getContext<IDependencies>('dependencies');
  const getExportCleaningReportsFile = async (): Promise<CleaningReportsExportGetFile> => {
    const getFileResponse = await cleaningReportService.getExportCleaningReportsFile({
      requestId: action.payload.executionId,
    });
    const { status, presignedUrl } = getFileResponse?.cleaningReportsExportGetFile.data || {};
    if (status !== AsyncJobStatus.Done) {
      throw new Error('Download link is not ready yet!');
    }

    if (!presignedUrl) {
      throw new Error('Download link is ready but no presignedUrl!');
    }

    return getFileResponse;
  };

  try {
    const response: CleaningReportsExportGetFile = yield retry(
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_MAX_RETRIES,
      CleaningConstants.CLEANING_REPORT_EXPORT_POLL_INTERVAL,
      getExportCleaningReportsFile
    );

    yield* put(eventListActions.pollGetRoutineExportSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      eventListActions.pollGetRoutineExportError({
        error,
      })
    );
  }
}

export function* cleaningReportListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_REQUEST, getCleaningReportListTotalsSaga);
  yield* takeLatest(CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_REQUEST, getCleaningReportListExactSaga);
  yield* takeLatest(
    CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST,
    getCleaningReportListExactMoreDataSaga
  );
  yield* takeLatest(CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_REQUEST, getCleaningReportListRobotSaga);
  yield* takeLatest(
    CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST,
    getCleaningReportListRobotMoreDataSaga
  );
  yield* takeLatest(
    CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_REQUEST,
    getCleaningReportListFiltersSaga
  );
  yield* takeLatest(
    CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST,
    getMachineListFilterSaga
  );
  yield* takeLatest(
    CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_REQUEST,
    requestExportCleaningReportsSaga
  );
  yield* takeLatest(
    CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_REQUEST,
    pollGetExportRobotDetailsCleaningReportsFileSaga
  );
  yield* takeLatest(
    CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST,
    requestExportRobotDetailsCleaningReportsSaga
  );
  yield* takeLatest(
    CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST,
    getStandAloneMachineListFilterSaga
  );
  yield* takeLatest(CleaningReportListActions.GET_REPORT_SUBSCRIPTION_REQUEST, getCleaningReportSubscriptionSaga);
  yield* takeLatest(CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_REQUEST, saveCleaningReportSubscriptionSaga);

  yield* takeLatest(eventListActions.getRoutineCleaningTaskReportRequest, getRoutineCleaningTaskReportSaga);

  yield* takeLatest(eventListActions.routineExportRequest, getRoutineExportSaga);
  yield* takeLatest(eventListActions.pollGetRoutineExportRequest, pollGetExportRoutinesFileSaga);
}
