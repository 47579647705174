import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space, Spin } from 'antd';
import { RobotRouteImagePreviewGlobalStyles } from '../../../CleaningReportListRobot/components/RobotRouteImagePreview.global.styles';
import { ROBOT_CTR_ROUTE_IMAGE_FALLBACK } from 'app/modules/machine-inventory/utils/constants';
import { CleaningTaskReport } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { CachingStorageSelector } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSelector';
import { downloadLink } from 'app/utils/download-link/downloadLink';
import { cachingStorageActions } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSlice';

interface RobotRouteImagePreviewProps {
  cleaningTaskReport: CleaningTaskReport;
  onVisibilityChange: (value: boolean) => void;
  visible: boolean;
}

export const RobotRouteImagePreview = ({
  cleaningTaskReport,
  onVisibilityChange,
  visible,
}: RobotRouteImagePreviewProps): JSX.Element => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const imageAsDataUrl = useSelector(CachingStorageSelector.selectImageStorageObjectUrl);
  const ctrImage = useSelector(CachingStorageSelector.selectImageStorageList);
  const robotRouteImageLoading = useSelector(CachingStorageSelector.selectImageStorageIsLoading);
  const imageFinishedTime = cleaningTaskReport.finishedAt;

  const cleaningRouteDate = DateTime.formatDateByLocale(i18n.language, imageFinishedTime, DATE_FORMAT_PATTERN.DATE);
  const imageTitle = `${cleaningTaskReport.machine?.name || ''} - Cleaning Route ${cleaningRouteDate}`;
  const key = `${cleaningTaskReport.machine?.id}-${cleaningTaskReport.finishedAt}`;
  const image = ctrImage[key];

  useEffect(() => {
    if (visible) {
      if (!image) {
        dispatch(
          cachingStorageActions.getImageRequest({
            machineId: cleaningTaskReport.machine?.id || '',
            finishedAt: cleaningTaskReport.finishedAt || '',
          })
        );
      } else {
        dispatch(cachingStorageActions.setImageRequest(image));
      }
    }
  }, [cleaningTaskReport.finishedAt, cleaningTaskReport.machine?.id, dispatch, visible, image]);

  const handleDownload = useCallback(() => {
    if (imageAsDataUrl) {
      downloadLink({ fileUrl: imageAsDataUrl, fileName: imageTitle });
    }
  }, [imageAsDataUrl, imageTitle]);

  return (
    <>
      <RobotRouteImagePreviewGlobalStyles />
      <Image.PreviewGroup
        fallback={ROBOT_CTR_ROUTE_IMAGE_FALLBACK}
        preview={{
          visible,
          src: imageAsDataUrl || '',
          onVisibleChange: (value): void => onVisibilityChange(value),
          imageRender: originalNode => (
            <div className="cleaning-task-report-robot__preview-route-image-render">
              {robotRouteImageLoading ? (
                <div className="cleaning-task-report-robot__preview-route-image-loading-indicator">
                  <Spin size="default" />
                </div>
              ) : (
                <>
                  <div className="cleaning-task-report-robot__preview-route-image-title">{imageTitle}</div>
                  {originalNode}
                </>
              )}
            </div>
          ),
          toolbarRender: (
            _,
            { transform: { scale }, actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
          ) => (
            <Space size={12} className="toolbar-wrapper">
              <DownloadOutlined
                onClick={handleDownload}
                disabled={!imageAsDataUrl}
                style={{ cursor: !imageAsDataUrl ? 'not-allowed' : 'pointer' }}
              />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      >
        <Image
          width={200}
          className="cleaning-task-report-robot__preview-route-image"
          wrapperClassName="cleaning-task-report-robot__preview-route-image-wrapper"
        />
      </Image.PreviewGroup>
    </>
  );
};
