import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledAddWorkIntervalModal = styled(Modal)`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .add-work-interval-modal {
    div {
      width: 520px;
    }
  }

  .add-work-interval-modal__time-range-picker {
    height: 32px;
    width: 228px;
    transition: 0.2s width;

    &.ant-picker-focused {
      width: 228px;
    }

    .ant-picker-time-range-wrapper {
      min-width: 228px !important;
    }
  }

  .add-work-interval-modal__dropdown {
    width: 228px;
    transition: 0.2s width;
    border: 1px solid ${(props): string => props.theme.colors.darkGrey};
  }

  .add-work-interval-modal__days-of-week {
    column-gap: 1px;
    .ant-checkbox-wrapper {
      width: calc(100% / 7 - 6px);
      span.ant-checkbox {
        display: none;
      }

      span:last-child {
        width: 100%;
        color: ${(props): string => props.theme.colors.black};
        ${(props): string => props.theme.fontStyles.p1.news};
        text-align: center;
        height: 28px;
        line-height: 32px;
        transition: 0.25s background-color;
      }

      &:hover {
        span:last-child {
          background-color: ${(props): string => props.theme.colors.brightGrey};
        }
      }

      &.ant-checkbox-wrapper-checked {
        span:last-child {
          background-color: ${(props): string => props.theme.colors.black};
          color: ${(props): string => props.theme.colors.white};
        }
      }
    }

    display: flex;
    align-items: center;
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.semanticError};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.semanticError};
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin-inline-end: 0;
  }

  .ant-form-item .ant-form-item-label > .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    visibility: visible;
    color: ${(props): string => props.theme.colors.semanticError};
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    line-height: 1;
    content: '*';
  }
`;
