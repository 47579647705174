import { Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as cleaningReportListSelectors from '../../state/cleaningReportListSelectors';
import { CleaningReportListActions } from '../../state/cleaningReportListActions';
import { StyledReportSubscriptionDrawer } from './ReportSubscriptionDrawer.styles';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import {
  selectHasAccessToGCD,
  selectHasAccessToRobots,
} from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Radio } from 'lib/components/Radio/Radio';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { CleaningReportSubscriptionInterval } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import {
  CleaningReportSubscriptionForm,
  convertDataToSave,
  convertResData,
} from 'app/modules/cleaning/utils/ConvertReportSubscriptionUtils';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export const ReportSubscriptionDrawer = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(cleaningReportListSelectors.selectIsReportSubscriptionModalVisible);
  const isGetSubscriptionLoading = useSelector(cleaningReportListSelectors.selectIsGetSubscriptionLoading);
  const isSaveSubscriptionLoading = useSelector(cleaningReportListSelectors.selectIsSaveSubscriptionLoading);
  const data = useSelector(cleaningReportListSelectors.selectIsGetSubscriptionData);
  const [_isEventReport, setIsEventReport] = useState(false);
  const [_isRobotReport, setIsRobotReport] = useState(false);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);
  const [fields, setFields] = useState<Optional<CleaningReportSubscriptionForm>>(undefined);

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const haveRobots = useSelector(selectHasAccessToRobots);
  const haveMachines = useSelector(selectHasAccessToGCD);
  const showCheckbox = isRobotIntegrationEnabled && haveRobots && haveMachines;

  const hideReportSubscriptionDrawer = useCallback(() => {
    dispatch(CleaningReportListActions.hideReportSubscriptionDrawer());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const onFinish = useCallback(
    (values: CleaningReportSubscriptionForm) => {
      const cloneValue = { ...values };
      if (haveRobots && !haveMachines) {
        cloneValue.robotCheckboxReport = true;
        cloneValue.robotXLS = true;
      }

      if (haveMachines && !haveRobots) {
        cloneValue.machinesCheckboxReport = true;
        cloneValue.eventXLS = true;
      }

      if (haveMachines && haveRobots) {
        if (cloneValue.robotCheckboxReport) {
          cloneValue.robotXLS = true;
        }

        if (cloneValue.machinesCheckboxReport) {
          cloneValue.eventXLS = true;
        }
      }

      cloneValue.reportInterval = cloneValue.reportInterval as CleaningReportSubscriptionInterval;

      const params = convertDataToSave(cloneValue);

      analyticsLinkActivated({
        linkName: AnalyticsLink.REPORT_SUBSCRIPTION_CHANGED,
      });

      if (cloneValue.reportInterval === '') {
        dispatch(
          CleaningReportListActions.saveReportSubscriptionRequest({
            lang: Translations.getSupportedLanguageCode(i18n.language),
            cleaningReportSubscriptions: [],
            timezone: DateTime.getBrowserTimeZone(),
          })
        );
        return;
      }

      dispatch(
        CleaningReportListActions.saveReportSubscriptionRequest({
          lang: Translations.getSupportedLanguageCode(i18n.language),
          cleaningReportSubscriptions: params,
          timezone: DateTime.getBrowserTimeZone(),
        })
      );
    },
    [analyticsLinkActivated, dispatch, haveMachines, haveRobots, i18n.language]
  );

  const handleDisableSaveBtn = useCallback((): void => {
    const isEventReportTrue = formInstance.getFieldValue('machinesCheckboxReport');

    const isRobotReportTrue = formInstance.getFieldValue('robotCheckboxReport');

    let isCheckBoxValid = true;

    if (haveMachines && haveRobots && isRobotIntegrationEnabled) {
      isCheckBoxValid = isRobotReportTrue || isEventReportTrue;
    }

    setIsSaveBtnDisable(!isCheckBoxValid);
  }, [formInstance, haveMachines, haveRobots, isRobotIntegrationEnabled]);

  const onValuesChange = useCallback((): void => {
    setIsEventReport(formInstance.getFieldValue('machinesCheckboxReport'));
    setIsRobotReport(formInstance.getFieldValue('robotCheckboxReport'));
    handleDisableSaveBtn();
  }, [formInstance, handleDisableSaveBtn]);

  useEffect(() => {
    const converted = convertResData(data);
    formInstance.setFieldsValue(converted);
    onValuesChange();
  }, [formInstance, data, onValuesChange]);

  useEffect(() => {
    if (isVisible) {
      dispatch(CleaningReportListActions.getReportSubscriptionRequest());
    }
  }, [dispatch, isVisible]);

  useAnalyticsSetForm({
    name: AnalyticsForm.CHANGE_REPORT_SUBSCRIPTION,
    fields: fields as Record<string, any>,
    isVisible,
  });

  return (
    <StyledReportSubscriptionDrawer
      className="subscription-drawer"
      size="small"
      open={isVisible}
      onClose={hideReportSubscriptionDrawer}
    >
      <div className="report-subscription-drawer__header">
        <h1 className="report-subscription-drawer__title">{t('cleaningReportList.reportSubscription.title')}</h1>
      </div>
      <div className="report-subscription-drawer__body">
        <p className="report-subscription-drawer__description">
          {t('cleaningReportList.reportSubscription.description1')}
        </p>
        <p className="report-subscription-drawer__description">
          {t('cleaningReportList.reportSubscription.description2')}
        </p>
        <Form
          form={formInstance}
          name="report-subscription-drawer"
          layout="vertical"
          onVolumeChange={handleValuesChange}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          <Spin size="large" spinning={isGetSubscriptionLoading}>
            {showCheckbox && (
              <div className="report-subscription-drawer__input-checkbox-wrapper">
                <Form.Item
                  valuePropName="checked"
                  name="robotCheckboxReport"
                  className="report-subscription-drawer__input-checkbox"
                >
                  <div className="report-subscription-drawer__option">
                    <Checkbox checked={formInstance.getFieldValue('robotCheckboxReport')} />
                    <p className="report-subscription-drawer__option-checkbox-label">
                      {t('cleaningReportList.reportSubscription.form.robotReport')}
                    </p>
                  </div>
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name="machinesCheckboxReport"
                  className="report-subscription-drawer__input-checkbox"
                >
                  <div className="report-subscription-drawer__option">
                    <Checkbox checked={formInstance.getFieldValue('machinesCheckboxReport')} />
                    <p className="report-subscription-drawer__option-checkbox-label">
                      {t('cleaningReportList.reportSubscription.form.connectedDeviceReport')}
                    </p>
                  </div>
                </Form.Item>
              </div>
            )}

            <Form.Item name="reportInterval" className="report-subscription-drawer__input-group">
              <Radio.Group>
                <div className="report-subscription-drawer__option">
                  <Radio value={''} name="none" />
                  <p>{t('cleaningReportList.reportSubscription.form.noReport')}</p>
                </div>
                <div className="report-subscription-drawer__option">
                  <Radio value={CleaningReportSubscriptionInterval.Monthly} name="monthly" />
                  <p>{t('cleaningReportList.reportSubscription.form.monthly')}</p>
                </div>
                <div className="report-subscription-drawer__option">
                  <Radio value={CleaningReportSubscriptionInterval.Weekly} name="weekly" />
                  <p>{t('cleaningReportList.reportSubscription.form.weekly')}</p>
                </div>
              </Radio.Group>
            </Form.Item>
          </Spin>
        </Form>
      </div>

      <div className="report-subscription-drawer__footer">
        <SecondaryButton size="s" onClick={hideReportSubscriptionDrawer} className="cancel-button" key="cancel-button">
          {t('common.close')}
        </SecondaryButton>
        <PrimaryButton
          disabled={isSaveBtnDisable}
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleSave}
          loading={isSaveSubscriptionLoading}
        >
          {t('cleaningReportList.reportSubscription.saveSubscription')}
        </PrimaryButton>
      </div>
    </StyledReportSubscriptionDrawer>
  );
};
